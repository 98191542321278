import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadingIcon from '../../loadingIcon';


export default class ActionButton extends Component {
  render() {
    const displayItem = this.props.showLoading ?
      <LoadingIcon visible={this.props.showLoading} /> :
      <span>{this.props.text}</span>;
    return (
      <button
        className="w3-button w3-blue w3-block w3-round"
        onClick={this.props.clickEvent}
        disabled={!this.props.isEnabled}
      >
        {displayItem}
      </button>
    );
  }
}

ActionButton.propTypes = {
  clickEvent: PropTypes.func.isRequired,
  isEnabled: PropTypes.bool,
  showLoading: PropTypes.bool,
  text: PropTypes.string.isRequired,
};

ActionButton.defaultProps = {
  isEnabled: false,
  showLoading: false,
};
