import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ControlLabel from './controlLabel';

export default class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: true
    }
    this.change = this.change.bind(this);

  }


  change(event) {
      this.props.onChange(event.target.value);
  }
  
  componentWillReceiveProps(newProps) {
    if(newProps.isValid != this.state.isValid) {
      this.setState({
        isValid: newProps.isValid
      })
    }
  }

  render() {
    const options = [{ id: "", description: 'Select One' }, ...this.props.options];
    const isReadonlyType = { readOnly: this.props.isReadonly && this.props.isReadonly === true };
    const editStyle = { background: '#ffffff' };

    if (this.state.isValid === false) {
      editStyle.background = '#ffe0e0';
    }


    return (
      <div className="w3-container w3-padding">
        <div className="w3-row">
          <div className="w3-col notranslate">
            <ControlLabel
              text={this.props.label}
              required={this.props.required}
            />
          </div>
        </div>
        <div className="w3-row">
          <div className="w3-col">
            <select
              className="w3-select w3-border w3-round"
              componentclass="select"
              value={this.props.value}
              required
              onChange={this.change}
              disabled={isReadonlyType.readOnly}
              style={editStyle}
            >
              {
                options.map(option =>
                  <option key={option.id} value={option.id}>{option.description}</option>)
              }
            </select>
          </div>
        </div>
      </div>
    );
  }
}

Dropdown.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  options: PropTypes
    .arrayOf(PropTypes.any, PropTypes.string)
    .isRequired,
  dataType: PropTypes.any,
};

Dropdown.defaultProps = {
  required: false,
  dataType: 1,
};
