import React from 'react';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import FormField, { ControlTypes, DataTypes } from '../common/inputs/formField';
import { NewId } from '../../helpers/stringHelpers';
import PageHeader from '../common/pageHeader';
import TemplateDataAccessor from '../../dataAccess/template';
import AuthorisedArea from '../../helpers/AuthorisedArea';
import LoadingIcon from '../common/loadingIcon';
import TemplateListTab from './templateListTab';
import TemplateGroup from './templateGroup';
import GroupEditor from './groupEditor';


const menuItems = [{ Display: 'Templates', Url: '/templates' }];

const menuButtonStyle = {
    marginLeft: '3px',
};

const detailsCellStyle = {
    width: '50%',
};

const previewCellStyle = {
    verticalAlign: 'middle',
    textAlign: 'center',
};

export default class Template extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            loadingText: "Loading Template",
            isPreviewing: false,
            previewImage: null,
            referenceData: null,
            data: null,
            isNew: !this.props.match.params.templateId,
            thisId: this.props.match.params.templateId,
        };

        this.previewImage = this.previewImage.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSaveComponent = this.onSaveComponent.bind(this);
        this.onSaveGroup = this.onSaveGroup.bind(this);
        this.onComponentClick = this.onComponentClick.bind(this);
        this.addComponent = this.addComponent.bind(this);
        this.onDeleteComponentClick = this.onDeleteComponentClick.bind(this);
        this.onGroupEditClick = this.onGroupEditClick.bind(this);
        this.onDeleteTemplateClick = this.onDeleteTemplateClick.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.closePlateGroupModal = this.closePlateGroupModal.bind(this);
    }

    componentWillMount() {
        // Enable showing a spinny...
        this.setState({ isLoading: true });
        // Get the data from the API, based on the ID.
        // Also, we want preview data back, so send 'true' as inclduePreview.
        TemplateDataAccessor.GetById(this.state.thisId, true)
            .then((result) => {
                const template = result.payload;
                // If we're a new template, we're going to assing the ID now....
                if (this.state.isNew) {
                    template.id = NewId();
                }
                // Set the state.
                this.setState({
                    data: template,
                    referenceData: template.referenceData,
                    isLoading: false,
                }, () => {
                    // And automatically preview the plate once the data is loaded.
                    this.previewImage();
                });
            })
            .catch((e) => {
                this.setState({ isLoading: false });
            });
    }

    /**
     * Event fired for when ever data in an edit field is changed.
     * The state will be updated with the value passed in, for the field name specified.
     * @param {*} fieldName
     * @param {*} fieldValue
     */
    onChange(fieldName, fieldValue) {
        const thisData = { ...this.state.data, [fieldName]: fieldValue };
        this.setState({ data: thisData });
    }

    /**
     * Function that handles when ever a component is clicked in the component list view.
     * It ensures that data is available for the editor screen (Relative groups)
     * And it sets the selected value.
     * @param {*} componentId
     * @param {*} groupIndex
     */
    onComponentClick(componentId, groupIndex) {
        // Get a list of the groups, so that we can select Relative Groups when editing.
        const relativeComponents = [];
        this.state.data.templateGroups[groupIndex].components
            .forEach(item =>
                relativeComponents.push({ id: item.id, description: item.description }));
        // Find the selected component.
        const componentIndex = this.state.data.templateGroups[groupIndex]
            .components.map((item) => { return item.id; })
            .indexOf(componentId);
        const selectedComponent = this.state.data.templateGroups[groupIndex]
            .components[componentIndex];
        // Update the State with the selected component,
        // as well as it's index in the list, and the group it's in.
        this.setState({
            selectedGroupIndex: groupIndex,
            selectedComponent,
            relativeComponents,
        }, () => {
            // And now display the edit form.
            document.getElementById('ComponentEditorPopup').style.display = 'block';
        });
    }

    // Event that handles the click event on the 'Add Group' button.
    // Currently only handles 'Add'. Hence, just shows the editor.
    onGroupEditClick() {

        document.getElementById('GroupEditorPopup').style.display = 'block';
    }

    /**
     * Callback for saving a component when it's edited or added in the component editor.
     * @param {*} component The component that should be saved.
     */
    onSaveComponent(component) {
        // Close the modal.
        document.getElementById('ComponentEditorPopup').style.display = 'none';
        // Get the groups and update the component in the group
        const groups = [...this.state.data.templateGroups];
        const newData = this.state.data;
        // Find the component in the selected group.
        const componentIndex = groups[this.state.selectedGroupIndex]
            .components.map((c) => { return c.id; })
            .indexOf(component.id);
        // Update the component list, replacing the updated component data.
        groups[this.state.selectedGroupIndex].components[componentIndex] = component;
        newData.templateGroups = groups;
        // Update the state.
        this.setState({ data: newData, selectedComponent: null }, () => this.previewImage());
    }

    // Callback for when the Save button is clicked when adding or editing a group.
    onSaveGroup(groupName, groupTypeId, fontTypeId) {
        const groups = [...this.state.data.templateGroups];
        const newGroup = {
            description: groupName,
            groupTypeId: groupTypeId,
            inverted: false,
            id: NewId(),
            templateId: this.state.data.id,
            fontTypeId: fontTypeId,
            staticAnchorX: null,
            staticAnchorY: null,
            staticTextHeight: null,
            staticSpacing: null,
            components: [],
        }

        groups.push(newGroup);
        const newData = this.state.data;
        // Add the new groups to our local state variable.
        newData.templateGroups = groups;
        this.setState(
            {
                data: newData,
            },
            () => { this.previewImage(); document.getElementById('GroupEditorPopup').style.display = 'none'; },
        );
    }

    /**
     * Event to handle the deletion of a Component. This handles confirmtion.
     * If the user wants to coninue, we call the actual delete.
     * @param {The group index} groupIndex
     * @param {The id of the component to delete} componentId
     */
    onDeleteComponentClick(groupIndex, componentId) {
        const options = {
            title: 'Confirmation',
            message: 'You are about to delete the component from the current template. Continue?',
            buttons: [
                {
                    label: 'Yes, delete the component',
                    onClick: () => this.deleteComponent(groupIndex, componentId),
                },
                {
                    label: 'No',
                },
            ],
        };
        // Show the confirmation.
        confirmAlert(options);
    }

    /**
     * This method performs the deletion of the component from the group
     */
    onDeleteTemplateClick() {
        const options = {
            title: 'Confirmation',
            message: 'You are about to delete this template. Any users whu have this template assign, will no longer be able to use it. Continue?',
            buttons: [
                {
                    label: 'Yes, delete the template',
                    onClick: () => this.deleteTemplate(),
                },
                {
                    label: 'No',
                },
            ],
        };
        // Show the confirmation.
        confirmAlert(options);
    }

    /**
     * Delete a component from a group, based on it's ID
     * @param {The Index of the selected grouo} groupIndex
     * @param {The id of the component to delete} componentId
     */
    deleteComponent(groupIndex, componentId) {
        const groups = [...this.state.data.templateGroups];
        const components = [...groups[groupIndex].components];

        // We really need to see if there are any components that reply on this component.
        const relative1Index = components.map((comp) => {
            return comp.relativeGroup1Id;
        }).indexOf(componentId);
        const relative2Index = components.map((comp) => {
            return comp.relativeGroup2Id;
        }).indexOf(componentId);
        if (relative1Index !== -1 || relative2Index !== -1) {
            toast.error('Unable to delete this, as it is used as used by another relative component.');
            return;
        }
        const componentIndex = components.map((comp) => { return comp.id; }).indexOf(componentId);
        components.splice(componentIndex, 1);
        groups[groupIndex].components = components;
        this.setState({ templateGroups: groups }, () => this.previewImage());
    }

    /**
     * Delete the currently selected template.
     */
    deleteTemplate() {
        TemplateDataAccessor.Delete(this.state.thisId)
            .then((result) => {
                if (result.isSuccess) {
                    toast.success('The template has been successfully delete');
                    this.props.history.push('/templates');
                } else {
                    toast.error('Error while attempting to delete this template');
                }
            });
    }

    // Adds a new component to the collection of the selected group.
    addComponent(groupIndex) {
        // Get the groups list, as we'll be adding an item to it soon.
        const templateGroups = [...this.state.data.templateGroups];
        // Get all the components in the current group.
        const components = [...templateGroups[groupIndex].components];
        // And get the specific group we're adding to,
        // as we'll need some data from it to create the new Component.
        const theGroup = { ...templateGroups[groupIndex] };
        // Generate the new Id from this new component.
        const newId = NewId();
        // Create the component with it's new ID.
        const newComponent = {
            anchorPointTypeID: null,
            anchorPointXMM: null,
            anchorPointYMM: null,
            description: null,
            entryDisplayOrder: null,
            componentPositionTypeId: null,
            componentTypeId: null,
            groupHeightAdjustmentMM: null,
            fontSpacing: null,
            shapeWidth: null,
            shapeHeight: null,
            id: newId,
            isReadOnly: false,
            maxChars: null,
            previewImageId: null,
            previewText: null,
            relativeGroup1Id: null,
            relativeGroup2Id: null,
            renderingOrder: null,
            shouldDisplayToClient: true,
            templateGroupId: theGroup.id,
        };
        // Add a new blank item to the component list, with default values.
        components.push(newComponent);
        // Update the group with the new set of components.
        templateGroups[groupIndex].components = components;
        // Get the state, so that we can add the updated groups.
        const newData = { ...this.state.data };
        // Add the new groups to our local state variable.
        newData.templateGroups = templateGroups;
        // Save the state.
        this.setState({
            data: newData,
            selectedComponent: newComponent,
        }, () => {
            this.onComponentClick(
                newId,
                groupIndex,
            );
        });
    }

    canPreview() {
        return this.state.data &&
            this.state.data.backgroundImageId &&
            this.state.data.width &&
            this.state.data.height;
    }

    previewImage() {
        if (!this.canPreview()) {
            return;
        }

        this.setState({
            isPreviewing: true,
            previewImage: null,
            loadingText: "Creating Preview"
        });

        var stateData = this.state.data

        let payload = {
            id: stateData.id,
            description: stateData.description,
            height: stateData.height,
            width: stateData.width,
            backgroundImageId: stateData.backgroundImageId,
            templateId: stateData.templateId,
            allowAdvert: stateData.allowAdvert,
            advertHeight: stateData.advertHeight,
            selectedCustomImageId: stateData.selectedCustomImageId,
            templateGroups: stateData.templateGroups,
            pdfLeftSpacing: stateData.pdfLeftSpacing,
            pdfRightSpacing: stateData.pdfRightSpacing,
            pdfTopSpacing: stateData.pdfTopSpacing,
            pdfBottomSpacing: stateData.pdfBottomSpacing,
        }


        TemplateDataAccessor.Preview(payload)
            .then((result) => {
                this.setState({ isPreviewing: false });
                if (result.isSuccess) {
                    this.setState({ previewImage: result.base64Data });
                } else {
                    toast.error(`Error processing preview. ${result.responseText}`);
                }
            })
            .catch((e) => {
                console.error(`Error while previewing image. ${e}`);
                toast.error(`Error while getting preview. ${e}`);
                this.setState({ isPreviewing: false });
            });

        this.setState({ isLoading: false });
    }

    groupHeaderChange(groupId, fieldName, value) {
        let groups = this.state.data.templateGroups
        const groupIndex = groups.findIndex(x => x.id === groupId)
        const group = groups[groupIndex]
        group[fieldName] = value
        groups.splice(groupIndex, 1, group)
        const data = this.state.data;
        data.templateGroups = groups;
        this.setState({ data })
    }

    onDeleteGroup(groupId) {
        const options = {
            title: 'Confirmation',
            message: 'You are about to delete this group. Continue?',
            buttons: [
                {
                    label: 'Yes, delete the group',
                    onClick: () => this.deleteGroup(groupId),
                },
                {
                    label: 'No',
                },
            ],
        };
        // Show the confirmation.
        confirmAlert(options);
    }

    deleteGroup(groupId) {
        // Find the index of the group we want to delete
        let groups = this.state.data.templateGroups
        const groupIndex = groups.findIndex(x => x.id === groupId)
        // Remove it from the temp store we just got.
        groups.splice(groupIndex, 1);
        // Set it back to the state
        const data = this.state.data;
        data.templateGroups = groups;
        this.setState({ data })

    }

    saveTemplate() {
        this.setState({ isLoading: true, loadingText: "Saving Template..." });
        TemplateDataAccessor.Save(this.state.data)
            .then((result) => {
                this.setState({ isLoading: false });
                if (result.isSuccess) {
                    toast.success('Template has been updated successfully.');
                    this.props.history.push('/templates');
                } else {
                    toast.error(result.responseText);
                }
            })
            .catch((e) => {
                console.error('Error while saving template.', e);
                toast.error(`An error occured while saving. Error: ${e} `);
                this.setState({ isLoading: false });
            });
    }

    closeModal() {
        this.setState({ selectedComponent: null });
        document.getElementById('ComponentEditorPopup').style.display = 'none';
    }

    closePlateGroupModal() {
        this.setState({ selectedComponent: null });
        document.getElementById('GroupEditorPopup').style.display = 'none';
    }

    totalWidth() {
        let x = (this.state.data.width ?? 0) + (this.state.data.pdfLeftSpacing ?? 0) + (this.state.data.pdfRightSpacing ?? 0);
        return x;
    }

    totalHeight() {
        let x = (this.state.data.pdfTopSpacing ?? 0) + (this.state.data.height ?? 0) + (this.state.data.pdfBottomSpacing ?? 0);
        return x
    }

    render() {

        if (this.state.isLoading) {
            return (
                <div>
                    <LoadingIcon displayText={this.state.loadingText} />
                </div>
            )
        }


        return (
            <div className="w3-container">
                <PageHeader Heading="Template Editor" MenuItems={menuItems} />
                <AuthorisedArea requiresAdmin showError>
                    <div className="w3-row">
                        <div className="w3-col w3-right-align">
                            <button className="w3-btn w3-blue w3-round" disabled={!this.canPreview()} onClick={() => this.previewImage()} style={menuButtonStyle} >Preview</button>
                            <button className="w3-btn w3-green w3-round" href="#" to="#" style={menuButtonStyle}>Generate PDF</button>
                            <button className="w3-btn w3-red w3-round" href="#" to="#" style={menuButtonStyle} onClick={() => { this.onDeleteTemplateClick(); }}>Delete</button>
                            <button className="w3-btn w3-green w3-round" disabled={this.state.isLoading} style={menuButtonStyle} onClick={() => { this.saveTemplate(); }}>Save</button>
                            <Link className="w3-btn w3-blue w3-round" href="/templates" to="/templates" style={menuButtonStyle}>Cancel</Link>
                        </div>
                    </div>
                    {this.state.data && (
                        <div>
                            <div className="w3-table">
                                <td style={detailsCellStyle}>
                                    <div className="w3-row">
                                        <div className="w3-col l4">
                                            <FormField
                                                isRequired
                                                maxLength={30}
                                                dataType={DataTypes.String}
                                                label="Template Name"
                                                controlType={ControlTypes.EditBox}
                                                value={this.state.data.description}
                                                onChange={value => this.onChange('description', value)}
                                            />
                                        </div>
                                        <div className="w3-col l4">
                                            <FormField
                                                isRequired
                                                label="Template Type"
                                                controlType={ControlTypes.DropdownSelection}
                                                options={this.state.referenceData.templateTypes}
                                                value={this.state.data.templateTypeId}
                                                onChange={value => this.onChange('templateTypeId', value)}
                                                dataType={DataTypes.Numeric}
                                            />
                                        </div>
                                        <div className="w3-col l4">
                                            <FormField
                                                maxLength={30}
                                                dataType={DataTypes.String}
                                                label="Registration Number"
                                                controlType={ControlTypes.EditBox}
                                                value={this.state.data.plateRegistrationNumber}
                                                onChange={value => this.onChange('plateRegistrationNumber', value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="w3-row">
                                        <div className="w3-col l3">
                                            <FormField
                                                isRequired
                                                maxLength={4}
                                                dataType={DataTypes.Numeric}
                                                label="Width"
                                                controlType={ControlTypes.EditBox}
                                                value={this.state.data.width}
                                                onChange={value => { this.onChange('width', value) }}
                                            />
                                        </div>
                                        <div className="w3-col l3">
                                            <FormField
                                                isRequired
                                                maxLength={4}
                                                dataType={DataTypes.Numeric}
                                                label="Height"
                                                controlType={ControlTypes.EditBox}
                                                value={this.state.data.height}
                                                onChange={value => this.onChange('height', value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="w3-row">
                                        <div className="w3-col l3">
                                            <FormField
                                                isRequired
                                                maxLength={4}
                                                dataType={DataTypes.Numeric}
                                                label="Left Space"
                                                controlType={ControlTypes.EditBox}
                                                value={this.state.data.pdfLeftSpacing ?? 0}
                                                onChange={value => { this.onChange('pdfLeftSpacing', value) }}
                                            />
                                        </div>
                                        <div className="w3-col l3">
                                            <FormField
                                                isRequired
                                                maxLength={4}
                                                dataType={DataTypes.Numeric}
                                                label="Right Space"
                                                controlType={ControlTypes.EditBox}
                                                value={this.state.data.pdfRightSpacing ?? 0}
                                                onChange={value => this.onChange('pdfRightSpacing', value)}
                                            />
                                        </div>
                                        <div className="w3-col l3">
                                            <FormField
                                                isRequired
                                                maxLength={4}
                                                dataType={DataTypes.Numeric}
                                                label="Top Space"
                                                controlType={ControlTypes.EditBox}
                                                value={this.state.data.pdfTopSpacing ?? 0}
                                                onChange={value => { this.onChange('pdfTopSpacing', value) }}
                                            />
                                        </div>
                                        <div className="w3-col l3">
                                            <FormField
                                                isRequired
                                                maxLength={4}
                                                dataType={DataTypes.Numeric}
                                                label="Bottom Space"
                                                controlType={ControlTypes.EditBox}
                                                value={this.state.data.pdfBottomSpacing ?? 0}
                                                onChange={value => this.onChange('pdfBottomSpacing', value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="w3-row">
                                            <FormField
                                                controlType={ControlTypes.DisplayLabel}
                                                value={(`Canvas will be ${this.totalWidth()} x ${this.totalHeight()}`)}
                                            />
                                    </div>
                                    <div className="w3-row">
                                        <div className="w3-col l6">
                                            <FormField
                                                isRequired
                                                label="Template Background"
                                                controlType={ControlTypes.DropdownSelection}
                                                value={this.state.data.backgroundImageId}
                                                options={this.state.referenceData.backgroundImageLibraries}
                                                onChange={value => this.onChange('backgroundImageId', value)}
                                                dataType={DataTypes.String}
                                            />
                                        </div>
                                        <div className="w3-col l3">
                                            <FormField
                                                isRequired
                                                label="Allow Advert"
                                                controlType={ControlTypes.CheckBox}
                                                value={this.state.data.allowAdvert}
                                                onChange={value => this.onChange('allowAdvert', value)}
                                                dataType={DataTypes.Boolean}
                                            />
                                        </div>
                                        <div className="w3-col l3">
                                            {this.state.data.allowAdvert &&
                                                <FormField
                                                    isRequired
                                                    maxLength={4}
                                                    dataType={DataTypes.Numeric}
                                                    label="Advert Height"
                                                    controlType={ControlTypes.EditBox}
                                                    value={this.state.data.advertHeight}
                                                    onChange={value => this.onChange('advertHeight', value)}
                                                />
                                            }
                                        </div>
                                    </div>
                                </td>

                                <td style={previewCellStyle} className="w3-centered">
                                    {this.state.isPreviewing &&
                                        <LoadingIcon displayText={this.state.loadingText} />
                                    }
                                    {this.state.previewImage &&
                                        <img className="w3-image animated fadeIn" src={this.state.previewImage} alt="text" />
                                    }
                                </td>
                            </div>
                            <div className="w3-row">
                                <div>
                                    <div className="w3-half">
                                        <h3>Groupings</h3>
                                    </div>
                                    <div className="w3-rest">
                                        <button className="w3-btn w3-green w3-right" onClick={() => { this.onGroupEditClick(); }}>Add Grouping</button>
                                    </div>
                                </div>
                            </div>
                            <div className="w3-row">
                                <Tabs >
                                    <TabList>
                                        {this.state.data.templateGroups.map((group) => {
                                            return (<Tab>{group.description} ({group.groupType})</Tab>);
                                        })
                                        }
                                    </TabList>
                                    {this.state.data.templateGroups.map((group, index) => { // Create the groups.
                                        return (
                                            <TabPanel>
                                                <div className="w3-row">
                                                    <div className="w3-col l1">
                                                        <button className="w3-btn w3-red w3-tiny" onClick={() => { this.onDeleteGroup(group.id); }}>X</button>
                                                    </div>
                                                    <div className="w3-col l2">
                                                        <FormField
                                                            isRequired
                                                            label="Group Font"
                                                            value={group.fontTypeId}
                                                            onChange={value => this.groupHeaderChange(group.id, 'fontTypeId', value)}
                                                            controlType={ControlTypes.DropdownSelection}
                                                            options={this.state.referenceData.fontTypes}
                                                            dataType={DataTypes.Numeric}
                                                        />
                                                    </div>
                                                    <div className="w3-col l1">
                                                        <FormField
                                                            isRequired
                                                            label="Display Inverted"
                                                            dataType={DataTypes.Boolean}
                                                            value={group.inverted}
                                                            onChange={value => this.groupHeaderChange(group.id, 'inverted', value)}
                                                            controlType={ControlTypes.CheckBox}
                                                        />
                                                    </div>

                                                    {group.groupTypeId === 1 &&
                                                        <React.Fragment>
                                                            <div className="w3-col l2">
                                                                <FormField
                                                                    isRequired
                                                                    maxLength={4}
                                                                    dataType={DataTypes.Numeric}
                                                                    label="Component Spacing"
                                                                    controlType={ControlTypes.EditBox}
                                                                    value={group.staticSpacing}
                                                                    onChange={value => this.groupHeaderChange(group.id, 'staticSpacing', value)}
                                                                />
                                                            </div>
                                                            <div className="w3-col l1">
                                                                <FormField
                                                                    isRequired
                                                                    maxLength={4}
                                                                    dataType={DataTypes.Numeric}
                                                                    label="X"
                                                                    controlType={ControlTypes.EditBox}
                                                                    value={group.staticAnchorX}
                                                                    onChange={value => this.groupHeaderChange(group.id, 'staticAnchorX', value)}
                                                                />
                                                            </div>
                                                            <div className="w3-col l1">
                                                                <FormField
                                                                    isRequired
                                                                    maxLength={4}
                                                                    dataType={DataTypes.Numeric}
                                                                    label="Y"
                                                                    controlType={ControlTypes.EditBox}
                                                                    value={group.staticAnchorY}
                                                                    onChange={value => this.groupHeaderChange(group.id, 'staticAnchorY', value)}
                                                                />
                                                            </div>
                                                            <div className="w3-col l2">
                                                                <FormField
                                                                    isRequired
                                                                    maxLength={4}
                                                                    dataType={DataTypes.Numeric}
                                                                    label="Text Height (mm)"
                                                                    controlType={ControlTypes.EditBox}
                                                                    value={group.staticTextHeight}
                                                                    onChange={value => this.groupHeaderChange(group.id, 'staticTextHeight', value)}
                                                                />
                                                            </div>
                                                        </React.Fragment>

                                                    }
                                                </div>


                                                <TemplateListTab
                                                    components={
                                                        this.state.data.templateGroups[index] &&
                                                        this.state.data.templateGroups[index].components}
                                                    refData={this.state.referenceData}
                                                    onClick={this.onComponentClick}
                                                    onDeleteClick={this.onDeleteComponentClick}
                                                    groupIndex={index}
                                                />
                                                <div className="w3-right-align">
                                                    <button onClick={() => { this.addComponent(index); }} className="w3-btn w3-blue w3-padding-small w3-round">Add New Component</button>
                                                </div>
                                            </TabPanel>
                                        );
                                    })
                                    }
                                </Tabs>
                            </div>
                        </div>
                    )}
                </AuthorisedArea>
                {this.state.referenceData &&
                    <React.Fragment>
                        <div id="ComponentEditorPopup" className="w3-modal">
                            <div className="w3-modal-content">
                                <TemplateGroup
                                    onSave={this.onSaveComponent}
                                    onCancel={this.closeModal}
                                    refdata={this.state.referenceData}
                                    selectedComponent={this.state.selectedComponent}
                                    relativeComponents={this.state.relativeComponents}
                                />
                            </div>
                        </div>
                        <div id="GroupEditorPopup" className="w3-modal">
                            <div className="w3-modal-content">
                                <GroupEditor
                                    fontTypes={this.state.referenceData.fontTypes}
                                    groupTypes={this.state.referenceData.groupTypes}
                                    onSave={this.onSaveGroup}
                                    onCancel={this.closePlateGroupModal}
                                />
                            </div>
                        </div>
                    </React.Fragment>
                }
            </div>
        );
    }
}
