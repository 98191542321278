import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ControlLabel from './controlLabel';

export default class CheckBox extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange() {
    this.props.onChange(!this.props.value);
  }

  render() {
    return (
      <div className="w3-container">
        <div>
          <ControlLabel text={this.props.label} />
        </div>
        <div className="ws-padding">
          <input type="checkbox" className="w3-check" checked={this.props.value} onClick={this.onChange} />
        </div>
      </div>
    );
  }
}

CheckBox.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
};
