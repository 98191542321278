import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import Moment from 'react-moment';
import PageHeader from '../common/pageHeader';
import ClientDataAccessor from '../../dataAccess/client';
import AuthorisedArea from '../../helpers/AuthorisedArea';
import TrueFalseDisplay from '../common/inputs/components/TrueFalseDisplay';
import LoadingIcon from '../../images/loadingIcon.svg';

const tableHeaderStyle = {
  background: '#09adf4',
  color: 'white',
};

const buttonPanelStyle = {
  paddingBottom: '5px',
};

class MyClients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      clients: [],
    };
    this.handleSelection = this.handleSelection.bind(this);
    this.isCancelled = false;
  }

  /**
   * Executed once the component mounts. Loads the data.
   */
  componentDidMount() {
    if (!this.isCancelled) this.loadClientList();
  }

  /**
   * Executes when the component is destroyed.
   */
  componentWillUnmount() {
    this.isCancelled = true;
  }

  /**
   * Loads the client list from ther API
   */
  loadClientList() {
    ClientDataAccessor.GetMyClients()
      .then((response) => {
        this.setState({ clients: response, isLoading: false });
      })
      .catch((error) => {
        console.log('Got error on list screen', error);
      });
  }

  /**
   * Handles the selection of an item in the client list.
   * @param {*} clientId The ID of the client.
   */
  handleSelection(clientId) {
    this.props.history.push(`/myclient/${clientId}`);
  }

  render() {
    const menuItems = [];

    return (

      <div className="w3-container">
        <PageHeader Heading="My Clients" MenuItems={menuItems} />

        <AuthorisedArea showError>
          <table className="w3-table-all w3-card-4 w3-hoverable w3-border">
            <thead >
              <tr style={tableHeaderStyle}>
                <td>Client Name</td>
                <td>Registration No.</td>
                <td>Templates</td>
                <td>Prints</td>
                <td>Login Count</td>
                <td>Last Visit</td>
                <td>Status</td>
              </tr>
            </thead>
            {!this.state.isLoading &&
              <tbody>
                {this.state.clients.map(client => (
                  <tr className="tableRow" onClick={() => { this.handleSelection(client.id); }}  key={client.id}>
                    <td>{client.name}</td>
                    <td>{client.registrationNumber}</td>
                    <td>{client.statistics.assignedTemplateCount}</td>
                    <td>{client.statistics.lastLogin &&
                      client.statistics.platesPrinted}
                    </td>
                    <td>{client.statistics.lastLogin &&
                      client.statistics.loginCount}
                    </td>
                    <td>{client.statistics.lastLogin &&
                      <Moment fromNow utc ago>{client.statistics.lastLogin}</Moment>}
                    </td>
                    <td><TrueFalseDisplay value={client.clientStatusTypeId === 1} /></td>
                  </tr>
                                ))}
              </tbody>
            }
          </table>
          {this.state.isLoading &&
            <div className="w3-full w3-center">
              <LoadingIcon />
            </div>
          }
        </AuthorisedArea>
      </div>

    );
  }
}

export default withRouter(MyClients);
