import fetchData from '../helpers/fetchData';
import Server from '../constants/environment';

const isReponseCodeOK = num => num.toString()[0] === '2';
const loginURL = `${Server.getConnection()}/api/login`;

class AuthenticationDataAccessor {
  static authenticateCredentials(userName, password) {

    return new Promise((resolve, reject) => {
      const request = {
        URL: loginURL,
        method: 'POST',
        data: { username: userName, password, frontEndVersion: Server.getBuildNumber() },
      };

      fetchData(request)
        .then((response) => {
          const payload = response.json();
          const status = response.status;
          if (isReponseCodeOK(status)) {
            resolve(payload);
          } else {
            reject(new Error('Failed'));
          }
        })
        .catch((err) => {
          console.log('Error caught!', err);
          reject(err);
        });
    });
  }
}

export default AuthenticationDataAccessor;
