import React from 'react';
import ReCaptcha from 'react-google-recaptcha';
import toast from 'react-hot-toast';
import FormField, { DataTypes, ControlTypes } from '../common/inputs/formField';
import ClientDataAccessor from '../../dataAccess/client';
import LoadingIcon from '../../images/loadingIcon.svg';
import Translate from "../../internationalisation/translations";
import { UserContext } from '../../contexts/userContext';

const sentStyle = {
  margin: 'auto',
  padding: '20px',
  color: 'white',
  backgroundColor: 'green',
};

export default class ContactForm extends React.Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      form: {
        name: null,
        subject: null,
        email: null,
        content: null,
        telephone: null,
      },
      canPost: false,
      posted: false,
      recaptchaResponse: null,
      isSending: false,
    };

    this.onChange = this.onChange.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.onSendClick = this.onSendClick.bind(this);
  }

  /**
   * Handle when ever a form field is changed.
   * @param {*} fieldName The name of the field being changed
   * @param {*} value The value that the field should now be
   */
  onChange(fieldName, value) {
    const thisData = { ...this.state.form, [fieldName]: value };
    this.setState({
      form: thisData,
    });
  }

  /**
   * Event to handle the click event went sending the message.
   */
  onSendClick() {
    if (!this.state.recaptchaResponse || !this.state.canPost) return;

    this.setState({ isSending: true });
    ClientDataAccessor.SendMessage(
      this.state.form.subject,
      this.state.form.name,
      this.state.form.email,
      this.state.form.content,
      this.state.recaptchaResponse,
    )
      .then((result) => {
        if(result.isSuccess) {
          this.setState({isSending: false, posted: true});
          toast.success(result.responseText);
        } else {
          this.setState({ isSending: false });
          toast.error(error);
          console.log(error);
        }
      })
      .catch((error) => {
        this.setState({ isSending: false });
        toast.error(error);
        console.log(error);
      });
  }

  /**
   * ReCaptcha Callback handler. Executed when we get a successful
   * result from the captcha challenge.
   * @param {*} response The response from google.
   */
  verifyCallback(response) {
    this.setState({ canPost: true, recaptchaResponse: response });
  }

  render() {

    const {languageCode} = this.context;
    
    if (this.state.posted) {
      return (
        <div className="w3-row animated fadeIn">
          <div className="w3-container">
            <div className="w3-green" style={sentStyle}>
              Message delivered. Someone from SmartPlates will respond to you as soon as possible.
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className="w3-row">
          <div className="w3-col">
            <FormField
              dataType={DataTypes.String}
              controlType={ControlTypes.EditBox}
              label={Translate.getTranslation(languageCode, "TEXT_Name")}
              value={this.state.form.name}
              onChange={value => this.onChange('name', value)}
              required
            />
            <FormField
                dataType={DataTypes.String}
                controlType={ControlTypes.EditBox}
                label={Translate.getTranslation(languageCode, "TEXT_Subject")}
                value={this.state.form.subject}
                onChange={value => this.onChange('subject', value)}
                required
            />
            <FormField
              dataType={DataTypes.String}
              controlType={ControlTypes.EditBox}
              required
              value={this.state.form.email}
              onChange={value => this.onChange('email', value)}
              label={Translate.getTranslation(languageCode, "TEXT_Email")}
            />
            <FormField
              dataType={DataTypes.String}
              controlType={ControlTypes.TextArea}
              required
              value={this.state.form.content}
              onChange={value => this.onChange('content', value)}
              label={Translate.getTranslation(languageCode, "TEXT_Message")}
            />
            {!this.state.posted &&
              <div className="w3-row">
                <div className="w3-container">
                  <div className="w3-half">
                    <ReCaptcha sitekey="6Lch7ngUAAAAANjl5dEoXkHFcfaMRlwDH4qjluQY" onChange={this.verifyCallback} />
                  </div>
                  <div className="w3-rest  w3-right-align">
                    {!this.state.isSending &&
                      <button 
                        className="w3-btn w3-green" 
                        disabled={!this.state.canPost || this.state.isSending || !this.state.form.name || !this.state.form.email || !this.state.form.subject || !this.state.form.content} 
                        onClick={() => { this.onSendClick(); }}
                      >
                        {Translate.getTranslation(languageCode, "TEXT_Send")}
                      </button>
                    }
                    {this.isSending &&
                      <LoadingIcon />
                    }
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}
