import React, {Component} from 'react';
import {withRouter, Link} from 'react-router-dom';
import Moment from 'react-moment';
import PageHeader from '../common/pageHeader';
import ClientDataAccessor from '../../dataAccess/client';
import AuthorisedArea from '../../helpers/AuthorisedArea';
import LoadingIcon from '../../images/loadingIcon.svg';
import Auth from "../../authentication";
import FormField, {ControlTypes, DataTypes} from "../common/inputs/formField";

const tableHeaderStyle = {
    background: '#09adf4',
    color: 'white',
};

class History extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            generations: [],
            selectedClientId: null,
            clients: []
        };
        this.handleSelection = this.handleSelection.bind(this);
        this.loadClients = this.loadClients.bind(this);
        this.isCancelled = false;
    }

    /**
     * Executed once the component mounts. Loads the data.
     */
    componentDidMount() {
        if (!this.isCancelled) this.loadHistory();
    }

    /**
     * Executes when the component is destroyed.
     */
    componentWillUnmount() {
        this.isCancelled = true;
    }

    handleChange(field, value) {
        this.setState({
            [field]: value
        }, () => this.loadGenerations())
    }

    /**
     * Loads the client list from their API
     */
    loadHistory() {
        this.loadClients();
        this.loadGenerations();
    }

    loadGenerations() {
        if (this.state.selectedClientId) {
            ClientDataAccessor.GetPlateGenerationsByClient(this.state.selectedClientId, 1, 25)
                .then((response) => {
                    this.setState({generations: response.payload, isLoading: false});
                })
                .catch((error) => {
                    console.log('Got error on list screen', error);
                });
        } else {
            ClientDataAccessor.GetAllGenerations(1, 25)
                .then((response) => {
                    this.setState({generations: response.payload, isLoading: false});
                })
                .catch((error) => {
                    console.log('Got error on list screen', error);
                });
        }
    }

    loadClients() {
        ClientDataAccessor.GetSimpleList()
            .then((response) => {
                this.setState({clients: response.payload, isLoading: false});
            })
            .catch((error) => {
                console.log('Got error on list screen', error);
            });

    }

    /**
     * Handles the selection of an item in the client list.
     * @param {*} clientId The ID of the client.
     */
    handleSelection(clientId) {
        this.props.history.push(`/myclient/${clientId}`);
    }

    render() {
        const menuItems = [];

        return (

            <div className="w3-container">
                <PageHeader Heading="Client Plate History" MenuItems={menuItems}/>

                <AuthorisedArea showError>

                    <FormField
                        label="Client Filter"
                        value={this.state.selectedClientId}
                        onChange={value => this.handleChange('selectedClientId', value)}
                        options={this.state.clients}
                        controlType={ControlTypes.DropdownSelection}
                        dataType={DataTypes.String}
                    />

                    <table className="w3-table-all w3-card-4 w3-hoverable w3-border">
                        <thead>
                        <tr style={tableHeaderStyle}>
                            <td>Date</td>
                            <td>Client</td>
                            <td>Customer Name</td>
                            <td>Id</td>
                            <td>Id Type</td>
                            <td>Template</td>
                            <td>Text</td>
                            <td>Plate</td>
                        </tr>
                        </thead>
                        {!this.state.isLoading &&

                        <tbody>
                        {this.state.generations.map(generation => (
                            <tr className="tableRow">
                                <td><Moment local
                                            format="Do MMM[,] YYYY, HH:mm">{generation.dateGenerated}</Moment><br/><small>{generation.clientUser}</small>
                                </td>
                                <td><strong>{generation.client}</strong></td>
                                <td>{generation.customerName}</td>
                                <td>{generation.customerId}</td>
                                <td>{generation.identificationType}</td>
                                <td>{generation.template}</td>
                                <td>{generation.friendlyInputText}</td>
                                <td><img src={generation.url} alt={generation.inputText}/></td>

                            </tr>
                        ))}
                        </tbody>
                        }
                    </table>
                    {this.state.generations.length === 0 &&
                    <div><br/>
                        <center><strong>You have not generated any plates yet</strong></center>
                        <br/></div>
                    }

                    {this.state.isLoading &&
                    <div className="w3-full w3-center">
                        <LoadingIcon/>
                    </div>
                    }
                </AuthorisedArea>
            </div>

        );
    }
}

export default withRouter(History);
