import React from 'react';
import PageHeader from '../common/pageHeader';
import AuthorisedArea from '../../helpers/AuthorisedArea';
import FormField, { ControlTypes, DataTypes } from '../common/inputs/formField';
import LoadingIcon from '../common/loadingIcon';
import ImageDataAccessor from '../../dataAccess/image';
import toast from 'react-hot-toast';

let menuItems = []

const menuButtonStyle = {
  marginLeft: '3px',
};

const detailsCellStyle = {
  width: '70%',
};

const previewCellStyle = {
  verticalAlign: 'middle',
  textAlign: 'center',
};

export default class Background extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isDirty: false,
      imageType: null,
      returnUrl: null,
      imageDescription: null,
      data: {
        id: null,
        filename: null,
        description: null,
        displayText: null,
        base64Data: null,
        fileSize: null,
        contentType: null,
        url: null
      },
    };

    this.handleChangeImage = this.handleChangeImage.bind(this);
    this.handleClickSave = this.handleClickSave.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  componentDidMount() {
    const thisId = this.props.match.params.imageId;

    let imageType = window.location.href.search("/background") === -1 ? 2 : 1

    const returnUrl = imageType === 1 ? "/images/backgrounds" : "/images/customimages"
    const imageDescription = imageType === 1 ? "Backgrounds" : "Custom Images"

    this.setState({
      imageType,
      imageDescription
    })
    
    menuItems = [{ Display: imageDescription, Url: returnUrl }];
    
    if(thisId) {
      ImageDataAccessor.GetImageById(thisId)
        .then((result) => {
          this.setState({ 
            data: result.payload, 
            isLoading: false,
            isDirty: false 
          });
        })
        .catch((e) => {
          this.props.history.push('/');
        });
    } else {
      this.setState({ isLoading: false, isDirty: false })
    }
  }

  handleChangeImage(event) {

    const file = event.file;
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = function (e) {
      const data = this.state.data;
      data.base64Data = [reader.result][0]
      data.filename = file.name;
      data.fileSize = file.size;
      data.contentType = file.type;
      data.url = null;
      this.setState({
        data,
        isDirty: true
      })
    }.bind(this);
  }
  

  handleOnChange(fieldName, fieldValue) {
    const newState = { ...this.state.data };
    newState[fieldName] = fieldValue;
    this.setState({ data: newState, isDirty: true });
  }

  handleDeleteClick() {
    ImageDataAccessor.DeleteImage(this.state.data.id)
      .then((incomingData) => {
        toast.success(incomingData.responseText);
        if(incomingData.isSuccess) {
          this.setState({ 
            isLoading: false, 
            isDirty: false }, () => {
              this.props.history.goBack();
          });
        }
      })
      .catch((e) => {
        console.log(`Error in image save. ${e}`);
      });
  }

  handleClickSave() {

    this.setState({ isLoading: true });

    const data = {
      description: this.state.data.description,
      displayText: this.state.data.displayText,
      filename: this.state.data.filename,
      base64Data: this.state.data.base64Data,
      id: this.state.data.id,
      ImageType: this.state.imageType,
      fileSize: this.state.data.fileSize,
      contentType: this.state.data.contentType
    }

    ImageDataAccessor.SaveImage(data)
      .then((incomingData) => {
        if(incomingData.isSuccess) {
          toast.success(incomingData.responseText);
          this.setState({ 
            isLoading: false, 
            isDirty: false }, () => {
              this.props.history.goBack();
          });
        } else {
          toast.error(incomingData.responseText);
        }
      })
      .catch((e) => {
        console.log(`Error in image save. ${e}`);
      });
  }

  render() {
    let imgSource
    if( this.state.data.base64Data ) {
      imgSource = this.state.data.base64Data
    }
    if( this.state.data.url ) {
      
      imgSource = this.state.data.url + "?r=" + new Date()
    }

    return (
      <div className="w3-container">
        <PageHeader Heading={this.state.imageDescription} MenuItems={menuItems} />

        <AuthorisedArea requiredAdmin showError>
          <div className="w3-row">
            <div className="w3-col w3-right-align">
              <button className="w3-btn w3-red w3-round" disabled={this.state.isLoading} onClick={() => { this.handleDeleteClick(); }} style={menuButtonStyle} >Delete</button>
              <button className="w3-btn w3-green w3-round" disabled={!this.state.isDirty} onClick={() => { this.handleClickSave(); }} style={menuButtonStyle} >Save</button>
              <button className="w3-btn w3-blue w3-round" style={menuButtonStyle} onClick={() => { this.props.history.goBack(); }} >Cancel</button>
            </div>
          </div>
          {this.state.isLoading &&
            <div className="w3-full w3-center">
              <LoadingIcon />
            </div>
          }
          {!this.state.isLoading &&
            <div className="w3-table">
              <td style={detailsCellStyle}>
                <div className="w3-row">
                  <div className="w3-col l8">
                    <FormField
                      isRequired
                      maxLength={30}
                      dataType={DataTypes.String}
                      value={this.state.data.description}
                      label="Image Name"
                      controlType={ControlTypes.EditBox}
                      onChange={value => this.handleOnChange('description', value)}
                    />
                    <FormField
                      isRequired
                      maxLength={30}
                      dataType={DataTypes.String}
                      label="Display Name"
                      controlType={ControlTypes.EditBox}
                      value={this.state.data.displayText}
                      onChange={value => this.handleOnChange('displayText', value)}
                    />
                    <FormField
                      isRequired
                      label="Upload new File"
                      controlType={ControlTypes.FileUpload}
                      onChange={e => this.handleChangeImage(e)}
                      dataType={DataTypes.String}
                    />
                  </div>
                </div>
              </td>
              <td className="w3-centered" style={previewCellStyle}>
                {imgSource &&
                  <img className="w3-image animated fadeIn" src={imgSource} alt={this.state.data.description} />
                }
              </td>
            </div>
          }
        </AuthorisedArea>
      </div>
    );
  }
}
