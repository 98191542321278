import fetchData from '../helpers/fetchData';
import Server from '../constants/environment';

class MessagingDataAccessor {
    static GetAll(page, size) {
        return new Promise((resolve, reject) => {
            const request = {
                method: 'GET',
                URL: `${Server.getConnection()}/api/messaging?page=${page}&size=${size}`,
            };

            fetchData(request)
                .then((result) => {
                    const payload = result.json();
                    resolve(payload);
                })
                .catch((error) => {
                    reject(new Error(error));
                });
        });
    }

    static DeleteById(messageId) {
        return new Promise((resolve, reject) => {
            const request = {
                method: 'DELETE',
                URL: `${Server.getConnection()}/api/messaging/${messageId}`,
            };

            fetchData(request)
                .then((result) => {
                    const payload = result.json();
                    resolve(payload);
                })
                .catch((error) => {
                    reject(new Error(error));
                });
        })
    }

    static MarkRead(messageId) {
        return new Promise((resolve, reject) => {
            const request = {
                method: 'POST',
                URL: `${Server.getConnection()}/api/messaging/${messageId}/view`,
            };

            fetchData(request)
                .then((result) => {
                    const payload = result.json();
                    resolve(payload);
                })
                .catch((error) => {
                    reject(new Error(error));
                });
        })
    }

}

export default MessagingDataAccessor;