import fetchData from '../helpers/fetchData';
import Server from '../constants/environment';
import Auth from "../authentication";

const isReponseCodeOK = num => num.toString()[0] === '2';

class TemplateDataAccessor {
  /**
   * Gets all templates in the system.
   * Note: Needs the user to be an Admin.
  */
  static GetAll() {
    const request = {
      method: 'GET',
      URL: `${Server.getConnection()}/api/template`,
    };
    return new Promise((resolve, reject) => {
      fetchData(request)
        .then((result) => {
          const payload = result.json();
          resolve(payload);
        })
        .catch((error) => {
          reject(new Error(error));
        });
    });
  }

  /**
   * Gets list of templates allocated to the currently authenticated user.
  */
  static GetForCurrentClient() {
    const clientId = Auth.clientId();
    const request = {
      method: 'GET',
      URL: `${Server.getConnection()}/api/client/${clientId}/templates`,
    };
    return new Promise((resolve, reject) => {
      fetchData(request)
        .then((result) => {
          if (isReponseCodeOK(result.status)) {
            const payload = result.json();
            resolve(payload);
          }
          reject(new Error(`Bad response from server (${Response.status})..`));
        })
        .catch((error) => {
          reject(new Error(error));
        });
    });
  }

  /**
   * Get a template by it's ID
   */
  static GetById(id, includePreview) {
    const request = {
      method: 'GET',
      URL: `${Server.getConnection()}/api/template/${id}`,
    };
    if (includePreview) {
      request.URL += '?includePreview=true';
    }

    return new Promise((resolve, reject) => {
      fetchData(request)
        .then((result) => {
          const payload = result.json();
          resolve(payload);
        })
        .catch((error) => {
          reject(new Error(error));
        });
    });
  }

  /**
   * Get a generated plate, but in Base64 format
   */
  static GetBase64ByUrl(generationId) {
    const request = {
      method: 'GET',
      URL: `${Server.getConnection()}/api/template/base64/${generationId}`,
    };

    return new Promise((resolve, reject) => {
      fetchData(request)
          .then((result) => {
            const payload = result.json();
            resolve(payload);
          })
          .catch((error) => {
            reject(new Error(error));
          });
    });
  }

  /**
   * Preview a template by passing in the template to preview
   * The reason for passing the template, as opposed to the ID,
   * is that it now allows us to preview before saving.
   */
  static Preview(data) {
    const request = {
      method: 'POST',
      URL: `${Server.getConnection()}/api/template/preview`,
      data,
    };

    return new Promise((resolve, reject) => {
      fetchData(request)
        .then((result) => {
          const payload = result.json();
          resolve(payload);
        })
        .catch((error) => {
          reject(new Error(error));
        });
    });
  }

  /**
   * Generate a plate by passing in the template to generate
   */
  static Generate(data) {
    const request = {
      method: 'POST',
      URL: `${Server.getConnection()}/api/template/generate`,
      data,
    };

    return new Promise((resolve, reject) => {
      fetchData(request)
        .then((result) => {
          const payload = result.json();
          resolve(payload);
        })
        .catch((error) => {
          reject(new Error(error));
        });
    });
  }

  /**
   * Save a template.
   */
  static Save(data) {
    const request = {
      method: 'POST',
      URL: `${Server.getConnection()}/api/template/`,
      data,
    };

    return new Promise((resolve, reject) => {
      fetchData(request)
        .then((result) => {
          const payload = result.json();
          resolve(payload);
        })
        .catch((error) => {
          reject(new Error(error));
        });
    });
  }

  /**
   * Deletes a specified template
   * @param {Primary key of the template to delete} id
   */
  static Delete(id) {
    const request = {
      method: 'DELETE',
      URL: `${Server.getConnection()}/api/template/${id}`,
    };

    return new Promise((resolve, reject) => {
      fetchData(request)
        .then((result) => {
          const payload = result.json();
          resolve(payload);
        })
        .catch((error) => {
          reject(new Error(error));
        });
    });
  }
}

export default TemplateDataAccessor;
