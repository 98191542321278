import React from 'react';
import PropTypes from 'prop-types';
import EditBox from './components/editbox';
import Dropdown from './components/dropdown';
import DisplayBoolean from './components/displayBoolean';
import DisplayLabel from './components/displaylabel';
import CheckBox from './components/checkBox';
import FileUpload from './components/fileUpload';
import TextArea from './components/textArea';

export const ControlTypes = {
  EditBox: 1,
  DisplayLabel: 2,
  DisplayBoolean: 3,
  DropdownSelection: 4,
  ActionButton: 5,
  CheckBox: 6,
  FileUpload: 7,
  TextArea: 8,
};

export const DataTypes = {
  String: 1,
  Numeric: 2,
  Date: 3,
  Password: 4,
  DateRelative: 5,
  Binary: 6,
  Boolean: 7,
};

export default class FormField extends React.Component {
  
  constructor(props) {
    super(props)

    this.state = {
      value:    this.props.value,
    }
  }

componentWillUpdate(nextProps, nextState, nextContext) {
    if(nextProps.value !== this.state.value) {
      this.setState({value: nextProps.value})
    }
}


  /**
   * Handle all the changes to the value of the field.
   * @param {The value that the control will be set to} value 
   */
  handleOnChange(value) {
    // First check. If required, and we have no value, it's invalid. We continue the rest of the checks though.
    let isValid = true;
    if(this.props.isRequired && (!value || value.length === 0))
    {
      isValid = false;
    }
    
    // Check the mask
    if(this.props.mask) {
      isValid =  this.validateMask(value);
    }
    
    
    // Now check if we have exceeded a max length
    if(this.props.maxLength) {
      if(value.length > this.props.maxLength) {
        isValid = false
      }
    }
    if (this.props.dataType === DataTypes.Numeric && value.length !== 0) {
      if(isNaN(value))
      {
        isValid = false
        value = null
      } else {
        if(value.length > 0)
        value = parseInt(value)
      }
    }

    this.setState({
      isValid,
      value
    })
    this.props.onChange(value);
  }

  validateMask(value) {
    if(this.props.mask) {
      const isOk = this.props.mask.test(value);
      return isOk;
    }
    return true;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.value !== this.state.value) {
      this.setState({
        value: this.props.value
      })
    }
  }


  render() {
    let thisControl = null;
  
    switch (this.props.controlType) {
      case ControlTypes.EditBox:
        {
          thisControl = (
            <EditBox
              isRequired={this.props.isRequired}
              isReadonly={this.props.isReadonly}
              maxLength={this.props.maxLength}
              dataType={this.props.dataType}
              label={this.props.label}
              value={this.state.value}
              onChange={(value) => this.handleOnChange(value)}
              isActionButton={this.props.isActionButton}
              actionButtonText={this.props.actionButtonText}
              actionButtonCallback={this.props.actionButtonCallback}
              isPassword={this.props.controlType === ControlTypes.Password}
              isValid={this.state.isValid}
              mask={this.props.mask}
              id={this.props.id}
              onKeyPress={this.props.onKeyPress}
            />
          );
          break;
        }
      case ControlTypes.Password: {
        thisControl = (<EditBox
          isRequired={this.props.isRequired}
          isReadonly={this.props.isReadonly}
          maxLength={this.props.maxLength}
          dataType={this.props.dataType}
          label={this.props.label}
          value={this.state.value}
          onChange={(value) => this.handleOnChange(value)}
          isActionButton={this.props.isActionButton}
          actionButtonText={this.props.actionButtonText}
          actionButtonCallback={this.props.actionButtonCallback}
          isPassword={this.props.controlType === ControlTypes.Password}
          isValid={this.state.isValid}
          id={this.props.id}
          onKeyPress={this.props.onKeyPress}

        />);
        break;
      }
      case ControlTypes.TextArea: {
        thisControl = (<TextArea
          isRequired={this.props.isRequired}
          isReadonly={this.props.isReadonly}
          label={this.props.label}
          value={this.state.value}
          onChange={(value) => this.handleOnChange(value)}
          isValid={this.state.isValid}

          />);
        break;
      }
      case ControlTypes.CheckBox: {
        thisControl = (<CheckBox
          isRequired={this.props.isRequired}
          isReadonly={this.props.isReadonly}
          label={this.props.label}
          value={this.state.value}
          onChange={(value) => this.handleOnChange(value)}
          />);
        break;
      }
      case ControlTypes.DropdownSelection: {
        thisControl = (
          <Dropdown
            isRequired={this.props.isRequired}
            isReadonly={this.props.isReadonly}
            label={this.props.label}
            value={this.state.value}
            onChange={(value) => this.handleOnChange(value)}
            options={this.props.options}
            dataType={this.props.dataType}
            isValid={this.state.isValid}

          />);
        break;
      }
      case ControlTypes.DisplayBoolean: {
        thisControl = (
          <DisplayBoolean
            label={this.props.label}
            value={this.state.value}
            trueText={this.props.trueText}
            falseText={this.props.falseText}
          />
        );
        break;
      }
      case ControlTypes.DisplayLabel: {
        thisControl = (
          <DisplayLabel
            label={this.props.label}
            value={this.state.value}
            onChange={this.props.onChange}
            isReadonly={this.props.isReadonly}
            isRequired={this.props.isRequired}
            dataType={this.props.dataType}
          />
        );
        break;
      }
      case ControlTypes.FileUpload: {
        thisControl = (
          <FileUpload
            label={this.props.label}
            value={this.state.value}
            onChange={(value) => this.props.onChange(value)}
            isReadonly={this.props.isReadonly}
            isRequired={this.props.isRequired}
          />
        );
        break;
      }
      default: {
        thisControl = <div>Error - No such control</div>;
      }
    }

    return (
      <div>{thisControl}</div>
    );
  }
}

FormField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  isRequired: PropTypes.bool,
  isReadonly: PropTypes.bool,
  isActionButton: PropTypes.bool,
  actionButtonText: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.any, PropTypes.string),
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  trueText: PropTypes.string,
  falseText: PropTypes.string,
  dataType: PropTypes.number.isRequired,
  mask: PropTypes.string,
  id: PropTypes.string,
};

FormField.defaultProps = {
  isRequired: false,
  isReadonly: false,
  isActionButton: false,
  options: [],
  maxLength: 30,
  onChange: null,
  trueText: null,
  falseText: null,
  actionButtonText: null,
  value: null,
  mask: null,
  id: null,
};
