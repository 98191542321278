import React, { Component } from 'react';
import image from '../../../images/banner.jpg';

const imgStyle = {
  width: '100%',
  objectFit: 'cover',
  height: '150px',
};

export default class Jumbotron extends Component {
  render() {
    return (
      <div>
        <img src={image} alt="Lights" style={imgStyle} />
      </div>
    );
  }
}
