import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ControlLabel from './controlLabel';
import {DataTypes} from "../formField";

const groupStyle = {
    display: 'inline-block',
};

export default class Editbox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isValid: this.props.isValid,
            value: this.props.value
        };

        this.change = this.change.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
    }

    
    
    componentWillReceiveProps(newProps) {
        if (newProps.isValid !== this.state.isValid) {
            this.setState({
                isValid: newProps.isValid
            })
        }

        if (newProps.value !== this.state.value) {
            this.setState({value: newProps.value})
        }
    }

    change(event) {
        this.props.onChange(event.target.value);
    }

    // Check if we have a mask or require numeric
    onKeyPress(event) {

        // Do we have a callback to handle key presses?
        if(this.props.onKeyPress)
            this.props.onKeyPress(event);

        // If we're numeric, lets validate that.
        if (this.props.mask) {
            // Check it's a valid key
            let isOK = this.props.mask.test(event.key);

            if (!isOK) {
                // If it's not OK, cancel the key press.
                event.preventDefault();
            }
        }

        if (this.props.dataType === DataTypes.Numeric) {
            // Check it's a valid key
            let isOK = /^[0-9]$/i.test(event.key);

            // If it's not OK, cancel the key press.
            if (!isOK) {
                event.preventDefault();
            }
        }
    }


    render() {
        const editType = this.props.isPassword && this.props.isPassword === true ? 'password' : 'text';
        const isReadonlyType = {readOnly: this.props.isReadonly && this.props.isReadonly === true};
        const editStyle = {background: '#ffffff'};
        
        if (this.state.isValid === false) {
            editStyle.background = '#ffe0e0';
        }
        if (isReadonlyType.readOnly) {
            editStyle.background = '#f2f2f2';
        }

        const extraButton = (
            <button className="w3-btn w3-blue" type="button"
                    onClick={this.props.actionButtonCallback}>{this.props.actionButtonText}</button>
        );

        return (
            <div className="w3-container w3-padding">
                <div className="w3-row">
                    <div className="w3-col notranslate">
                        <ControlLabel text={this.props.label} isRequired={this.props.isRequired}/>
                    </div>
                </div>
                <div className="w3-row">
                    <div className="w3-col notranslate" style={groupStyle}>
                        <input
                            id={this.props.id}
                            className="w3-input w3-border w3-round"
                            type={editType}
                            placeholder={this.props.label}
                            style={editStyle}
                            value={this.state.value}
                            onKeyUp={this.validation}
                            onChange={(event) => this.change(event)}
                            readOnly={isReadonlyType.readOnly}
                            onKeyPress={(event) => this.onKeyPress(event)}
                        />
                        {this.props.isActionButton && extraButton}
                    </div>
                </div>
            </div>
        );
    }
}

Editbox.propTypes = {
    isRequired: PropTypes.bool,
    dataType: PropTypes.number,
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isReadonly: PropTypes.bool,
    isActionButton: PropTypes.bool,
    actionButtonText: PropTypes.string,
    maxLength: PropTypes.number,
    id: PropTypes.string
};

Editbox.defaultProps = {
    isRequired: false,
    isReadonly: false,
    isActionButton: false,
    actionButtonText: '',
    dataType: 1,
    maxLength: 20,
    value: '',
    id: ''
};
