import React from "react";
import AnalyticsDataAccessor from "../../dataAccess/analytics";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import PageHeader from "../common/pageHeader";
import AuthorisedArea from "../../helpers/AuthorisedArea";


class GenerationsReport extends React.Component {
    
    
    constructor(props) {
        super(props);
        
        
        this.state = {
            data: []
        }
    }
    
    
    componentDidMount() {
        this.getData();
    }

    getData() {
        
        const data = AnalyticsDataAccessor.GetGenerations()
            .then((data) => {
                this.setState({
                    data: data.items
                })
            })
        
    }
    
    render() {

        const data = this.state.data.map((item) => (
            {
                name: item.date,
                value: item.value
            }
            )
        )

        const MenuItems = [];
        const displayHeading =  'Client Plate Generations';
        
        return(
        
            <React.Fragment>
                <PageHeader Heading={displayHeading} MenuItems={MenuItems} />
                <AuthorisedArea requiredAdmin showError>
                    <div className="w3-container">
                            <BarChart
                                width={500}
                                height={300}
                                data={data}
                                margin={{
                                    top: 15,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="1 1" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="value" fill="#8884d8" />
                            </BarChart>
                    </div>
                </AuthorisedArea>
            </React.Fragment>
        )
        
    }
    
    
}

export default GenerationsReport;