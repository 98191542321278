import React from 'react';
import Moment from 'react-moment';

const tableHeaderStyle = {
  background: '#5BC4F2',
  color: 'white',
  width: '100%',
  borderRadius: '8px',
  fontWeight: 'normal',
};
export default class ImageAllocation extends React.Component {
  constructor(props) {
    super(props);
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle(index) {
    this.props.onChange(index);
  }

  render() {
    return (
      <section>

        <table className="w3-table  w3-bordered w3-hoverable">
          <thead style={tableHeaderStyle} className="tableheader">
            <tr>
              <th />
              <th>Image</th>
              <th>Last Modified</th>
            </tr>
          </thead>
          <tbody>
            {this.props.data.map(((image, index) =>
                          (
                            <tr key={image.imageId}>
                              <td>
                                <input
                                  className="w3-check"
                                  type="checkbox"
                                  checked={image.isAllocated}
                                  value={image.clientImageId}
                                  onChange={() => this.handleToggle(index)}
                                />
                              </td>
                              <td><span>{image.description}</span></td>
                              <td><span>{image.isAllocated ? <Moment format="Do [of] MMMM[,] YYYY">{image.dateCreated}</Moment> : ''}</span></td>
                            </tr>
                          )
                        ))}

          </tbody>
        </table>
      </section>
    );
  }
}
