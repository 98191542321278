import React from 'react';
import FormField, {ControlTypes, DataTypes} from '../common/inputs/formField';

const containerStyle = {
    padding: '10px',
};

export default class ClientDetails extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(fieldName, value) {
        this.props.onChange(fieldName, value);
    }

    render() {
        return (
            <div className="w3-row" style={containerStyle}>
                <div className="w3-third">
                    <FormField
                        isRequired
                        label="Client Name"
                        value={this.props.data.name}
                        onChange={value => this.handleChange('name', value)}
                        maxLength={30}
                        controlType={ControlTypes.EditBox}
                        dataType={DataTypes.String}
                    />
                    <FormField
                        isRequired
                        isReadonly={!this.props.isNew}
                        label="Client Type"
                        value={this.props.data.clientTypeId}
                        onChange={value => this.handleChange('clientTypeId', value)}
                        options={this.props.data.referenceData.clientTypes}
                        controlType={ControlTypes.DropdownSelection}
                        dataType={DataTypes.Numeric}
                    />
                    {this.props.data.clientTypeId === 1 &&
                    <FormField
                        isRequired
                        label="Distributor"
                        value={this.props.data.parentClientId}
                        onChange={value => this.handleChange('parentClientId', value)}
                        options={this.props.data.referenceData.distributors}
                        isReadonly={!this.props.isNew}
                        controlType={ControlTypes.DropdownSelection}
                        dataType={DataTypes.String}
                    />
                    }
                    {this.props.data.clientTypeId === 1 &&
                    <FormField
                        isRequired
                        label="User Pin Required"
                        value={this.props.data.userPinRequired}
                        onChange={value => this.handleChange('userPinRequired', value)}
                        isReadonly={!this.props.isNew}
                        controlType={ControlTypes.CheckBox}
                        dataType={DataTypes.Boolean}
                    />
                    }
                  {this.props.data.clientTypeId === 1 &&
                  <FormField
                    isRequired
                    label="Can view Plate History"
                    value={this.props.data.canViewPlateHistory}
                    onChange={value => this.handleChange('canViewPlateHistory', value)}
                    isReadonly={!this.props.isNew}
                    controlType={ControlTypes.CheckBox}
                    dataType={DataTypes.Boolean}
                  />
                  }

                  {this.props.data.clientTypeId === 2 &&
                    <FormField
                        isReadonly={!this.props.isNew}
                        isRequired
                        label="Registration Prefix"
                        value={this.props.data.registrationPrefix}
                        onChange={value => this.handleChange('registrationPrefix', value)}
                        maxLength={2}
                        controlType={ControlTypes.EditBox}
                        dataType={DataTypes.String}
                        mask={/^[0-9]*$/i}
                    />
                    }
                    {this.props.data.clientTypeId === 1 &&
                    <FormField
                        //isRequired
                        label="Client Registration Number"
                        value={this.props.data.registrationNumber}
                        // onChange={value => this.handleChange('registrationNumber', value)}
                        dataType={DataTypes.String}
                        controlType={ControlTypes.EditBox}
                        isActionButton={this.props.isNew && !this.props.data.registrationNumber}
                        isReadonly
                        actionButtonText="Generate"
                        actionButtonCallback={this.props.generateRegistrationNumberEvent}
                    />
                    }
                    <FormField
                        label="VAT Registration Number"
                        value={this.props.data.vatNumber}
                        onChange={value => this.handleChange('vatNumber', value)}
                        dataType={DataTypes.Numeric}
                        isActionButton={!this.props.data.vatNumberValidated}
                        actionButtonCallback={this.props.validateVATEvent}
                        actionButtonText="Validate"
                        isReadonly={this.props.data.vatNumberValidated}
                        controlType={ControlTypes.EditBox}
                    />
                    <FormField
                        label="VAT Number Validated"
                        value={Boolean(this.props.data.vatNumberValidated)}
                        trueText="VAT Number validated"
                        falseText="Vat Number not yet validated"
                        controlType={ControlTypes.DisplayBoolean}
                        dataType={DataTypes.Boolean}
                    />
                </div>

                <div className="w3-third">
                    <FormField
                        isRequired
                        label="Contact Name"
                        value={this.props.data.contactName}
                        onChange={value => this.handleChange('contactName', value)}
                        controlType={ControlTypes.EditBox}
                        dataType={DataTypes.String}
                    />
                    <FormField
                        isRequired
                        label="Country"
                        value={this.props.data.countryTypeId}
                        onChange={value => this.handleChange('countryTypeId', value)}
                        options={this.props.data.referenceData.countryTypes}
                        controlType={ControlTypes.DropdownSelection}
                        dataType={DataTypes.Numeric}
                    />
                    <FormField
                        isRequired
                        label="Email Address"
                        value={this.props.data.email}
                        onChange={value => this.handleChange('email', value)}
                        controlType={ControlTypes.EditBox}
                        dataType={DataTypes.String}
                    />
                    <FormField
                        label="Telephone"
                        value={this.props.data.telephone}
                        onChange={value => this.handleChange('telephone', value)}
                        controlType={ControlTypes.EditBox}
                        dataType={DataTypes.String}
                    />
                </div>

                <div className="w3-third">
                    <FormField
                        isRequired
                        label="Username"
                        value={this.props.data.username}
                        onChange={value => this.handleChange('username', value)}
                        controlType={ControlTypes.EditBox}
                        dataType={DataTypes.String}
                    />
                    <FormField
                        isRequired
                        label="Password"
                        value={this.props.data.password}
                        onChange={value => this.handleChange('password', value)}
                        controlType={this.props.data.showPassword ? ControlTypes.EditBox : ControlTypes.Password}
                        dataType={DataTypes.String}
                    />
                    <FormField
                        isRequired
                        label="Show Password"
                        value={this.props.data.showPassword}
                        onChange={value => this.handleChange('showPassword', value)}
                        isReadonly={!this.props.isNew}
                        controlType={ControlTypes.CheckBox}
                        dataType={DataTypes.Boolean}
                    />
                    <FormField
                        isRequired
                        label="Client Status"
                        value={this.props.data.clientStatusTypeId}
                        onChange={value => this.handleChange('clientStatusTypeId', value)}
                        options={this.props.data.referenceData.clientStatusTypes}
                        controlType={ControlTypes.DropdownSelection}
                        dataType={DataTypes.Numeric}
                    />
                    {!this.props.isNew && // Only render when we're viewing an existing client
                    <React.Fragment>
                        <FormField
                            label="Last Login"
                            value={this.props.data.statistics.lastLogin}
                            dataType={DataTypes.DateRelative}
                            controlType={ControlTypes.DisplayLabel}
                        />
                        <FormField
                            label="Login Count"
                            value={this.props.data.statistics.loginCount}
                            controlType={ControlTypes.DisplayLabel}
                            dataType={DataTypes.Numeric}
                        />
                    </React.Fragment>
                    }
                </div>
            </div>
        );
    }
}
