import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import Moment from 'react-moment';
import PageHeader from '../common/pageHeader';
import ClientDataAccessor from '../../dataAccess/client';
import AuthorisedArea from '../../helpers/AuthorisedArea';
import TrueFalseDisplay from '../common/inputs/components/TrueFalseDisplay';
import LoadingIcon from '../../images/loadingIcon.svg';
import FormField, {ControlTypes, DataTypes} from "../common/inputs/formField";
import {sortByField} from "../../helpers/sorting";

const tableHeaderStyle = {
  background: '#09adf4',
  color: 'white',
};

const buttonPanelStyle = {
  paddingBottom: '5px',
};

class Clients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      clients: [],
      sortOrder: 2
    };
    this.handleSelection = this.handleSelection.bind(this);
    this.isCancelled = false;
  }

  /**
   * Executed once the component mounts. Loads the data.
   */
  componentDidMount() {
    if (!this.isCancelled) this.loadClientList();
  }

  /**
   * Executes when the component is destroyed.
   */
  componentWillUnmount() {
    this.isCancelled = true;
  }

  /**
   * Loads the client list from the API
   */
  loadClientList() {
    ClientDataAccessor.GetAll(1,50)
      .then((response) => {
        this.setState({ clients: response, isLoading: false });
      })
      .catch((error) => {
        console.log('Got error on list screen', error);
      });
  }

  /**
   * Handles the selection of an item in the client list.
   * @param {*} clientId The ID of the client.
   */
  handleSelection(clientId) {
    this.props.history.push(`/client/${clientId}`);
  }
  
  onChange(fieldName, fieldValue) {
    this.setState({[fieldName]: fieldValue});
  }

  render() {
    
    const mainStyle = {
      background: "#f2f1ed"
    }
    
    return (

      <div className="w3-container">
        <PageHeader Heading="Clients"  />

        <AuthorisedArea requiredAdmin showError>
          <div className="w3-row m4" style={buttonPanelStyle}>
            <div className="w3-col s4">
              <FormField
                  isRequired
                  label="Sort"
                  dataType={DataTypes.Numeric}
                  options={[
                    {id: 1, description: "Customer Name"},
                    {id: 2, description: "Registration Number"}
                  ]}
                  value={this.state.sortOrder}
                  onChange={value => this.onChange('sortOrder', value)}
                  controlType={ControlTypes.DropdownSelection}
              />
            </div>
            <div className="w3-col s8 w3-right-align">
              <Link className="w3-btn w3-blue w3-round" href="/client/" to="/client/"><i className="fa fa-user" /> Create New Client</Link>
            </div>
          </div>

          <table className="w3-table w3-bordered w3-card-4 w3-hoverable w3-border">
            <thead >
              <tr style={tableHeaderStyle}>
                <td>Client Name</td>
                <td>Registration No.</td>
                <td>Assigned Templates</td>
                <td>Prints</td>
                <td>Login Count</td>
                <td>Last Visit</td>
                <td>Status</td>
              </tr>
            </thead>
            {!this.state.isLoading &&
              <tbody>
                {this.state.clients
                    .filter(x => x.clientTypeId === 2)
                    .sort((a,b) => sortByField(a, b,this.state.sortOrder === 1 ? 'name' : "registrationPrefix", 0) )
                    .map(client => (
                    <React.Fragment>
                      <tr style={mainStyle} className="tableRow" onClick={() => { this.handleSelection(client.id); }} key={client.id}>
                        <td><strong>{client.name}</strong></td>
                        <td>{client.registrationNumber}</td>
                        <td/>
                        <td/>
                        <td>{client.statistics.lastLogin &&
                          client.statistics.loginCount}
                        </td>
                        <td>{client.statistics.lastLogin &&
                          <Moment fromNow utc ago>{client.statistics.lastLogin}</Moment>}
                        </td>
                        <td><TrueFalseDisplay value={client.clientStatusTypeId === 1} /></td>
                      </tr>
                      {this.state.clients
                          .filter(x => x.parentClientId === client.id)
                          .sort((a,b) => sortByField(a, b,this.state.sortOrder === 1 ? 'name' : "registrationNumber", 0) )
                          .map(subClient => 
                            <tr className="tableRow" onClick={() => { this.handleSelection(subClient.id); }} key={subClient.id}>
                              
                              <td>&nbsp;&nbsp;&gt;&nbsp;&nbsp;&nbsp;&nbsp;{subClient.name}</td>
                              <td>{subClient.registrationNumber}</td>
                              <td>{subClient.statistics.assignedTemplateCount}</td>
                              <td>{subClient.statistics.lastLogin &&
                              subClient.statistics.platesPrinted}
                              </td>
                              <td>{subClient.statistics.lastLogin &&
                              subClient.statistics.loginCount}
                              </td>
                              <td>{subClient.statistics.lastLogin &&
                              <Moment fromNow utc ago>{subClient.statistics.lastLogin}</Moment>}
                              </td>
                              <td><TrueFalseDisplay value={subClient.clientStatusTypeId === 1} /></td>
                            </tr>
                        )}                      

                    </React.Fragment>
                  ))}
              </tbody>
            }
          </table>
          {this.state.isLoading &&
            <div className="w3-full w3-center">
              <LoadingIcon />
            </div>
          }
        </AuthorisedArea>
      </div>

    );
  }
}

export default withRouter(Clients);
