import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import Moment from 'react-moment';
import PageHeader from '../common/pageHeader';
import AuthorisedArea from '../../helpers/AuthorisedArea';
import LoadingIcon from '../../images/loadingIcon.svg';
import MessagingDataAccessor from "../../dataAccess/messaging";
import Message from './message';
import toast from 'react-hot-toast';

const tableHeaderStyle = {
  background: '#09adf4',
  color: 'white',
};
class Inbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      messages: [],
      selectedMessage: null,
    };
    this.handleSelection = this.handleSelection.bind(this);
    this.isCancelled = false;
  }

  /**
   * Executed once the component mounts. Loads the data.
   */
  componentDidMount() {
    if (!this.isCancelled) this.loadMessages();
  }

  /**
   * Executes when the component is destroyed.
   */
  componentWillUnmount() {
    this.isCancelled = true;
  }

  /**
   * Loads the client list from their API
   */
  loadMessages() {
    MessagingDataAccessor.GetAll(1, 20)
      .then((response) => {
        this.setState({ messages: response.payload, isLoading: false });
      })
      .catch((error) => {
        console.log('Got error on list screen', error);
      });
  }

  /**
   * Handles the selection of an item in the client list.
   * @param {*} messageId The ID of the message.
   */
  handleSelection(messageId) {
    // Get the message
    const message = this.state.messages.find(x=>x.id === messageId);
    
    // Update the state to display the message
    this.setState({
      selectedMessage: message
    })

    // Tell the API that the message gas been viewed.
    if(message.statusId === 1) { // Only needs to be done for unread messages.
      MessagingDataAccessor.MarkRead(messageId)
        .then((response)=> {
          if(response.isSuccess) {
            this.loadMessages(); 
          } 
        })
    }
  }

  /**
   * Marks a message as deleted
   */
  deleteMessage() {
    MessagingDataAccessor.DeleteById(this.state.selectedMessage.id)
      .then((response)=> {
        toast.success(response.responseText);
        if(response.isSuccess) {
          this.setState({
            selectedMessage: null
          });
          this.loadMessages();
        }
        else {
          toast.error(response.responseText);
        }
      })
  }

  
  

  render() {
    const menuItems = [];

    const toolbarStyle = {
      padding: "10px"
    }


    return (

      <div className="w3-container">
        <PageHeader Heading="Inbox" MenuItems={menuItems} />

        <AuthorisedArea showError>
          
          <table className="w3-table-all w3-card-4 w3-hoverable w3-border">
            <thead >
              <tr style={tableHeaderStyle}>
                <td />
                <td>Date</td>
                <td>From</td>
                <td>Subject</td>
                <td>Source</td>
                <td>State</td>
              </tr>
            </thead>
            {!this.state.isLoading &&
                
              <tbody>
                {this.state.messages.map(message => (
                  <tr className="tableRow" onClick={() => this.handleSelection(message.id) }>
                    <td><i className="fas fa-inbox" /></td>
                    <td><Moment local format="Do MMM[,] YYYY, HH:mm">{message.dateSent}</Moment></td>
                    <td>{`${message.fromName} <${message.fromEmail}>`}</td>
                    <td>{message.subject}</td>
                    <td>{message.source}</td>
                    <td>{message.status}</td>
                    
                  </tr>
                                ))}
              </tbody>
            }
          </table>
          {this.state.messages.length === 0 &&
          <div><br /><center><strong>You have no messages</strong></center><br/></div>
          }

          {this.state.isLoading &&
            <div className="w3-full w3-center">
              <LoadingIcon />
            </div>
          }
          {this.state.selectedMessage &&
          
            <React.Fragment>
              <div className="w3-row">
                <div className="w3-col w3-right-align" style={toolbarStyle}>
                  <button className="w3-button w3-red w3-round" type="button" onClick={() => this.deleteMessage()}><i className="fa fa-trash" />&nbsp;Delete</button>
                </div>
              </div>
            <Message message={this.state.selectedMessage} />
            </React.Fragment>
          
          }
        </AuthorisedArea>
      </div>

    );
  }
}

export default withRouter(Inbox);
