import React from 'react';
import LoginBox from '../login/loginbox';

const boxStyle = {
  margin: 'auto',
  width: '350px',
};

const containerStyle = {
  height: '400px',
};

export default class SignIn extends React.Component {
  componentWillMount() {
  }

  render() {
    return (
      <div className="w3-display-container" style={containerStyle}>
        <div className="w3-display-middle" style={boxStyle}>
            <LoginBox />
        </div>
      </div>
    );
  }
}
