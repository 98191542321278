import React from 'react';
import Moment from 'react-moment';
import FormField, { DataTypes, ControlTypes } from '../common/inputs/formField';
import LoadingIcon from '../../images/loadingIcon.svg';
import ClientDataAccesor from '../../dataAccess/client';

const tableHeaderStyle = {
  background: '#5BC4F2',
  color: 'white',
  width: '100%',
  borderRadius: '8px',
  fontWeight: 'normal',
};
export default class PlateHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      data: [],
      page: 1,
      pageSize: 25
    };
  }

  onChange(property, value) {
    this.setState({[property] : value}, () => this.handleRefresh())
  }

  handleRefresh() {
    ClientDataAccesor.GetGenerationsByClientId(this.props.clientId, this.state.page, this.state.pageSize)
    .then((incomingData) => {
      this.setState({ isLoading: false, data: incomingData.payload });
    })
    .catch((e) => {
      console.log(`Error while adding plates.. ${e}`);
      this.props.history.push('/');
    });
  }

  componentWillMount() {
    this.handleRefresh();
  }

  render() {

    const options = [
      {id: 25, description: "25"},
      {id: 50, description: "50"},
      {id: 100, description: "100"},
      {id: 200, description: "200"},
      {id: 10000, description: "All"},
      
    ]

    return (
      <section>
        <div className="w3-row">
          <div className="w3-third">
            <FormField
              label="Items to Display"
              value={this.state.pageSize}
              onChange={value => this.onChange('pageSize', value)}
              options={options}
              controlType={ControlTypes.DropdownSelection}
              dataType={DataTypes.Numeric}
            />
            </div>
        </div>
        <table className="w3-table  w3-bordered w3-hoverable">
          <thead style={tableHeaderStyle} className="tableheader">
            <tr>
              <th>Generated</th>
              <th>Template</th>
              <th>Customer Name</th>
              <th>Customer Id</th>
              <th>Id Type</th>
              <th>Input Data</th>
              <th className="w3-right-align">Preview</th>
            </tr>
          </thead>
          {this.state.isLoading === false &&
            <tbody>
              {this.state.data.map((preview =>
                (
                  <tr key={preview.id}>
                    <td><span>{preview.dateGenerated ? <Moment format="Do [of] MMMM[,] YYYY">{preview.dateGenerated}</Moment> : ''}</span><br/><small>{preview.clientUser}</small></td>
                    <td><span>{preview.template}</span></td>
                    <td><span>{preview.customerName}</span></td>
                    <td><span>{preview.customerId}</span></td>
                    <td><span>{preview.identificationType}</span></td>
                    <td><span><pre><code>{preview.friendlyInputText}</code></pre></span></td>
                    <td className="w3-right-align"><span><img src={preview.url} border="0" alt="What" /></span></td>
                  </tr>
                  )
              ))}

            </tbody>
          }
        </table>
        {this.state.isLoading &&
          <div className="w3-center">
            <LoadingIcon />
          </div>
        }
      </section>
    );
  }
}