import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TrueFalseDisplay from './TrueFalseDisplay';
import ControlLabel from './controlLabel';

export default class DisplayBoolean extends Component {
  render() {
    const textToShow = this.props.value ? this.props.trueText : this.props.falseText;

    return (
      <div className="w3-container w3-padding">
        <div className="w3-row">
          <div className="w3-col">
            <ControlLabel text={this.props.label} />
          </div>
        </div>
        <div className="w3-row w3-padding">
          <TrueFalseDisplay value={this.props.value} text={textToShow} />
        </div>
      </div>
    );
  }
}

DisplayBoolean.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  trueText: PropTypes.string,
  falseText: PropTypes.string,
};

DisplayBoolean.defaultProps = {
  trueText: '',
  falseText: '',
};
