import React from 'react';
import FormField, { ControlTypes, DataTypes } from '../common/inputs/formField';
import Translate from "../../internationalisation/translations";
import { UserContext } from '../../contexts/userContext';

export default class DataEntry extends React.Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            tabPath: []
        }

        this.validate = this.validate.bind(this);
        this.validateInput = this.validateInput.bind(this);
        this.updateState = this.updateStateWithNewComponentValue.bind(this);
        this.updateStateFromProps = this.updateStateFromProps.bind(this);
    }

    /**
     * If the props update from the parent, we need to update the state.
     * But first, check, did the props actually change? Or else, we get a loop.
     * @param {*} prevProps
     */
    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
            this.updateStateFromProps();
        }
    }

    onChange(fieldName, fieldValue) {
        const thisData = { ...this.state.data, [fieldName]: fieldValue };
        this.setState({ data: thisData });
    }


    updateStateFromProps() {
        // Update the state, based on th new props we got.
        this.setState({ data: this.props.data, tabPath: [] }, () => {
            // Now we're going to clear the component array, which allows us to tab to each item.
            // If we have data...
            if (this.state.data &&
                this.state.data.templateGroups) {
                // Iterate through the groups...
                let path = []
                this.state.data.templateGroups
                    .filter(item => item.shouldDisplayToClient)
                    .forEach((group, groupIndex) => {
                        // And then through each component.
                        group.components
                            .filter(comp => comp.shouldDisplayToClient)
                            .sort((a, b) => a.entryDisplayOrder - b.entryDisplayOrder)
                            .forEach((component) => {
                                path.push(component.id);
                            });
                        this.setState({
                            tabPath: path
                        })

                    });
            }
        });
    }

    validate() {
        this.props.afterValidate(this.state.data);
    }

    /**
     * This method will validate that we can enter more data inot the control,
     * and also, when we hit the max, we move to the next item in the array (tabPath)
     * of inputs.
     * @param {*} maxLength
     * @param {*} e
     * @param {*} groupId
     * @param {*} componentId
     */
    validateInput(maxLength, e, groupId, componentId) {
        // Get the value that has been entered.
        const value = e.target.value;
        // If the length of the entry exceeds the maximum set, we cancel it.
        if (e.target.value.length === maxLength) {
            let nextIndex = 0;
            // Get the index of the currently selected control, from the list of control names.
            const thisIndex = this.state.tabPath.indexOf(e.target.id);
            // If the index is less than the length, we will be able to go to another control.
            if (thisIndex < this.state.tabPath.length - 1) {
                // Increment the index.,,
                nextIndex = thisIndex + 1;
                // Get the name of the next control to move to...
                const nextId = this.state.tabPath[nextIndex];
                // And set the focus to that.
                document.getElementById(nextId).focus();
            }
        }
        // Now lets work out if we were actually allowed to update the value...
        // Did we exceed the max length?
        if (e.target.value.length <= maxLength) {
            // Data seems OK, lets update the status.
            this.updateStateWithNewComponentValue(groupId, componentId, value);
        }
    }

    updateStateWithNewComponentValue(groupId, componentId, value) {
        const stateData = this.state.data;
        // Find the component's index, using map, and seeing if we can find the id.
        const group = stateData.templateGroups.find(group => group.id === groupId)
        var groupIndex = stateData.templateGroups.indexOf(group);

        const component = group.components.find(component => component.id === componentId)
        var componentIndex = stateData.templateGroups[groupIndex].components.indexOf(component);

        // Update the value of the selected component.
        component.previewText = value;

        // Replace the component in our local version.
        stateData.templateGroups[groupIndex].components[componentIndex] = component;
        // And save it back to State.
        this.setState({ data: stateData });
    }

    updateAdvert(value) {
        const stateData = this.state.data;
        stateData.selectedCustomImageId = value
        this.setState({ data: stateData });
    }

    render() {
        const { languageCode } = this.context;
        const inputControlDivStyle = {
            width: '80px',
        };
        if (this.props.currentStep !== 1) {
            return null;
        }


        return (
            <React.Fragment>
                {this.state.data && this.state.data.allowAdvert &&
                    <div className="w3-row">
                        <div className="w3-col m6">
                            <FormField
                                isRequired
                                label="Advertisement"
                                value={this.state.data.selectedCustomImageId}
                                onChange={(value) => this.updateAdvert(value)}
                                controlType={ControlTypes.DropdownSelection}
                                options={this.state.data.referenceData.customImageLibraries}
                                dataType={DataTypes.Numeric}
                            />
                        </div>
                    </div>
                }
                <div>
                    {this.state.data && // Get all the Groups, if they should be displayed.
                        this.state.data.templateGroups
                            .filter(item => item.shouldDisplayToClient)
                            .map((group, groupIndex) => {
                                return (
                                    <div key={group.id} className="w3-col m6 l6 w3-padding">
                                        <div className="w3-container animated fadeIn">
                                            <div className="w3-container w3-border w3-round w3-padding w3-card-4">
                                                <h4>{group.description}</h4>
                                                <div className="w3-row w3-padding">
                                                    {group.components
                                                        .filter(comp => comp.shouldDisplayToClient)
                                                        .sort((a, b) => a.entryDisplayOrder - b.entryDisplayOrder)
                                                        .map((component) => {

                                                            let inputControlStyle = {
                                                                margin: "5px 0",
                                                                fontSize: "18px",
                                                                padding: "8px 8px",
                                                                boxSizing: "border-box",
                                                                borderRadius: "5px",
                                                                width: component.maxChars * 40
                                                            }

                                                            return (
                                                                <div key={component.id} className="w3-col w3-margin-right" style={inputControlDivStyle}>
                                                                    <input
                                                                        type="text"
                                                                        id={component.id}
                                                                        style={inputControlStyle}
                                                                        value={component.previewText}
                                                                        onChange={e => this.validateInput(component.maxChars, e, group.id, component.id)} />
                                                                </div>
                                                            );
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    <div className="w3-container w3-right-align">
                        <button className="w3-btn w3-blue w3-round" onClick={() => this.props.moveBack()}>{Translate.getTranslation(languageCode, "TEXT_Previous")} <i className="fa fa-arrow-circle-left" /></button>&nbsp;
                        <button className="w3-btn w3-green w3-round" onClick={() => this.validate()}>{Translate.getTranslation(languageCode, "TEXT_Next")} <i className="fa fa-arrow-circle-right" /></button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
