import React, { Component } from 'react';
import PageHeader from '../common/pageHeader';
import FormField, { ControlTypes, DataTypes } from '../common/inputs/formField';
import ClientDataAccessor from '../../dataAccess/client';
import LoadingIcon from '../common/loadingIcon'
import Auth from '../../authentication';
import { UserContext } from '../../contexts/userContext';
import Translate from "../../internationalisation/translations";


const plateStyle = {
  margin: '5px',
};

export default class Dashboard extends Component {
  static contextType = UserContext;
  
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      isDataLoading: true,
      isHistoryLoading: true,
      isCancelled: false,
      canViewPlateHistory: Auth.canViewPlateHistory()
    };

    this.loadDetail = this.loadDetail.bind(this);
    this.loadPlateHisory = this.loadPlateHisory.bind(this);
  }

  componentDidMount() {

    if (!this.state.isCancelled) {
      this.loadDetail();
      if(Auth.canViewPlateHistory()) {
        this.loadPlateHisory();
      }
    }
  }
  
  componentWillUnmount() {
    this.setState({
      isCancelled: true
    })
  }

  loadDetail() {
    ClientDataAccessor.GetDashboard()
      .then((data) => {
        this.setState({
          data : data.payload,
          isDataLoading: false,
          isCancelled: false
        });
      });
  }

  loadPlateHisory() {
    ClientDataAccessor.GetGenerationsByClientId(Auth.clientId(), 1, 12)
      .then((data) => {
        this.setState({
          plateHistory: data.payload,
          isHistoryLoading: false,
        });
      });
  }


  render() {
    this.menuItems = [];
    if(this.state.isDataLoading)
      return (
        <LoadingIcon />
      );

    const data = this.state.data;
    const {languageCode} = this.context;
    
    return (
      
      <div className="w3-container">
        <PageHeader Heading={Translate.getTranslation(languageCode, "TEXT_Dashboard")} MenuItems={this.menuItems} />
        <div className="w3-col l3 m4">
          <div className="tile animated fadeIn">
            <div className="tile-title"><span className="fa fa-user" />&nbsp;&nbsp;&nbsp; {Translate.getTranslation(languageCode, "TEXT_MyDetails")}</div>
            <div className="tile-body">
              <FormField label={Translate.getTranslation(languageCode, "TEXT_Name")} value={data.name} controlType={ControlTypes.DisplayLabel} dataType={DataTypes.String} />
              <FormField label={Translate.getTranslation(languageCode, "TEXT_ContactNumber")} value={data.telephone} controlType={ControlTypes.DisplayLabel} dataType={DataTypes.String} />
              <FormField label={Translate.getTranslation(languageCode, "TEXT_Distributor")} value={data.parentClient} controlType={ControlTypes.DisplayLabel} dataType={DataTypes.String} />
              <FormField label={Translate.getTranslation(languageCode, "TEXT_RegistrationNumber")} value={data.registrationNumber} controlType={ControlTypes.DisplayLabel} dataType={DataTypes.String} />
              <FormField label={Translate.getTranslation(languageCode, "TEXT_Country")} value={data.countryType} controlType={ControlTypes.DisplayLabel} dataType={DataTypes.String} />
              <FormField label={Translate.getTranslation(languageCode, "TEXT_VatNumber")} value={data.vatNumber} controlType={ControlTypes.DisplayLabel} dataType={DataTypes.String} />
              <FormField
                label={Translate.getTranslation(languageCode, "TEXT_VatStatus")}
                value={Boolean(data.vatNumberValidated)}
                trueText="VAT number validated"
                falseText="VAT number not yet validated"
                controlType={ControlTypes.DisplayBoolean}
                dataType={DataTypes.Boolean}
              />
            </div>
          </div>
        </div>
        <div className="w3-col l2 m3 w3-row-padding">
          <div className="tile animated fadeIn">
            <div className="tile-title"><span className="fa fa-bars" />&nbsp;&nbsp;&nbsp;{Translate.getTranslation(languageCode, "TEXT_UsageDetails")}</div>
            <div className="tile-body">
              <FormField label={Translate.getTranslation(languageCode, "TEXT_NumberOfVisits")} value={data.statistics.loginCount} controlType={ControlTypes.DisplayLabel} dataType={DataTypes.String} />
              {Auth.canViewPlateHistory() &&
                  <React.Fragment>
                    <FormField label={Translate.getTranslation(languageCode, "TEXT_PlatesCreated")}
                             value={data.statistics.platesPrinted} controlType={ControlTypes.DisplayLabel}
                             dataType={DataTypes.String}/>
                    <FormField label={Translate.getTranslation(languageCode, "TEXT_LastDateCreated")} value={data.statistics.lastPrintDate} controlType={ControlTypes.DisplayLabel} dataType={DataTypes.Date} />
                  </React.Fragment>
                    }
                <FormField label={Translate.getTranslation(languageCode, "TEXT_AllocatedTemplates")} value={data.statistics.assignedTemplateCount} controlType={ControlTypes.DisplayLabel} dataType={DataTypes.String} />
            </div>
          </div>
        </div>

        {Auth.canViewPlateHistory() &&
          <div className="w3-col l7 m5">
            <div className="tile animated fadeIn">
              <div className="tile-title">
                <span
                  className="fa fa-search"/>&nbsp;&nbsp;&nbsp; {Translate.getTranslation(languageCode, "TEXT_PlatesCreated")}
              </div>
              <div className="tile-body">
                <div className="w3-row-padding">
                  <p>{Translate.getTranslation(languageCode, "TEXT_RecentlyPrintedPlates")}</p>
                  {!this.state.isHistoryLoading && this.state.plateHistory.map(item => (
                    <div className="w3-col l4 m12 s12" key={item.id}>
                      <img src={item.url} className="w3-image" style={plateStyle} border="0" alt="What"/>
                    </div>
                  ))
                  }
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}
