import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ControlLabel from './controlLabel';

const groupStyle = {
  display: 'inline-block',
};

const customUploadControl = { // Used to hide the default upload control.
  opacity: '0',
  position: 'absolute',
  zIndex: '-1',
};

export default class FileUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isValid: this.props.isValid
    }

    this.onChange = this.onChange.bind(this)
  }

  componentWillReceiveProps(newProps) {
    if(newProps.isValid != this.state.isValid) {
      this.setState({
        isValid: newProps.isValid
      })
    }
  }

  onChange(e) {
    this.props.onChange({file:e.target.files[0]})
  }


  render() {
    const isReadonlyType = { readOnly: this.props.isReadonly && this.props.isReadonly === true };
    const editStyle = { background: '#ffffff' };
    if (this.state.isValid === false) {
      editStyle.background = '#ffe0e0';
    }
    if (isReadonlyType.readOnly) {
      editStyle.background = '#f2f2f2';
    }

    return (
      <div className="w3-container w3-padding">
        <div className="w3-row">
          <div className="w3-col">
            <ControlLabel text={this.props.label} isRequired={this.props.isRequired} />
          </div>
        </div>
        <div className="w3-row">
          <div className="w3-col" style={groupStyle}>
            <label htmlFor="file" className="w3-btn w3-green w3-small w3-round">Upload</label>
            <input
              type="file"
              id="file"
              encType="multipart/form-data"
              placeholder={this.props.label}
              style={customUploadControl}
              value={this.props.value}
              onChange={this.onChange}
              readOnly={isReadonlyType.readOnly}
            />
          </div>
        </div>
      </div>
    );
  }
}

FileUpload.propTypes = {
  isRequired: PropTypes.bool,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isReadonly: PropTypes.bool,
};

FileUpload.defaultProps = {
  isRequired: false,
  isReadonly: false,
  value: '',
};
