import React from 'react';
import Moment from 'react-moment';
import PropTypes from 'prop-types';
import fetchData from '../../helpers/fetchData';
import Server from '../../constants/environment';
import TrueFalseDisplay from '../common/inputs/components/TrueFalseDisplay';
import LoadingIcon from '../../images/loadingIcon.svg';
import ClientDataAccessor from "../../dataAccess/client";

const tableHeaderStyle = {
  background: '#5BC4F2',
  color: 'white',
  width: '100%',
  borderRadius: '8px',
  fontWeight: 'normal',
};
export default class AuditLog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      data: [],
    };
  }

  componentWillMount() {

    ClientDataAccessor.GetAuditHistory(this.props.clientId)
      .then((result) => {
        this.setState({ isLoading: false, data: result.lines });
      })
      .catch((e) => {
        console.log(`Error in Client load. ${e}`);
        this.props.history.push('/');
      });
  }

  render() {
    return (
      <section>

        <table className="w3-table  w3-bordered w3-hoverable">
          <thead style={tableHeaderStyle} className="tableheader">
            <tr>
              <th>Date</th>
              <th>Type</th>
              <th>Level</th>
              <th>Success</th>
              <th>Description</th>
              <th>IP Address</th>
            </tr>
          </thead>
          {this.state.isLoading === false &&
            <tbody>
              {this.state.data.map((audit =>
                            (
                              <tr key={audit.id}>
                                <td><span>{audit.eventDateTime ? <Moment local format="Do [of] MMM, HH:mm">{audit.eventDateTime}</Moment> : ''}</span></td>
                                <td><span>{audit.auditLogEventType}</span></td>
                                <td><span>{audit.auditLogLevelType}</span></td>
                                <td><span><TrueFalseDisplay value={audit.isSuccess} /></span></td>
                                <td><span>{audit.description}</span></td>
                                <td><span>{audit.remoteIpAddress}</span></td>
                              </tr>
                              )
                          ))}
            </tbody>
          }
        </table>
        {this.state.isLoading &&
          <div className="w3-center">
            <LoadingIcon />
          </div>
        }
      </section>
    );
  }
}