/* THis method takes a set of lookup data, and returns a single item based on it's ID */
const getValueFromData = (options, id) => {
  if (!id) {
    return null;
  }
  if (!options || options.length === 0) {
    console.log(`Empty lookup list passed to getValueFromData, and id is ${id}`);
    return null;
  }

  const option = options.filter(item => (!isNaN(item.id) ? Number(item.id) : item.id) === id); // Have to handle Guids and Numbers.
  if (option.length === 1) {
    return option[0].description;
  }
  console.log(`Unable to find an id of ${id} in ${[...options]}`);
  return null;
};

export default getValueFromData;
