import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Auth from '../../authentication';
import AuthorisedArea from '../../helpers/AuthorisedArea';
import { UserContext } from '../../contexts/userContext';
import PtFlag from '../../images/pt.svg';
import GbFlag from '../../images/gb.svg';
import EsFlag from '../../images/es.svg';
import Translate from "../../internationalisation/translations";



const navBarStyle = {
    background: 'linear-gradient(#09adf4, #5BC4F2)',
    color: 'white',
    padding: '10px',
};

const menuItemsStyle = {
    maxWidth: '1350px',
    margin: 'auto',
};

export default class NavbarContainer extends Component {
    
    constructor(props) {
        super(props);
        this.signOut = this.signOut.bind(this);
    }

    static contextType = UserContext;

    signOut() {
        Auth.logout();
        const {changeAuthenticatedState} = this.context;
        changeAuthenticatedState(false);
    }

    SelectPortuguese(langCode) {
        const {changeLanguage} = this.context;
        changeLanguage(langCode)
    }

    render() {
        const {isAuthenticated, languageCode} = this.context;
        const isDistributor = Auth.clientTypeId() === "2";
        const canViewPlateHistory = Auth.canViewPlateHistory()  || Auth.isAdmin();

        return (


            <div className="w3-bar" style={navBarStyle}>
                <div style={menuItemsStyle}>
                    <PtFlag style={{cursor: "pointer", margin: "7px"}} width={30} alt="English" onClick={() => this.SelectPortuguese("por")} />
                    <GbFlag style={{cursor: "pointer", margin: "7px"}} width={30} alt="Portuguese"  onClick={() => this.SelectPortuguese("eng")} />
                    <Link href="/" to="/" className="w3-bar-item w3-button w3-hover-teal"><i
                        className="fa fa-home"/> {Translate.getTranslation(languageCode, "TEXT_Home")}</Link>
                    <AuthorisedArea>
                        <Link href="/dashboard" to="/dashboard" className="w3-bar-item w3-button w3-hover-teal"><i
                            className="fas fa-clipboard-list"/> {Translate.getTranslation(languageCode, "TEXT_Dashboard")}</Link>
                        {!isDistributor &&
                        <React.Fragment>
                            <Link href="/platecreator" to="/platecreator"
                                  className="w3-bar-item w3-button w3-hover-teal"><i
                                className="fas fa-clipboard-list"/> {Translate.getTranslation(languageCode, "TEXT_PlateCreator")}</Link>
                          {canViewPlateHistory &&
                          <Link href="/myhistory" to="/myhistory" className="w3-bar-item w3-button w3-hover-teal"><i
                            className="fas fa-history"/> {Translate.getTranslation(languageCode, "TEXT_History")}</Link>
                          }
                        </React.Fragment>
                        }
                        {isDistributor &&
                        <Link href="/myclients" to="/myclients" className="w3-bar-item w3-button w3-hover-teal"><i
                            className="fas fa-users-cog"/> {Translate.getTranslation(languageCode, "TEXT_MyClients")}</Link>
                        }
                    </AuthorisedArea>
                    {isAuthenticated === true &&
                    <Link className="w3-button w3-amber w3-right w3-hover-teal w3-round" to="/" onClick={this.signOut}><i
                        className="fas fa-sign-out-alt"/> {Translate.getTranslation(languageCode, "TEXT_SignOut")}</Link>
                    }
                    {!isAuthenticated &&
                    <Link className="w3-button w3-right w3-green w3-hover-teal w3-round" to="/login"><i
                        className="fas fa-sign-in-alt"/> {Translate.getTranslation(languageCode, "TEXT_SignIn")}</Link>
                    }
                    <Link href="/contact" to="/contact" className="w3-bar-item w3-right w3-button w3-hover-teal"><i
                        className="fa fa-envelope"/> {Translate.getTranslation(languageCode, "TEXT_ContactUs")}</Link>
                    <AuthorisedArea requiredAdmin>
                        <React.Fragment>
                            <div className="w3-dropdown-hover w3-right">
                                <button className="w3-button w3-hover-teal"><i className="fas fa-question-circle" /> Links</button>
                                <div className="w3-dropdown-content w3-bar-block w3-card-4">
                                    <a href="chrome://settings/content/pdfDocuments"  className="w3-bar-item w3-button w3-hover-teal"><i
                                        className="fas fa-users-cog"/> Chrome PDF Settings</a>
                                </div>
                            </div>


                            <div className="w3-dropdown-hover w3-right">
                                <button className="w3-button w3-hover-teal"><i className="fas fa-lock"/> Analytics</button>
                                <div className="w3-dropdown-content w3-bar-block w3-card-4">
                                    <Link href="/analytics/generations" to="/analytics/generations" className="w3-bar-item w3-button w3-hover-teal"><i
                                        className="fas fa-users-cog"/> Plate Generations</Link>
                                </div>
                            </div>
                            
                            

                            <div className="w3-dropdown-hover w3-right">
                                <button className="w3-button w3-hover-teal"><i className="fas fa-lock"/> Admin</button>
                                <div className="w3-dropdown-content w3-bar-block w3-card-4">
                                    <Link href="/clients" to="/clients" className="w3-bar-item w3-button w3-hover-teal"><i
                                        className="fas fa-users-cog"/> Clients</Link>
                                    <Link href="/templates" to="/templates"
                                          className="w3-bar-item w3-button w3-hover-teal"><i
                                        className="far fa-object-group"/> Templates</Link>
                                    <Link href="/images/backgrounds" to="/images/backgrounds"
                                          className="w3-bar-item w3-button w3-hover-teal"><i
                                        className="far fa-image"/> Backgrounds</Link>
                                    <Link href="/images/customimages" to="/images/customimages"
                                          className="w3-bar-item w3-button w3-hover-teal"><i
                                        className="far fa-images"/> Custom Images</Link>
                                    <Link href="/history" to="/history"
                                          className="w3-bar-item w3-button w3-hover-teal"><i
                                        className="far fa-images"/> Plate Generations</Link>
                                    <Link href="/inbox" to="/inbox"
                                          className="w3-bar-item w3-button w3-hover-teal"><i
                                        className="fas fa-inbox"/> Inbox</Link>
                                </div>
                            </div>
                        </React.Fragment>

                    </AuthorisedArea>

                </div>
            </div>
        );
    }
}

