import React from 'react';
import toast from 'react-hot-toast';
import Steps, {Step} from 'rc-steps';
import '/node_modules/rc-steps/assets/index.css';
import AuthorisedArea from '../../helpers/AuthorisedArea';
import PageHeader from '../common/pageHeader';
import SelectTemplate from './selectTemplate';
import PreviewPrint from './previewPrint';
import DataEntry from './dataEntry';
import TemplateDataAccessor from '../../dataAccess/template';
import Translate from "../../internationalisation/translations";
import { UserContext } from '../../contexts/userContext';

const stepContainerStyle = {
    background: 'white',
};

export default class PlateCreator extends React.Component {
  static contextType = UserContext;
    constructor(props) {
        super(props);

        this.state = {
            currentStep: 0,
            uri: null,
            isDownloading: false,
            identificationTypes: [],
            templates: [],
            identificationTypeId: null,
            customerName: null,
            customerIdNumber: null,
            verifiedClientUserId: null,
            requiresUserClientVerification: true,
            pdfBase64: null,
            data: {
                previewImage: null,
                isTemplatesLoaded: false,
                isPreviewLoading: false,
                selectedTemplate: null,
                templateId: null,
            },
        };
      
        
        

        this.nextStep = this.nextStep.bind(this);
        this.prevStep = this.prevStep.bind(this);
        this.getTemplates = this.getTemplates.bind(this);
        this.handleChildChange = this.handleChildChange.bind(this);
        this.handlePlateSelectionChange = this.handlePlateSelectionChange.bind(this);
        this.setValidatedClientUser = this.setValidatedClientUser.bind(this);
        this.close = this.close.bind(this);
    }

    componentDidMount() {
        this.getTemplates();
    }

    handleChildChange(fieldName, value) {
        this.setState({[fieldName]: value});
    }

    handlePlateSelectionChange(data) {
        this.setState({data});
    }
    
    setValidatedClientUser(id) {
        this.setState({
            verifiedClientUserId: id
        })
    }

    getTemplates() {
        TemplateDataAccessor.GetForCurrentClient()
            .then((result) => {
                const currentState = {...this.state.data};
                currentState.isTemplatesLoaded = true;
                this.setState({
                    data: currentState,
                    identificationTypes: result.identificationTypes,
                    templates: result.payload,
                    customerName: result.customerName,
                    customerIdNumber: result.customerIdNumber,
                    identificationTypeId: result.identificationTypeId
                });
            });
    }

    nextStep() {
        
        // Move to the next step.
        let currentStep = this.state.currentStep;
        
        // 1=Select a template and PIN entry
        // 2=Enter plate fields
        // 3=Preview and Print
        
        // Have we entered the plate data?
        if (currentStep === 1) {

            let dataIsGood = true;

            this.state.data.templateGroups.forEach(group => {
                if (group.shouldDisplayToClient === true) {
                    group.components.forEach(component => {
                        if (component.isReadOnly === false && component.componentTypeId !== 3) { // If it's not readonly and isn't a shape
                            if (component.previewText == null || component.previewText.trim() === '') { // Is it null or empty?
                                dataIsGood = false;
                            }
                        }
                    })
                }
            })

            if (dataIsGood === false) {
                toast.error("All plate fields must had data entered");
                return;
            }
        }


        
        // Validate the person has supplied customer details.
        if (currentStep === 2 && (!this.state.customerIdNumber || !this.state.customerName || !this.state.identificationTypeId)) {
            toast.error("Please enter the Customer Details before creating the plate");
            return;
        }


        // Checks have passed. Let's move to the next tab and initialise, based on the step.

        if(currentStep === 0) {
            currentStep = currentStep  + 1;
            this.setState({currentStep});
            return;
        }
        
        // We moved to the preview screen. So create the preview.
        if (currentStep === 1) {
            currentStep = currentStep  + 1;
            this.setState({currentStep});
            this.generatePreview();
            return;
        }

        // If we're to Step 2, we need to generate the preview. 
        if (currentStep === 2) {
            this.generateFile();
        }

    }

    generateFile() {

        // Check if we have generated already. If so, go straight to print.
        if(this.state.pdfBase64) {
            this.printPlate();
            return;
        }
        
        // First, let's set the status to downloading...
        this.setState({isGenerating: true}, () => {
            const data = {
                plateData: this.state.data,
                customerName: this.state.customerName,
                idNumber: this.state.customerIdNumber,
                identificationTypeId: this.state.identificationTypeId,
                verifiedClientUserId: this.state.verifiedClientUserId
            }

            TemplateDataAccessor.Generate(data)
                .then((result) => {
                    this.printPlate(result.payload.generationId);
                })
                .catch((error) => {
                    toast.error('An error has occured while attempting to generate the plate.');
                    console.log('Caught an error after trying to generate!', error);
                    this.setState({
                        isGenerating: false,
                    });
                    
                });
        });
    }
    
    printPlate(generationId) {

        // If we get an Id, then we need to get it from the server and print it.
        if(generationId) {
            TemplateDataAccessor.GetBase64ByUrl(generationId)
                .then((result) => {
                    if (result.isSuccess) {
                        this.setState({pdfBase64: result.payload})
                        window.printJS({printable: result.payload, type: 'pdf', base64: true});
                        this.setState({isGenerating: false});
                    } else {
                        toast.error("There was an error printing your plate. Please try again or contact SmartPlates");
                    }
                })
        }
        // Else we just print what we have in state.
        else {
            window.printJS({printable: this.state.pdfBase64, type: 'pdf', base64: true});
        }
    }
    
    
    
    

    generatePreview() {

        const preLoad = {...this.state.data};
        preLoad.isPreviewLoading = true;
        preLoad.previewImage = null;
        this.setState({data: preLoad});

        TemplateDataAccessor.Preview(this.state.data)
            .then((result) => {
                this.setState({isPreviewing: false});
                if (result.isSuccess) {
                    const stateData = {...this.state.data};
                    stateData.previewImage = result.base64Data;
                    stateData.isPreviewLoading = false;
                    this.setState({data: stateData});
                } else {
                    toast.error(`Error processing preview. ${result}`);
                }
            })
            .catch((e) => {
                console.error(`Error while previewing image. ${e}`);
                toast.error(`Error while getting preview. ${e}`);
                this.setState({isPreviewing: false});
            });

        this.setState({isLoading: false});
    }

    close() {
        this.props.history.push('/dashboard');
    }

    prevStep() {
        
        
        let currentStep = this.state.currentStep;
        currentStep = currentStep <= 0 ? 0 : currentStep - 1;
        this.setState({currentStep});
    }

    render() {
      const {languageCode} = this.context;
        const MenuItems = [];

        return (
            <div className="w3-container">
                <PageHeader Heading={Translate.getTranslation(languageCode, "TEXT_PlateCreator")} MenuItems={MenuItems}/>
                <AuthorisedArea showError>
                    <div className="w3-panel w3-card-4 w3-padding w3-round w" style={stepContainerStyle}>
                        <Steps current={this.state.currentStep} initial={0}>
                            <Step title={Translate.getTranslation(languageCode, "TEXT_TemplateSelection")} description={Translate.getTranslation(languageCode, "TEXT_SelectATemplate")}/>
                            <Step title={Translate.getTranslation(languageCode, "TEXT_PlateData")} description={Translate.getTranslation(languageCode, "TEXT_EnterPlateDetails")}/>
                            <Step title={Translate.getTranslation(languageCode, "TEXT_PreviewAndPrint")} description={Translate.getTranslation(languageCode, "TEXT_PreviewAndPrintPlate")}/>
                        </Steps>
                    </div>

                    
                    <div className="step-progress">
                        <SelectTemplate
                            currentStep={this.state.currentStep}
                            templates={this.state.templates}
                            data={this.state.data}
                            onChange={this.handlePlateSelectionChange}
                            setValidatedClientUser = {this.setValidatedClientUser}
                            afterValidate={this.nextStep}
                            moveBack={this.prevStep}
                        />
                        <DataEntry
                            currentStep={this.state.currentStep}
                            data={this.state.data}
                            afterValidate={this.nextStep}
                            moveBack={this.prevStep}
                        />
                        <PreviewPrint
                            currentStep={this.state.currentStep}
                            identificationTypes={this.state.identificationTypes}
                            customer={{
                                customerName: this.state.customerName,
                                customerIdNumber: this.state.customerIdNumber,
                                identificationTypeId: this.state.identificationTypeId
                            }}
                            data={this.state.data}
                            onSubmit={this.nextStep}
                            moveBack={this.prevStep}
                            onChange={this.handleChildChange}
                            isGenerating={this.state.isGenerating}
                            close={this.close}
                        />
                    </div>
                </AuthorisedArea>
            </div>
        );
    }
}
