import React, {Component} from 'react';
import {withRouter, Link} from 'react-router-dom';
import Moment from 'react-moment';
import PageHeader from '../common/pageHeader';
import ClientDataAccessor from '../../dataAccess/client';
import AuthorisedArea from '../../helpers/AuthorisedArea';
import TrueFalseDisplay from '../common/inputs/components/TrueFalseDisplay';
import LoadingIcon from '../../images/loadingIcon.svg';
import FormField, {ControlTypes, DataTypes} from "../common/inputs/formField";

const tableHeaderStyle = {
    background: '#09adf4',
    color: 'white',
};

const containerStyle = {
    padding: '2px',
};

const buttonPanelStyle = {
    paddingBottom: '5px',
};

class MyClient extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            client: null,
            printedPlates: [],
            id: this.props.match.params.clientId
        };
        this.isCancelled = false;
    }

    /**
     * Executed once the component mounts. Loads the data.
     */
    componentDidMount() {
        if (!this.isCancelled) this.loadClient();
    }

    /**
     * Executes when the component is destroyed.
     */
    componentWillUnmount() {
        this.isCancelled = true;
    }

    /**
     * Loads the client list from the API
     */
    loadClient() {
        ClientDataAccessor.GetById(this.state.id)
            .then((response) => {
                this.setState({client: response.payload, isLoading: false});
            })
            .catch((error) => {
                console.log('Got error on list screen', error);
            });


        ClientDataAccessor.GetPlateGenerationsByClient(this.state.id,1, 10, )
            .then((plates) => {
                this.setState({printedPlates: plates.payload})
            })
    }

    render() {
        const menuItems = [{Display: 'My Clients', Url: '/myclients'}];

        if (this.state.isLoading)
            return (
                <LoadingIcon displayText={"Loading..."}/>
            )


        return (

            <div className="w3-container">
                <PageHeader Heading={`Client: ${this.state.client.name}`} MenuItems={menuItems}/>

                <AuthorisedArea showError>

                    <div className="w3-row" style={containerStyle}>
                        <div className="w3-third">
                            <h2>Client Details</h2>
                            <FormField
                                isRequired
                                label="Client Name"
                                value={this.state.client.name}
                                onChange={value => this.handleChange('name', value)}
                                maxLength={30}
                                controlType={ControlTypes.DisplayLabel}
                                dataType={DataTypes.String}
                            />
                            <FormField
                                isRequired
                                label="Email"
                                value={this.state.client.email}
                                onChange={value => this.handleChange('name', value)}
                                maxLength={100}
                                controlType={ControlTypes.DisplayLabel}
                                dataType={DataTypes.String}
                            />
                            <FormField
                                isRequired
                                label="Username"
                                value={this.state.client.username}
                                onChange={value => this.handleChange('name', value)}
                                maxLength={100}
                                controlType={ControlTypes.DisplayLabel}
                                dataType={DataTypes.String}
                            />
                            <FormField
                                isRequired
                                label="Password"
                                value={this.state.client.password}
                                onChange={value => this.handleChange('name', value)}
                                maxLength={100}
                                controlType={ControlTypes.Password}
                                dataType={DataTypes.String}
                            />
                            <FormField
                                isRequired
                                label="Contact Name"
                                value={this.state.client.contactName}
                                onChange={value => this.handleChange('name', value)}
                                maxLength={50}
                                controlType={ControlTypes.DisplayLabel}
                                dataType={DataTypes.String}
                            />
                            <FormField
                                isRequired
                                label="Telephone"
                                value={this.state.client.telephone}
                                onChange={value => this.handleChange('name', value)}
                                maxLength={100}
                                controlType={ControlTypes.DisplayLabel}
                                dataType={DataTypes.String}
                            />
                            <FormField
                                isRequired
                                label="Registration Number"
                                value={this.state.client.registrationNumber}
                                onChange={value => this.handleChange('name', value)}
                                maxLength={100}
                                controlType={ControlTypes.DisplayLabel}
                                dataType={DataTypes.String}
                            />
                            <FormField
                                isRequired
                                label="Vat Number"
                                value={this.state.client.vatNumber}
                                onChange={value => this.handleChange('name', value)}
                                maxLength={100}
                                controlType={ControlTypes.DisplayLabel}
                                dataType={DataTypes.String}
                            />
                            <FormField
                                isRequired
                                label="Vat Number Validated?"
                                value={this.state.client.vatNumberValidated}
                                onChange={value => this.handleChange('name', value)}
                                maxLength={100}
                                controlType={ControlTypes.DisplayBoolean}
                                dataType={DataTypes.String}
                            />
                            <FormField
                                isRequired
                                label="Country"
                                value={this.state.client.countryType}
                                onChange={value => this.handleChange('clientStatusTypeId', value)}
                                options={this.state.client.referenceData.countryTypes}
                                controlType={ControlTypes.DisplayLabel}
                                dataType={DataTypes.Numeric}
                            />

                        </div>
                        <div className="w3-third">
                            <h2>Activity</h2>
                            <FormField
                                isRequired
                                label="Client Status"
                                value={this.state.client.clientStatusType}
                                onChange={value => this.handleChange('clientStatusTypeId', value)}
                                options={this.state.client.referenceData.clientStatusTypes}
                                controlType={ControlTypes.DisplayLabel}
                                dataType={DataTypes.Numeric}
                            />
                            <FormField
                                isRequired
                                label="Login Count"
                                value={this.state.client.loginCount}
                                onChange={value => this.handleChange('clientStatusTypeId', value)}
                                options={this.state.client.referenceData.clientStatusTypes}
                                controlType={ControlTypes.DisplayLabel}
                                dataType={DataTypes.Numeric}
                            />
                            <FormField
                                isRequired
                                label="Last Login"
                                value={<Moment fromNow utc ago>{this.state.client.lastLoginDate}</Moment>}
                                onChange={value => this.handleChange('clientStatusTypeId', value)}
                                options={this.state.client.referenceData.clientStatusTypes}
                                controlType={ControlTypes.DisplayLabel}
                                dataType={DataTypes.Numeric}
                            />
                            <FormField
                                isRequired
                                label="Plates Printed"
                                value={this.state.client.printCount}
                                onChange={value => this.handleChange('clientStatusTypeId', value)}
                                options={this.state.client.referenceData.clientStatusTypes}
                                controlType={ControlTypes.DisplayLabel}
                                dataType={DataTypes.Numeric}
                            />
                            <h4>Allocated Templates</h4>
                            {this.state.client.plateAllocation
                                .filter(plate => plate.isAllocated === true)
                                .map((item) => {
                                    return <div className="w3-row" style={containerStyle}>{item.description}</div>
                                })}

                            <h4>Last 10 Printed Plates</h4>
                            <table cellPadding={10}>
                                <thead>
                                <th align={"left"} nowrap={true}>Date</th>
                                <th align={"left"}>Template</th>
                                <th align={"left"}>Plate</th>
                                </thead>
                                <tbody>
                                {this.state.printedPlates
                                    .map((item) => {
                                        return <tr>
                                            <td nowrap={true}><Moment local format="YYYY-MM-DD HH:MM">{item.dateGenerated}</Moment>
                                            </td>
                                            <td>{item.template}</td>
                                            <td><img src={item.url} alt={item.template}/></td>
                                        </tr>
                                    })}

                                </tbody>
                            </table>

                        </div>
                    </div>


                </AuthorisedArea>
            </div>

        );
    }
}

export default withRouter(MyClient);
