import fetchData from '../helpers/fetchData';
import Server from '../constants/environment';

const isReponseCodeOK = num => num.toString()[0] === '2';

/**
 * Private Image Accessor, used to handle the difference between
 * images and backgrounds. They're handled the same way in the
 * backend, but the public methods allow 'GetBackground' and 'GetImage'.
 * This handles the type ids and all that.
 */
class ImageDataAccessorPrivate {
  /**
   * Private version to Get an image by it's ID
   */
  static GetImageById(id) {
    const request = {
      method: 'GET',
      URL: `${Server.getConnection()}/api/images/${id}`,
    };

    return new Promise((resolve, reject) => {
      fetchData(request)
        .then((result) => {
          const payload = result.json();
          resolve(payload);
        })
        .catch((error) => {
          reject(new Error(error));
        });
    });
  }

  /**
   * Private method to get a list of images, based on it's type.
   */
  static GetImages(type) {
    const request = {
      method: 'GET',
      URL: `${Server.getConnection()}/api/images/type/${type}`,
    };

    return new Promise((resolve, reject) => {
      fetchData(request)
        .then((result) => {
          const payload = result.json();
          resolve(payload);
        })
        .catch((error) => {
          reject(new Error(error));
        });
    });
  }

  /**
   * Private method to save a generic image.
   */
  static SaveImage(data) {
    const request = {
      method: 'POST',
      URL: `${Server.getConnection()}/api/images/`,
      data,
    };

    return new Promise((resolve, reject) => {
      fetchData(request)
        .then((result) => {
          const payload = result.json();
          resolve(payload);
        })
        .catch((error) => {
          reject(new Error(error));
        });
    });
  }

    /**
   * Private method to delete a generic image.
   */
  static DeleteImage(id) {
    const request = {
      method: 'DELETE',
      URL: `${Server.getConnection()}/api/images/${id}`,
    };

    return new Promise((resolve, reject) => {
      fetchData(request)
        .then((result) => {
          const payload = result.json();
          resolve(payload);
        })
        .catch((error) => {
          reject(new Error(error));
        });
    });
  }
}

class ImageDataAccessor {
  /**
   * Gets a list of all the Images.
   * Note, this requires Admin.
   */
  static GetImages(imageType) {
    return ImageDataAccessorPrivate.GetImages(imageType);
  }
  /**
   * Get an Image Background by it's ID
   */
  static GetImageById(id) {
    return ImageDataAccessorPrivate.GetImageById(id);
  }
  /**
   * Saves a Image
   */
  static SaveImage(data) {
    return ImageDataAccessorPrivate.SaveImage(data);
  }

  static DeleteImage(id) {
    return ImageDataAccessorPrivate.DeleteImage(id);
  }
}

export default ImageDataAccessor;
