import React from 'react';
import TemplateDataAccessor from '../../dataAccess/template';
import LoadingIcon from '../common/loadingIcon';
import FormField, {ControlTypes, DataTypes} from "../common/inputs/formField";
import ClientDataAccessor from "../../dataAccess/client";
import toast from 'react-hot-toast';
import Auth from "../../authentication";
import Translate from "../../internationalisation/translations";
import { UserContext } from '../../contexts/userContext';

const descriptionStyle = {
    marginBottom: '10px',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    fontWeight: '800',
};

const containerStyle = {
    cursor: 'pointer',
};

const imageContainerStyle = {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '10px',

};

export default class SelectTemplate extends React.Component {
  static contextType = UserContext;
  
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            templates: this.props.templates,
            isLoading: false,
            requiresUserValidation: Auth.clientUserVerificationRequired(),
            userValidated: false,
            userId: null,
            pin: ''
        };
        this.validate = this.handleNextClick.bind(this);
        this.updateStateWithProps = this.updateStateWithProps.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
            this.updateStateWithProps();
        }
    }

    /**
     * Event fired when a different template is selected.
     */
    changeTemplateSelection(templateId) {
        if (templateId) {
            // We want to set the Selected ID right away for the screen to show the selected
            // item quickly. So, we'll set the state, and then update the template data.
            const uiUpdate = {...this.state.data, templateId};
            this.setState({
                data: uiUpdate,
                isLoading: true,
            });

            // Go grab the template from the API, so that we have it's information for the next step.
            // Pass 'false' for includePreview, as we don't want the preview data with the template.
            TemplateDataAccessor.GetById(templateId, false)
                .then((result) => {
                    // Get the state,.
                    this.props.onChange(result.payload);
                })
                .then(() => {
                    // And once we're done... auto click 'Next'.
                    this.handleNextClick();
                })
                .catch((e) => {
                    console.log(`Failed on load! ${e}`);
                    this.setState({
                        isLoading: false,
                    });
                });
        }
    }

    updateStateWithProps() {
        this.setState({data: this.props.data});
    }

    handleNextClick() {
        this.props.afterValidate();
    }

    validateClientUser() {
        ClientDataAccessor.ValidateClientUser(this.state.pin)
            .then((result) => {
                if (result.isSuccess) {
                    // Set that the user is validated, and send the Id back to the parent to log when printing.
                    this.setState({userValidated: true}, () => this.props.setValidatedClientUser(result.payload))
                } else {
                    toast.error(`Invalid PIN`);
                    this.setState({pin: ""})
                }
            })
    }

    handleChange(field, value) {
        this.setState({
            [field]: value
        })
    }

    onKeyPress(target) {
        if (target.charCode === 13) {
            this.validateClientUser();
        }
      }

    render() {
        const {languageCode} = this.context;
        
        if (this.props.currentStep !== 0) {
            return null;
        }

        if (this.state.isLoading) {
            return <LoadingIcon/>
        }

        return (
            <div className="">
                {this.state.data.isTemplatesLoaded &&
                this.props.data.templates &&
                !this.props.data.templates.length &&
                <div>
                    <i className="fa fa-info-circle"/>
                    You do not have any templates assigned to you.
                    Please contact SmartPlates to resolve this.
                </div>
                }
                {this.state.requiresUserValidation && this.state.userValidated === false &&
                <div className="w3-panel w3-card-4 w3-padding w3-round w">
                    <div><i className="fa fa-info-circle"/> You need to identify yourself in order to generate a plate.
                        Please enter your PIN
                    </div>
                    <div className={"w3-quarter"}>
                        <FormField
                            isRequired
                            label="PIN"
                            value={this.state.pin}
                            onChange={value => this.handleChange('pin', value)}
                            maxLength={4}
                            controlType={ControlTypes.Password}
                            dataType={DataTypes.Password}
                            onKeyPress = {key => this.onKeyPress(key)}
                        />

                    </div>
                    <div className={"w3-quarter"}><br/>
                        <button className="w3-btn w3-green w3-padding w3-round"
                                onClick={() => this.validateClientUser()}>Go
                        </button>
                    </div>
                </div>
                }
                {(this.state.requiresUserValidation === false || this.state.userValidated) // If the user doesn't need validation, or they're validated
                && this.props.templates.map((item) => {
                    const selected = item.id === this.state.data.templateId;
                    return (
                        <div key={item.id} style={containerStyle} className="w3-col m4 l3 w3-hover-border-light-blue "
                             onClick={() => this.changeTemplateSelection(item.id)}>
                            <div
                                className={`w3-container w3-margin w3-border w3-round w3-card-4 animated fadeIn ${selected && 'w3-blue'}`}>
                                <div><span style={descriptionStyle}>{item.description}</span></div>
                                <div><img className="w3-image w3-hover-opacity" src={`${item.previewUrl}?=${new Date}`}
                                          alt={item.description} style={imageContainerStyle}/></div>
                            </div>
                        </div>
                    );
                })}
                <div className="w3-container w3-right-align">
                    <button className="w3-btn w3-green w3-round"
                            disabled={this.state.isLoading || !this.state.data.templateId}
                            onClick={() => this.handleNextClick()}>{Translate.getTranslation(languageCode, "TEXT_Next")} <i className="fa fa-arrow-circle-right"/>
                    </button>
                </div>
            </div>
        );
    }
}
