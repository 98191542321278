import React from 'react';
import FormField, { ControlTypes, DataTypes } from '../common/inputs/formField';

export default class TemplateGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      selectedComponent: null,
      groupTypeId: null
    };
    this.onChange = this.onChange.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const newSelectedItem = nextProps.selectedComponent;
    this.setState({
      selectedComponent: newSelectedItem,
      refdata: nextProps.refdata,
      groupTypeId: nextProps.groupTypeId
    });
  }

  onChange(fieldName, fieldValue) {
    const thisData = { ...this.state.selectedComponent, [fieldName]: fieldValue };
    this.setState({ selectedComponent: thisData });
  }

  onSave() {
    this.props.onSave(this.state.selectedComponent);
  }

  render() {
    return (
      <div>
        <header className="w3-container w3-blue">
          <h2>Plate Component</h2>
        </header>
        {this.state.selectedComponent &&
          <div className="w3-container">
            <div className="w3-row">
              <div className="w3-col w3-half">
                <FormField
                  isRequired
                  label="Description"
                  value={this.state.selectedComponent.description}
                  onChange={value => this.onChange('description', value)}
                  maxLength={30}
                  controlType={ControlTypes.EditBox}
                  dataType={DataTypes.String}
                />
              </div>
              <div className="w3-col w3-half">
                <FormField
                  isRequired
                  label="Position"
                  value={this.state.selectedComponent.componentPositionTypeId}
                  onChange={value => this.onChange('componentPositionTypeId', value)}
                  controlType={ControlTypes.DropdownSelection}
                  options={this.state.refdata.componentPositionType}
                  dataType={DataTypes.Numeric}
                />
              </div>
            </div>
            <div className="w3-row">
              {this.state.selectedComponent.componentPositionTypeId === 1 && // Fixed.
                <div>
                  <div className="w3-col w3-half">
                    <FormField
                      isRequired
                      label="Anchor Point Type"
                      value={this.state.selectedComponent.anchorPointTypeID}
                      onChange={value => this.onChange('anchorPointTypeID', value)}
                      controlType={ControlTypes.DropdownSelection}
                      options={this.state.refdata.anchorPointTypes}
                      dataType={DataTypes.Numeric}
                    />
                  </div>
                  <div className="w3-col w3-quarter">
                    <FormField
                      isRequired
                      label="X Anchor Point"
                      value={this.state.selectedComponent.anchorPointXMM}
                      onChange={value => this.onChange('anchorPointXMM', value)}
                      maxLength={4}
                      dataType={DataTypes.Numeric}
                      controlType={ControlTypes.EditBox}
                    />
                  </div>
                  <div className="w3-col w3-quarter">
                    <FormField
                      isRequired
                      label="Y Anchor Point"
                      value={this.state.selectedComponent.anchorPointYMM}
                      onChange={value => this.onChange('anchorPointYMM', value)}
                      maxLength={4}
                      controlType={ControlTypes.EditBox}
                      dataType={DataTypes.Numeric}
                    />
                  </div>
                </div>
              }
              {this.state.selectedComponent.componentPositionTypeId === 2 && // Relative
                <div>
                  <div className="w3-col w3-half">
                    <FormField
                      isRequired
                      label="Reletive Group 1"
                      value={this.state.selectedComponent.relativeGroup1Id}
                      onChange={value => this.onChange('relativeGroup1Id', value)}
                      controlType={ControlTypes.DropdownSelection}
                      options={this.props.relativeComponents}
                      dataType={DataTypes.String}
                    />
                  </div>
                  <div className="w3-col w3-half">
                    <FormField
                      isRequired
                      label="Reletive Group 2"
                      value={this.state.selectedComponent.relativeGroup2Id}
                      onChange={value => this.onChange('relativeGroup2Id', value)}
                      controlType={ControlTypes.DropdownSelection}
                      dataType={DataTypes.String}
                      options={this.props.relativeComponents}
                    />
                  </div>
                </div>
              }
            </div>
            <div className="w3-row">
              <div className="w3-col w3-quarter">
                <FormField
                  isRequired
                  label="Component Content Type"
                  value={this.state.selectedComponent.componentTypeId}
                  onChange={value => this.onChange('componentTypeId', value)}
                  controlType={ControlTypes.DropdownSelection}
                  options={this.state.refdata.componentTypes}
                  dataType={DataTypes.Numeric}
                />
              </div>
              <div className="w3-col w3-quarter">
              <FormField
                isRequired
                label="Readonly"
                value={this.state.selectedComponent.isReadOnly}
                onChange={e => this.onChange('isReadOnly', e)}
                controlType={ControlTypes.CheckBox}
                dataType={DataTypes.Boolean}
              />
            </div>

              {this.state.selectedComponent.componentTypeId === 2 && /* Text */
                <div>
                  <div className="w3-col w3-quarter">
                    <FormField
                      isRequired
                      label="Maximum Length"
                      value={this.state.selectedComponent.maxChars}
                      onChange={value => this.onChange('maxChars', value)}
                      maxLength={3}
                      controlType={ControlTypes.EditBox}
                      dataType={DataTypes.Numeric}
                    />
                  </div>
                </div>
              }
              {this.state.selectedComponent.componentTypeId === 1 && /* Image */
                <div>
                  <div className="w3-col w3-half">
                    <FormField
                      isRequired
                      label="Image"
                      value={this.state.selectedComponent.previewImageId}
                      onChange={value => this.onChange('previewImageId', value)}
                      controlType={ControlTypes.DropdownSelection}
                      options={this.state.refdata.customImageLibraries}
                      dataType={DataTypes.Numeric}
                    />
                  </div>
                </div>
              }
              {this.state.selectedComponent.componentTypeId === 3 && /* Shape */
                <div>
                  <div className="w3-col w3-quarter">
                  <FormField
                      isRequired
                      label="Shape Length (mm)"
                      value={this.state.selectedComponent.shapeWidth}
                      onChange={value => this.onChange('shapeWidth', value)}
                      maxLength={4}
                      controlType={ControlTypes.EditBox}
                      dataType={DataTypes.Numeric}
                    />
                  </div>
                  <div className="w3-col w3-quarter">
                  <FormField
                      isRequired
                      label="Shape Height (mm)"
                      value={this.state.selectedComponent.shapeHeight}
                      onChange={value => this.onChange('shapeHeight', value)}
                      maxLength={4}
                      controlType={ControlTypes.EditBox}
                      dataType={DataTypes.Numeric}
                    />
                  </div>
                </div>
              }
              {(this.state.selectedComponent.componentTypeId === 2 || this.state.selectedComponent.componentTypeId === 4)
                && /* Text or Registration */
                <div>
                  <div className="w3-col w3-quarter">
                    <FormField
                      isRequired
                      label="Text Height (mm)"
                      value={this.state.selectedComponent.groupHeightAdjustmentMM}
                      onChange={value => this.onChange('groupHeightAdjustmentMM', value)}
                      maxLength={3}
                      controlType={ControlTypes.EditBox}
                      dataType={DataTypes.Numeric}
                    />
                  </div>
                </div>
              }
            </div>
            <div className="w3-col w3-quarter">
              <FormField
                isRequired
                label="Display Order"
                value={this.state.selectedComponent.entryDisplayOrder}
                onChange={value => this.onChange('entryDisplayOrder', value)}
                maxLength={3}
                controlType={ControlTypes.EditBox}
                dataType={DataTypes.Numeric}
              />
            </div>
            <div className="w3-col w3-quarter">
              <FormField
                isRequired
                label="Render Order"
                value={this.state.selectedComponent.renderingOrder}
                onChange={value => this.onChange('renderingOrder', value)}
                maxLength={3}
                controlType={ControlTypes.EditBox}
                dataType={DataTypes.Numeric}
              />
            </div>
            <div>
            {(this.state.selectedComponent.componentTypeId === 2 || this.state.selectedComponent.componentTypeId === 4) /* Rego or Text */ &&
            <React.Fragment>
              <div className="w3-col w3-quarter">
              <FormField
                isRequired
                label="Preview Text"
                value={this.state.selectedComponent.previewText}
                onChange={value => this.onChange('previewText', value)}
                maxLength={30}
                controlType={ControlTypes.EditBox}
                dataType={DataTypes.String}
              />
            </div>
            <div className="w3-col w3-quarter">
                <FormField
                  isRequired
                  label="Font Spacing"
                  value={this.state.selectedComponent.fontSpacing}
                  onChange={value => this.onChange('fontSpacing', value)}
                  maxLength={30}
                  controlType={ControlTypes.EditBox}
                  dataType={DataTypes.Numeric}
                />
              </div>
              </React.Fragment>
            }
          </div>
          </div>
        }
        <footer className="w3-container w3-blue w3-right-align w3-padding">
          <button onClick={() => { this.onSave(); }} className="w3-btn w3-green"><i className="fa fa-check" /> Save</button>&nbsp;
          <button onClick={() => { this.props.onCancel(); }} className="w3-btn w3-blue"><i className="fa fa-ban" /> Cancel</button>
        </footer>
      </div>
    );
  }
}
