import React from 'react';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import toast from 'react-hot-toast';
import 'react-tabs/style/react-tabs.css';
import PageHeader from '../common/pageHeader';
import ClientDetails from './clientDetails';
import PlateAllocation from './plateAllocation';
import ImageAllocation from './imageAllocation';
import PlateHistory from './plateHistory';
import AuditLog from './auditLog';
import ClientUsers from "./clientUsers";
import AuthorisedArea from '../../helpers/AuthorisedArea';
import ClientDataAccessor from '../../dataAccess/client';
import {confirmAlert} from "react-confirm-alert";
import LoadingIcon from '../../images/loadingIcon.svg';

const menuButtonStyle = {
  marginLeft: '3px',
};
export default class Client extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDirty: false,
      isLoading: true,
      isNew: false,
      data: {},
    };

    this.handleStateUpdate = this.handleStateUpdate.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.deleteClient = this.deleteClient.bind(this);
    this.handlePlateAllocationChange = this.handlePlateAllocationChange.bind(this);
    this.handleImageAllocationChange = this.handleImageAllocationChange.bind(this);
    this.handleclientUsersChange = this.handleclientUsersChange.bind(this);
    this.handleClientUsersDelete = this.handleClientUsersDelete.bind(this);
    this.validateVAT = this.validateVAT.bind(this);
    this.generateRegistrationNumberEvent = this.generateRegistrationNumberEvent.bind(this);
  }

  componentDidMount() {
    // Are we trying to create a new client?
    this.setState({ isNew: !this.props.match.params.clientId }, () => {
      ClientDataAccessor.GetById(this.props.match.params.clientId)
        .then((incomingData) => {
          this.setState({
            data: incomingData.payload,
            isLoading: false,
          });
        });
    });
  }

  handleStateUpdate(property, value) {
    const stateCopy = { ...this.state.data };
    stateCopy[property] = value;
    this.setState({ data: stateCopy, isDirty: true });
  }

  handleClientUsersDelete(id) {

    const users = this.state.data.clientUsers;

      let user = users.find(x => x.id == id);
      const index = users.indexOf(user);
      users.splice(index, 1);

      this.setState( {
        isDirty: true,
        data: {...this.state.data, clientUsers: users}
      })

  }

  handleclientUsersChange(id, name, pin) {

    const users = this.state.data.clientUsers;
    
    if(id === -1) {
      const user = {
        id: '00000000-0000-0000-0000-000000000000',
        name: name,
        pin: pin
      }
      users.push(user)
    } else {


      let user = users.find(x => x.id == id);
      const index = users.indexOf(user);
      user.name = name;
      user.pin = pin;

      users.splice(index, 1);

      users.splice(index, 0, user)
    }
    this.setState( {
      isDirty: true,
      data: {...this.state.data, clientUsers: users}
    })
    
  }

  // We're taking the index of the selected item, and switching it's value.
  handlePlateAllocationChange(index) {
    // First, we get the ite, from the state.
    const theAllocation = this.state.data.plateAllocation[index];
    // If it has no date yet, lets set it to todays date... or else we leave it.
    if (!theAllocation.allocatedDate) { theAllocation.allocatedDate = new Date(); }
    // Switch the state.
    theAllocation.isAllocated = !theAllocation.isAllocated;
    // Recreate the Applocations with the updated Allocation.
    const updatedAllocations = this.state.data.plateAllocation.map((node, i) => {
      return i !== index
        ? node
        : theAllocation;
    });
    // Send the Updated Allocations to the state.
    this.setState({
      isDirty: true,
      data: { ...this.state.data, plateAllocation: updatedAllocations },
    });
  }

  // We're taking the index of the selected item, and switching it's value.
  handleImageAllocationChange(index) {
    // First, we get the ite, from the state.
    const theAllocation = this.state.data.imageAllocation[index];
    // If it has no date yet, lets set it to todays date... or else we leave it.
    if (!theAllocation.allocatedDate) { theAllocation.allocatedDate = new Date(); }
    // Switch the state.
    theAllocation.isAllocated = !theAllocation.isAllocated;
    // Recreate the Applocations with the updated Allocation.
    const updatedAllocations = this.state.data.imageAllocation.map((node, i) => {
      return i !== index
        ? node
        : theAllocation;
    });
    // Send the Updated Allocations to the state.
    this.setState({
      isDirty: true,
      data: { ...this.state.data, imageAllocation: updatedAllocations },
    });
  }

  handleDelete() {

    const options = {
      title: 'Confirmation',
      message: 'You are about to delete this client. Continue?',
      buttons: [
        {
          label: 'Yes, delete the client',
          onClick: () => this.deleteClient(),
        },
        {
          label: 'No',
        },
      ],
    };
    // Show the confirmation.
    confirmAlert(options);

  }
  
  deleteClient() {


    ClientDataAccessor.Delete(this.state.data.id)
        .then((result) => {
          if(result.isSuccess) {
            toast.success(result.responseText);
            this.props.history.push('/clients');
          } else {
            toast.error(result.responseText);
          }
        })
  }
  

  handleSave() {
    
    const currentIsDirty = this.state.isDirty;
    this.setState({isLoading: true, isDirty: false});
    
    let clientUsers = []
    this.state.data.clientUsers.forEach(x => {
      let u = {
        id: x.id < 0 ? null : x.id,
        name: x.name,
        pin: x.pin
      }
      clientUsers.push(u);
      
    });
    
    
    let data = {
      id : this.state.data.id,
      name: this.state.data.name,
      registrationNumber: this.state.data.registrationNumber,
      clientStatusTypeId: this.state.data.clientStatusTypeId,
      clientTypeId: this.state.data.clientTypeId,
      parentClientId: this.state.data.parentClientId,
      registrationPrefix: this.state.data.registrationPrefix,
      vatNumber: this.state.data.vatNumber,
      vatNumberValidated: this.state.data.vatNumberValidated,
      countryTypeId: this.state.data.countryTypeId,
      contactName: this.state.data.contactName,
      telephone: this.state.data.telephone,
      email: this.state.data.email,
      username: this.state.data.username,
      password: this.state.data.password,
      userPinRequired: this.state.data.userPinRequired,
      canViewPlateHistory: this.state.data.canViewPlateHistory,
      clientUsers: clientUsers
    }
    
    let allocatedTemplateIds = [];
    
    this.state.data.plateAllocation
        .filter(item => item.isAllocated === true)
        .map((image) => {
          allocatedTemplateIds.push(image.plateTemplateId);
    })
    
    data.allocatedPlateIds = allocatedTemplateIds

    let allocatedImageIds = [];

    this.state.data.imageAllocation
        .filter(item => item.isAllocated === true)
        .map((image) => {
          allocatedImageIds.push(image.imageId);
        })

    data.allocatedImageIds = allocatedImageIds

    ClientDataAccessor.Save(data)
      .then((message) => {
        
        if(message.isSuccess) {
          toast.success(message.responseText);
          this.props.history.push('/clients');
        } else {
          this.setState({isLoading: false, isDirty: currentIsDirty})
          toast.error(message.responseText);
        }
        
      })
      .catch((e) => {
        console.log('Got a reject!', e.message);
        toast.error(e.message);
      });
  }

  validateVAT() {
    if (!this.state.data.vatNumber) {
      toast.error('No VAT Number has been entered');
      return;
    }
    ClientDataAccessor.ValidateVatNumber(
      this.state.data.countryTypeId,
      this.state.data.vatNumber,
    )
      .then((result) => {
        if (result.isSuccess === true) {
          this.setState({
            isDirty: true,
            data:
              {
                ...this.state.data,
                vatNumberValidated: true,
              },
          });
          toast.success('VAT Number has been validated successfully.');
        } else {
          toast.error(result.message);
        }
      });
  }

  generateRegistrationNumberEvent() {
    
    ClientDataAccessor.GenerateRegistrationNumber(
      this.state.data.parentClientId,
      this.state.data.id
    )
      .then((result) => {
        if (result.isSuccess) {
          
          let data = this.state.data;
          data.registrationNumber = result.registrationNumber;
          
          this.setState({
            isDirty: true,
            data
          }, () => { toast.success(result.responseText); });
        } else {
          toast.error(result.responseText);
        }
      });
  }


  render() {
    const MenuItems = [{ Display: 'Clients', Url: '/clients' }];
    const displayHeading =  this.state.data && this.state.data.name ? this.state.data.name : 'Unnamed Client';

    
    return (
      <div className="w3-container">
        <PageHeader Heading={displayHeading} MenuItems={MenuItems} />
        <AuthorisedArea requiredAdmin showError>
          <div className="w3-row">
            <div className="w3-col w3-right-align">
              <button className="w3-btn w3-green w3-padding w3-round" onClick={this.handleSave} disabled={!this.state.isDirty} style={menuButtonStyle}>Save</button>
              <button className="w3-btn w3-red w3-padding w3-round" onClick={this.handleDelete} style={menuButtonStyle} disabled={this.state.isNew}>Delete</button>
              <Link className="w3-btn w3-blue w3-padding w3-round" to="/clients" href="/clients" style={menuButtonStyle}>Cancel</Link>
            </div>
          </div>
          
          {this.state.isLoading &&
          <div className="w3-center">
            <LoadingIcon />
          </div>
          }
          
          {!this.state.isLoading &&
            <div style={{ padding: '10px' }}>
              <Tabs >
                <TabList >
                  <Tab>Client Details</Tab>
                    {!this.state.isNew && this.state.data.userPinRequired && <Tab>Client Users</Tab>}
                    {!this.state.isNew && <Tab>Template Allocation</Tab>}
                    {!this.state.isNew && <Tab>Image Allocation</Tab>}
                    {!this.state.isNew && <Tab>Audit Log</Tab>}
                    {!this.state.isNew && <Tab>Plate History</Tab>}
                </TabList>

                <TabPanel>
                  <ClientDetails
                    data={this.state.data}
                    isNew={this.state.isNew}
                    onChange={this.handleStateUpdate}
                    validateVATEvent={this.validateVAT}
                    generateRegistrationNumberEvent={this.generateRegistrationNumberEvent}
                  />
                </TabPanel>
                {!this.state.isNew && 
                  <React.Fragment>
                    {this.state.data.userPinRequired &&
                    <TabPanel>
                      <ClientUsers
                          data={this.state.data.clientUsers}
                          onChange={this.handleclientUsersChange}
                          onDelete={this.handleClientUsersDelete}
                      />
                    </TabPanel>
                    }
                  <TabPanel>
                    <PlateAllocation
                      data={this.state.data.plateAllocation}
                      onChange={this.handlePlateAllocationChange}
                    />
                  </TabPanel>
                  <TabPanel>
                    <ImageAllocation
                      data={this.state.data.imageAllocation}
                      onChange={this.handleImageAllocationChange}
                    />
                  </TabPanel>
                  <TabPanel>
                    <AuditLog clientId={this.state.data.id} />
                  </TabPanel>
                  <TabPanel>
                    <PlateHistory clientId={this.state.data.id} />
                  </TabPanel>
                  </React.Fragment>
                }
              </Tabs>
            </div>
          }
        </AuthorisedArea>
      </div>
    );
  }

  
}
