import React from 'react';

const headingStyle = {
  background: 'linear-gradient(#09adf4, #5BC4F2)',
  color: 'white',
  padding: '6px 15px 6px 15px',
};
export default class InfoSection extends React.Component {
  render() {
    const textSection = (this.props.image ? 'w3-col l8' : 'w3-col l12');

    return (
      <div className="w3-card">
        <div className="w3-container" style={headingStyle}>
          {this.props.heading}
        </div>
        <div className="w3-container">
          <div className={textSection}>
            {this.props.content}
          </div>
          {!!this.props.image &&
          <div className="w3-col l4 w3-padding w3-right-align">
            <img className="w3-image" src={this.props.image} alt="Not sure" />
          </div>
          }
        </div>
      </div>
    );
  }
}
