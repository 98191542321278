import React, { Component } from 'react';
import Header from '../common/pageHeader';
import ContactDetails from './contactDetails';
import ContactForm from './contactForm';
import { UserContext } from '../../contexts/userContext';
import Translate from "../../internationalisation/translations";

const mapResponsiveStyle = {
  overflow: 'hidden',
  paddingBottom: '56.25%',
  position: 'relative',
  height: '0',
};
const iframeStyle = {
  left: '0',
  top: '0',
  height: '100%',
  width: '100%',
  position: 'absolute',
  border: '0',
  paddingRight: '30px',
};

export default class Contact extends Component {
  static contextType = UserContext;
  
  render() {
    const {languageCode} = this.context;
    return (
      <div>
        <Header Heading={Translate.getTranslation(languageCode, "TEXT_ContactUs")} />
        <div className="w3-row">
          <div className="w3-half" style={mapResponsiveStyle}>
            <iframe
              className="animated fadeIn"
              style={iframeStyle}
              title="Our Location"
              src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAtYWjiKhD_PyTKg4nTX5yI_O4rGTWzZ3A&q=4500-561,Portugal"
            />
          </div>
          <div className="w3-half">
            <ContactDetails />
            <ContactForm />
          </div>
        </div>
      </div>
    );
  }
}
