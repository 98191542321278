/* global sessionStorage:false, window:false */
import AuthenticationDataAccessor from './dataAccess/authentication';
import Server from "./constants/environment";

const NotFound = -1;
const DateMultiplier = 1000;

const Auth = (function Auth() {
  // Parse the JWT token so that we can get the data from it.
  function parseJwt(token) {
    // Is there a token?
    if (!token) { return ''; }
    // Do we have the separator?
    if (token.indexOf('.') === NotFound) { return ''; }
    // Token is made of two parts. Header info, and payload. Let's split it and take the payload.
    const tokenPayloadSection = token.split('.')[1];
    // Clean up the base64 text.
    const base64 = tokenPayloadSection.replace('-', '+').replace('_', '/');
    // Return the payload, decoded.
    return JSON.parse(window.atob(base64));
  }

  // Get the Expiry date from the JWT Token
  function expiryEpoch() {
    if (Auth.token()) {
      // Decode the token.
      const decoded = parseJwt(Auth.token());
      if (decoded) {
        return decoded.exp;
      }
    }
    return -1;
  }

  // Validate that the token is valid and current.
  function isValidToken() {
    const nowEpoch = Math.floor(new Date().getTime() / DateMultiplier);
    const expiry = expiryEpoch();
    // Current date is greater than the expiry?
    const hasExpired = (expiry < 0) || nowEpoch > expiry;

    if (hasExpired) {
      // Token seems invalid... Let's clear the login details.
      Auth.logout();
      // and fail.
      return false;
    }
    return !hasExpired;
  }

  // Properties of the Authentication object, easily accessible.
  Auth.token = function token() {
    return sessionStorage.getItem(Auth.StorageToken);
  };
  Auth.email = function email() {
    return sessionStorage.getItem(Auth.StorageEmail);
  };
  Auth.clientName = function clientName() {
    return sessionStorage.getItem(Auth.StorageName);
  };
  Auth.clientId = function clientId() {
    return sessionStorage.getItem(Auth.StorageClientId);
  }
  Auth.isAuthenticated = function isAuthenticated() {
    return isValidToken(); // Logged in is true if we have a valid token.
  };
  Auth.clientTypeId = function clientTypeId() {
    return sessionStorage.getItem(Auth.StorageClientTypeId)
  }
  Auth.clientUserVerificationRequired = function clientUserVerificationRequired() {
    return sessionStorage.getItem(Auth.StorageClientUserVerification) === 'true';
  }
  Auth.canViewPlateHistory = function canViewPlateHistory() {
    // Has Admin enabled Plate History for this user?
    return (sessionStorage.getItem(Auth.StorageCanViewPlateHistory) === 'true');
  }

  Auth.isAdmin = function isAdmin() {
    // Decode the JWT
    const decoded = parseJwt(Auth.token());
    let result = false;
    // Get each item from the JWT into an array.
    const splitToken = Object.keys(decoded);
    // Iterate through each item..
    splitToken.forEach((splitItem) => {
      // and see if it has the word 'roles'.
      if (splitItem.match(/.*role$/)) {
          if (decoded[splitItem] === 'Admin') {
            // If so, return true.
            result = true;
          }
      //  });
      }
    });
    // Not matching admin tag found in the token, so... not an admin.
    return result;
  };

  Auth.logout = function logout() {
    sessionStorage.removeItem(Auth.StorageToken);
    sessionStorage.removeItem(Auth.StorageEmail);
    sessionStorage.removeItem(Auth.StorageName);
    sessionStorage.removeItem(Auth.StorageClientTypeId);
    sessionStorage.removeItem(Auth.StorageClientId);
    sessionStorage.removeItem(Auth.StorageClientUserVerification);
    sessionStorage.removeItem(Auth.StorageCanViewPlateHistory);
  };

  Auth.login = function login(Username, Password, callback) {
    console.log("Login called. FE Version reported as", Server.getBuildNumber())
    return new Promise((resolve, reject) => {
      sessionStorage.removeItem(Auth.StorageToken);
      console.log("In promise");
      AuthenticationDataAccessor.authenticateCredentials(Username, Password)
        .then((data) => {
          if (data.isSuccess) {
            console.log("$$$", data.payload.canViewPlateHistory);
            sessionStorage.setItem(Auth.StorageToken, data.payload.jwt);
            sessionStorage.setItem(Auth.StorageEmail, data.payload.email);
            sessionStorage.setItem(Auth.StorageName, data.payload.name);
            sessionStorage.setItem(Auth.StorageClientTypeId, data.payload.clientTypeId);
            sessionStorage.setItem(Auth.StorageClientId, data.payload.id);
            sessionStorage.setItem(Auth.StorageClientUserVerification, data.payload.requiresClientUserVerification);
            sessionStorage.setItem(Auth.StorageCanViewPlateHistory, data.payload.canViewPlateHistory);
            resolve(data);
          }
          resolve(data);
        })
      })
      .catch((exception) => {
        console.log(`Error in the Login method! ${exception}`);
        // Why is this always firing?!?!?
        reject(false)
      });
  };

  return Auth;
}());


// These are the names they are in the cookie, defined by the server. We assign easy names.
Auth.StorageToken = 'JWT';
Auth.StorageEmail = 'Email';
Auth.StorageName = 'Name';
Auth.StorageClientTypeId = "Actor";
Auth.StorageClientId = "Id";
Auth.StorageClientUserVerification = "RequiresClientUserVerification";
Auth.StorageCanViewPlateHistory = "CanViewPlateHistory";

export default Auth;
