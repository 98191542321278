import React from 'react';
import { Link } from 'react-router-dom';
import PageHeader from '../common/pageHeader';
import AuthorisedArea from '../../helpers/AuthorisedArea';
import ImageDataAccessor from '../../dataAccess/image';
import Moment from 'react-moment';

const buttonPanelStyle = {
  paddingBottom: '5px',
};

const tableHeaderStyle = {
  background: '#09adf4',
  color: 'white',
};

export default class Images extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      imageType: null,
      imageTypeName: null,
      imageTypeUrl: null,
      images: [],
    };
  }

  componentDidMount() {
    let imageType = window.location.href.search("/backgrounds") === -1 ? 2 : 1
    switch(imageType) {
      case 1:
        this.setState({
          imageType: imageType,
          imageTypeName: "Backgrounds",
          imageTypeUrl: "background"
        })
        break;
      case 2: 
      this.setState({
        imageType: imageType,
        imageTypeName: "Custom Images",
        imageTypeUrl: "customimage"

      })
      
    }
    
    
    this.setState({
      imageType: imageType
    })

    ImageDataAccessor.GetImages(imageType)
      .then((data) => {
        this.setState({ images: data.payload, isLoading: false });
      });
  }

  handleSelection(id) {
    if(this.state.imageType === 1) {
      this.props.history.push(`/images/backgrounds/${id}`);
    } else {
      this.props.history.push(`/images/customimages/${id}`);
    }
  }

  render() {
    const menuItems = [];

    return (
      <div className="w3-container">
        <PageHeader Heading={`${this.state.imageTypeName}`} MenuItems={menuItems} />
        <AuthorisedArea requiredAdmin showError>
          <div className="w3-row" style={buttonPanelStyle}>
            <div className="w3-col l12 w3-right-align">
              <Link className="w3-btn w3-blue w3-round" href={`${this.state.imageTypeUrl}`} to={`${this.state.imageTypeUrl}`}><i className="fa fa-upload" /> Upload New Background</Link>
            </div>
          </div>
          <table className="w3-table-all w3-card-4 w3-hoverable w3-border">
            <thead >
              <tr style={tableHeaderStyle}>
                <td>Background Name</td>
                <td>User Display Text</td>
                <td>Templates Assigned</td>
                <td>Date Created</td>
                <td>File Size</td>
                <td>Type</td>
                <td className="w3-right-align">Preview</td>
              </tr>
            </thead>
            {!this.state.isLoading &&
            <tbody>
              {this.state.images.map(image => (
                <tr className="tableRow" onClick={() => { this.handleSelection(image.id); }} key={image.id}>
                  <td>{image.description}</td>
                  <td>{image.displayText}</td>
                  <td>{image.assignedCount}</td>
                  <td><Moment format="ll">{image.uploadedDate}</Moment></td>
                  <td>{image.fileSize} bytes</td>
                  <td>{image.contentType}</td>
                  <td><img src={image.url + "?r=" + new Date()} height="15px" /></td>
                </tr>
               ))}
            </tbody>
            }
          </table>
        </AuthorisedArea>
      </div>
    );
  }
}
