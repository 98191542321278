import "../styles/index.scss"
import React, { useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Navbar from './navbar';
import Home from './home/';
import Login from './login/';
import Contact from './contact/';
import Dashboard from './dashboard/';
import Clients from './clients/';
import Client from './client/';
import Templates from './templates/';
import Template from './template/';
import Images from './images/';
import Image from './image/';
import PlateCreator from './platecreator/';
import MyClients from "./myclients";
import MyClient from "./myclient";
import History from "./history"
import Inbox from "./inbox"
import MyHistory from "./myhistory";
import GenerationsReport from "./analytics/generationsReport";
import 'w3-css/w3.css';
import UserContextProvider from "../contexts/userContext";
import { Toaster } from 'react-hot-toast';
import Server from "../constants/environment";

const pageStyle = {
  maxWidth: '1350px',
  margin: 'auto',
};

function App() {
    
    return (
      <>
        <UserContextProvider>
          <Router>
            <div>
              <Navbar />
              <div style={pageStyle}>
                <Route exact path="/" component={Home} />
                <Route path="/login" component={Login} />
                <Route path="/contact" component={Contact} />
                <Route path="/dashboard" component={Dashboard} />
                <Route path="/clients" component={Clients} />
                <Route path="/client/:clientId" component={Client} />
                <Route path="/myclients" component={MyClients} />
                <Route path="/myclient/:clientId" component={MyClient} />
                <Route exact path="/client/" component={Client} />
                <Route path="/templates" component={Templates} />
                <Route exact path="/template/:templateId" component={Template} />
                <Route exact path="/template/" component={Template} />
                
                <Route exact path="/analytics/generations" component={GenerationsReport} />
                
                <Route exact path="/images/backgrounds" component={Images} />
                <Route exact path="/images/background" component={Image} />
                <Route exact path="/images/backgrounds/:imageId" component={Image} />

                <Route exact path="/images/customimages" component={Images} />
                <Route exact path="/images/customimage" component={Image} />
                <Route exact path="/images/customimages/:imageId" component={Image} />

                <Route path="/platecreator" component={PlateCreator} />
                
                <Route exact path="/history" component={History} />
                <Route exact path="/myhistory" component={MyHistory} />
                
                <Route exact path="/inbox" component={Inbox} />
              </div>
              <div style={{textAlign: "center", color: "#A7DCFF", fontSize:"10px"}}>SmartPlates UI - Version: {Server.getBuildNumber()}</div>
            </div>
            <Toaster toastOptions={{
              duration: 5000,
              className: '',
              style: {
                border: '1px solid #55FF00',
                padding: '18px',
                color: '#713200',
              },
            }}
            containerStyle={{
              top: 100
            }}
        />
          </Router>
        </UserContextProvider>
      </>
    );
}


export default App;