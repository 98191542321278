import React, { Component } from 'react';
import Moment from 'react-moment';
import PropTypes from 'prop-types';
import strToMoney from '../../../../helpers/stringHelpers';
import ControlLabel from './controlLabel';
import { DataTypes } from '../formField';

const dataDisplayStyle = {
  textIndent: '12px',
  paddingTop: '8px',
};

export default class DisplayLabel extends Component {
  render() {
    // Do we need to format the date?
    const useDate = !!this.props.dataType && this.props.dataType === DataTypes.Date;
    const useDateRelative = !!this.props.dataType && this.props.dataType === DataTypes.DateRelative;
    const useDateCalendar = !!this.props.dataType && this.props.dataType === 'date-calendar';
    const useMoney = !!this.props.dataType && this.props.dataType === 'money';

    let displayValue = this.props.value;

    if (useDate) {
      displayValue = <Moment format="ll">{displayValue}</Moment>;
    }
    if (useDateCalendar) {
      displayValue = <Moment calendar>{displayValue}</Moment>;
    }
    if (useDateRelative) {
      displayValue = <Moment fromNow utc>{displayValue}</Moment>;
    }
    if (useMoney) {
      displayValue = strToMoney(displayValue);
    }

    return (
      <div className="w3-container w3-padding">
        <div className="w3-row">
          <div className="w3-col notranslate">
            <ControlLabel text={this.props.label} />
          </div>
        </div>
        <div className="w3-row">
          <div className="w3-col" style={dataDisplayStyle}>
            <span>{displayValue}</span>
          </div>
        </div>
      </div>

    );
  }
}

DisplayLabel.propTypes = {
  dataType: PropTypes.number,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

DisplayLabel.defaultProps = {
  dataType: 1,
  value: '',
};
