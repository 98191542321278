import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Jumbotron from '../common/jumbotron/jumbotron';
import ContactDetails from '../contact/contactDetails';
import Auth from '../../authentication';
import InfoSection from '../common/infoSection';
import Image1 from '../../images/home-1.jpg';
import Image2 from '../../images/home-2.jpg';
import Translate from "../../internationalisation/translations";
import { UserContext } from '../../contexts/userContext';

const rowPadding = {
  padding: '0px 0px 24px 0px',
};

const ContactUs = (
  <section>
    <ContactDetails />
  </section>
);

export default class Home extends Component {
  static contextType = UserContext;
  
  render() {

    
    const {languageCode} = this.context;
    
    let StatusContent = (Auth.isAuthenticated() === true) ? (
      <section>
        <p>{Translate.getTranslation(languageCode, "TEXT_Welcome")}, {Auth.clientName()}.</p>
        <p>{Translate.getTranslation(languageCode, "TEXT_YouLoggedIn")} <Link to="/dashboard">Here</Link></p>
      </section>
    ) 
      : 
    (
      <section>
        <p>{Translate.getTranslation(languageCode, "TEXT_Welcome")}</p>
        <p>{Translate.getTranslation(languageCode, "TEXT_YouNotLoggedIn")}</p>
      </section>
    )

   


    return (
      <div>
        <Jumbotron title="Smart Plates" subtitle="Welcome" />
        <div className="w3-container">
          <div className="w3-row-padding" style={rowPadding}>
            <section className="w3-col l9 w3-padding">
              <InfoSection heading={Translate.getTranslation(languageCode, "HOME_WhoWeAre")} image={Image1} content={Translate.getTranslation(languageCode, "HOME_Intro")} />
            </section>
            <section className="w3-col l3 w3-padding">
              <InfoSection heading={Translate.getTranslation(languageCode, "HOME_CurrentStatus")} content={StatusContent} />
            </section>
          </div>
          <div className="w3-row-padding" style={rowPadding}>
            <section className="w3-col l8 w3-padding">
              <InfoSection heading={Translate.getTranslation(languageCode, "HOME_HowWeCanHelp")} content={Translate.getTranslation(languageCode, "HOME_Intro2")} image={Image2} />
            </section>
            <section className="w3-col l4 w3-padding">
              <InfoSection heading={Translate.getTranslation(languageCode, "TEXT_ContactUs")} content={ContactUs} />
            </section>
          </div>
        </div>
      </div>
    );
  }
}
