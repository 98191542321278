import React from 'react';
import TrueFalseDisplay from '../common/inputs/components/TrueFalseDisplay';
import getValueFromData from '../../helpers/referenceDataHelpers';

const tableHeaderStyle = {
  background: '#5BC4F2',
  color: 'white',
};

const descriptionStyle = {
  fontWeight: '800',
};

export default class TemplateListTab extends React.Component {
  render() {
    return (
      <div>
        <table className="w3-table-all w3-card">
          <thead>
            <tr style={tableHeaderStyle}>
              <th>Name</th>
              <th>Type</th>
              <th>Position</th>
              <th>Read-Only</th>
              <th>Maximum Length</th>
              <th>Anchor Position</th>
              <th>Render Order</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {this.props.components && this.props.components.map(component => (
              <tr className="tableRow" key={component.id}>
                <td onClick={() => { this.props.onClick(component.id, this.props.groupIndex); }}>
                  <span style={descriptionStyle}>{component.description}</span>
                </td>
                <td onClick={() => { this.props.onClick(component.id, this.props.groupIndex); }}>{
                  getValueFromData(
                    this.props.refData.plateComponentTypes,
                    component.groupComponentTypeId,
                  )}
                </td>
                <td onClick={() => { this.props.onClick(component.id, this.props.groupIndex); }}>
                  {getValueFromData(
                    this.props.refData.anchorPointTypes,
                    component.anchorPointTypeID,
                  )}
                </td>
                <td onClick={() => { this.props.onClick(component.id, this.props.groupIndex); }}>
                  <TrueFalseDisplay value={component.isReadOnly} />
                </td>
                <td onClick={() => { this.props.onClick(component.id, this.props.groupIndex); }}>
                  {component.maxChars}
                </td>
                <td onClick={() => { this.props.onClick(component.id, this.props.groupIndex); }}>
                  {component.groupComponentPositionTypeId &&
                    `${getValueFromData(
                      this.props.refData.groupComponentPositionType,
                      component.groupComponentPositionTypeId,
                    )} ${component.groupComponentPositionTypeId === 1 ? 'at' : 'between'} `}
                  {component.groupComponentPositionTypeId === 1 ? `x=${component.anchorPointXMM}mm, y=${component.anchorPointYMM}mm` : ''}
                  {component.groupComponentPositionTypeId === 2 && this.props.components && `${getValueFromData(this.props.components, component.relativeGroup1Id)} and ${getValueFromData(this.props.components, component.relativeGroup2Id)}`}
                </td>
                <td onClick={() => { this.props.onClick(component.id, this.props.groupIndex); }}>
                  {component.renderingOrder}
                </td>
                <td className="w3-right-align">
                  <button className="w3-btn w3-circle w3-red" onClick={() => this.props.onDeleteClick(this.props.groupIndex, component.id)}><i className="fa fa-xs fa-trash" /></button>
                </td>
              </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    );
  }
}
