import React from 'react';
import Moment from 'react-moment';
import FormField, {ControlTypes, DataTypes} from "../common/inputs/formField";

const tableHeaderStyle = {
    background: '#5BC4F2',
    color: 'white',
    width: '100%',
    borderRadius: '3px',
    fontWeight: 'normal',
    padding: "5px"
};
export default class ClientUsers extends React.Component {
    constructor(props) {
        super(props);
        this.handleToggle = this.handleToggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleAdd = this.handleAdd.bind(this);

        this.state = {
            id: null,
            name: null,
            pin: null
        }
    }

    handleAdd() {
        this.setState({
            id: -1,
            name: null,
            pin: null
        })

        this.props.onChange(this.state.id, this.state.name, this.state.pin);
    }
    
    handleSave() {
        
        
        this.props.onChange(this.state.id, this.state.name, this.state.pin);
        
        this.setState({
            id: null,
            name: null,
            pin: null
        })
    }

    handleDelete() {

        this.props.onDelete(this.state.id);
        this.setState({
            id: null,
            name: null,
            pin: null
        })
        
    }

    handleToggle(index) {

        const user = this.props.data.find(x => x.id === index)

        this.setState({
            id: user.id,
            name: user.name,
            pin: user.pin
        })
    }

    handleChange(field, value) {
        this.setState({[field]: value})
    }

    render() {
        return (
            <section>

                <table className="w3-table  w3-bordered w3-hoverable">
                    <thead className="tableheader">
                    <tr>
                        <th>Name</th>
                        <th>PIN</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.props.data.map(((user, index) =>
                            (
                                <tr key={user.id} onClick={() => this.handleToggle(user.id)}>
                                    <td><span>{user.name}</span></td>
                                    <td><span>{user.pin}</span></td>
                                </tr>
                            )
                    ))}

                    </tbody>
                </table>
                <br/>
                <button className="w3-btn w3-green w3-padding w3-round" onClick={() => this.handleAdd()}>Add</button>

                <br />
                {this.state.id &&
                <div>
                    <div className="w3-row w3-third">
                        <div className="w3-third" style={tableHeaderStyle}>
                            Edit
                        </div>
                        <div>
                            <FormField
                                isRequired
                                label="User Name"
                                value={this.state.name}
                                onChange={value => this.handleChange('name', value)}
                                maxLength={30}
                                controlType={ControlTypes.EditBox}
                                dataType={DataTypes.String}
                            />
                            <FormField
                                isRequired
                                label="PIN"
                                value={this.state.pin}
                                onChange={value => this.handleChange('pin', value)}
                                maxLength={4}
                                controlType={ControlTypes.EditBox}
                                dataType={DataTypes.String}
                            />
                        </div>
                        <div className="w3-row w3-third">
                            <button className="w3-btn w3-green w3-padding w3-round" onClick={() => this.handleSave()}>Save</button>
                            <button className="w3-btn w3-red w3-padding w3-round" onClick={() =>this.handleDelete()}>Delete</button>
                        </div>

                    </div>
                </div>
                }
            </section>
        );
    }

}
