import React from 'react';
import FormField, {ControlTypes, DataTypes} from '../common/inputs/formField';
import toast from 'react-hot-toast';


export default class GroupEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            groupName: null,
            groupTypeId: null,
            fontTypeId: null,
        };
        this.onSave = this.onSave.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            selectedGroup: nextProps.selectedGroup,
        });
    }

    onSave() {
        if (!this.state.groupName) {
            toast.error("Group name is required");
            return;
        }

        if (!this.state.groupTypeId) {
            toast.error("Group type is required");
            return;
        }

        if (!this.state.fontTypeId) {
            toast.error("Group Font is required");
            return;
        }
        this.props.onSave(this.state.groupName, this.state.groupTypeId, this.state.fontTypeId);
        this.setState({
            groupName: "",
            groupTypeId: 0,
            fontTypeId: 0,
        });
    }

    onChange(fieldName, fieldValue) {
        this.setState({[fieldName]: fieldValue});
    }

    render() {
        return (
            <div>
                <header className="w3-container w3-blue">
                    <h2>Plate Group</h2>
                </header>
                <div className="w3-container w3-padding">
                    <div className="w3-row">
                        <div className="w3-col w3-quarter">
                            <FormField
                                isRequired
                                label="Group Name"
                                dataType={DataTypes.String}
                                value={this.state.groupName}
                                onChange={value => this.onChange('groupName', value)}
                                maxLength={30}
                                controlType={ControlTypes.EditBox}
                            />
                        </div>

                        <div className="w3-col w3-quarter">
                            <FormField
                                isRequired
                                label="Type"
                                dataType={DataTypes.Numeric}
                                options={this.props.groupTypes}
                                value={this.state.groupTypeId}
                                onChange={value => this.onChange('groupTypeId', value)}
                                controlType={ControlTypes.DropdownSelection}
                            />
                        </div>

                        <div className="w3-col w3-quarter">
                            <FormField
                                isRequired
                                label="Group Font"
                                dataType={DataTypes.Numeric}
                                options={this.props.fontTypes}
                                value={this.state.fontTypeId}
                                onChange={value => this.onChange('fontTypeId', value)}
                                controlType={ControlTypes.DropdownSelection}
                            />
                        </div>
                    </div>
                </div>
                <footer className="w3-container w3-blue w3-right-align w3-padding">
                    <button onClick={() => {
                        this.onSave();
                    }} className="w3-btn w3-green"><i className="fa fa-check"/> Save
                    </button>
                    &nbsp;
                    <button onClick={() => {
                        this.props.onCancel();
                    }} className="w3-btn w3-blue"><i className="fa fa-ban"/> Cancel
                    </button>
                </footer>
            </div>
        );
    }
}
