import React from 'react';
import PropTypes from 'prop-types';

const labelStyle = {
  fontSize: '14px',
  fontWeight: '800',
};

export default class ControlLabel extends React.Component {
  render() {
    return (
      <span style={labelStyle}>
        {this.props.text} {this.props.isRequired ? '*' : ''}
      </span>
    );
  }
}

ControlLabel.propTypes = {
  text: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
};

ControlLabel.defaultProps = {
  isRequired: false,
};
