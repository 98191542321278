import React from 'react';
import NavbarContainer from './navbarContainer';

export default class Navbar extends React.Component {
  render() {
    return (
          <NavbarContainer />
    );
  }
}

