import React from 'react';
import LoadingIcon from '../common/loadingIcon';
import FormField, {ControlTypes, DataTypes} from "../common/inputs/formField";
import Translate from "../../internationalisation/translations";
import { UserContext } from '../../contexts/userContext';

const containerStyle = {
  background: '#5BC4F2',
  paddingLeft: '10px',
  color: 'white',
};

const imageContainerStyle = {
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '10px',
  textAlign: 'center',

};
export default class PreviewPrint extends React.Component {
  static contextType = UserContext;
  
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    
    this.state = {
      isGenerating: this.props.isGenerating,
      printed: 0
    }
    
  }

  handleChange(fieldName, value) {
    this.props.onChange(fieldName, value);
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.updateStateWithProps();
    }
  }

  updateStateWithProps() {
    
    let printed = this.state.printed;
    if(this.props.isGenerating && !this.state.isGenerating) {
      printed++;
    }
    
    this.setState({
      isGenerating: this.props.isGenerating,
      printed: printed
    });
  }



  onSubmit() {
    this.props.onSubmit();
  }

  render() {
    
    const {languageCode} = this.context;
    
    if (this.props.currentStep !== 2) {
      return null;
    }

    let canGenerate = !this.props.data.isPreviewLoading 
    
    return (
      <div>
        <div className="w3-container w3-border w3-round" style={containerStyle}>
          <p>Below is a preview of the plate you are producing.
            Please check that this matches your expectation.
          </p>
          <p>Once you are happy, click <strong>Print Plate</strong> to produce the plate.
            If you wish to make any changes, press Back.
          </p>
        </div>

        <div className="w3-container w3-padding w3-margin">
          <h4>
            Client Identity
          </h4>
          <div>
            <i><small>Enter the identity of the client prior to creating a plate.</small></i>
          </div>
          <div>
              <div className={"w3-quarter"}>
                <FormField
                    isRequired
                    label="Customer Name"
                    value={this.props.customer.customerName}
                    onChange={value =>  this.handleChange('customerName', value)}
                    maxLength={30}
                    controlType={ControlTypes.EditBox}
                    dataType={DataTypes.String}
                />
              </div>
            <div className={"w3-quarter"}>
              <FormField
                  isRequired
                  label="ID Type"
                  value={this.props.customer.identificationTypeId}
                  onChange={value =>  this.handleChange('identificationTypeId', value)}
                  options={this.props.identificationTypes}
                  controlType={ControlTypes.DropdownSelection}
                  dataType={DataTypes.Numeric}
              />
            </div>
            <div className={"w3-quarter"}>
              <FormField
                  isRequired
                  label="ID Number"
                  value={this.props.customer.customerIdNumber}
                  onChange={value => this.handleChange('customerIdNumber', value)}
                  maxLength={30}
                  controlType={ControlTypes.EditBox}
                  dataType={DataTypes.String}
              />
            </div>
          </div>
        </div>

        <div className="w3-container w3-padding w3-margin" >
          {this.props.data.isPreviewLoading &&
            <LoadingIcon style={imageContainerStyle} />
          }
          {this.props.data.previewImage &&
            <img className="w3-image animated fadeIn" alt="Plate Preview" src={this.props.data.previewImage} style={imageContainerStyle} /> 
          }
          {this.state.isGenerating &&
            <LoadingIcon style={imageContainerStyle} displayText={"Generating Plate"} />
          }
        </div>
        <div className="w3-container w3-right-align">
          {this.state.printed > 0 &&
            <><button disabled={this.state.isGenerating} style={{marginLeft: 5}} className="w3-btn w3-green w3-round" onClick={() => this.props.close()}>{Translate.getTranslation(languageCode, "TEXT_Close")} <i className="fa fa-cross"/></button></>
          }
          {this.state.printed === 0 &&
          <button disabled={this.state.isGenerating} style={{marginLeft: 5}} className="w3-btn w3-blue w3-round"
                  onClick={() => this.props.moveBack()}>{Translate.getTranslation(languageCode, "TEXT_Previous")} <i className="fa fa-arrow-circle-left"/>
          </button>
          }
          <button style={{marginLeft: 5}} disabled={!canGenerate || this.state.isGenerating} className="w3-btn w3-green w3-round" onClick={() => this.onSubmit()}>{this.state.printed === 0 ? Translate.getTranslation(languageCode, "TEXT_PrintPlate") : Translate.getTranslation(languageCode, "TEXT_PrintAnother")} <i className="fa fa-arrow-circle-right" /></button>
        </div>
      </div>
    );
  }
}
