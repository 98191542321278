import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ControlLabel from './controlLabel';

const groupStyle = {
  display: 'inline-block',
};

export default class TextArea extends Component {
  constructor(props) {
    super(props);
    this.change = this.change.bind(this);
  }

  change(event) {
    this.props.onChange(event.target.value);
  }

  render() {
    return (
      <div className="w3-container w3-padding">
        <div className="w3-row">
          <div className="w3-col">
            <ControlLabel text={this.props.label} isRequired={this.props.isRequired} />
          </div>
        </div>
        <div className="w3-row">
          <div className="w3-col" style={groupStyle}>
            <textarea
              className="w3-input w3-border w3-round"
              placeholder={this.props.label}
              value={this.props.value}
              onChange={this.change}
              readOnly={this.props.isReadonly}
              maxLength={2000}
              rows={10}
            />
          </div>
        </div>
      </div>
    );
  }
}

TextArea.propTypes = {
  isRequired: PropTypes.bool,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  isReadonly: PropTypes.bool,
};

TextArea.defaultProps = {
  isRequired: false,
  isReadonly: false,
  value: '',
};
