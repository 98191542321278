import fetchData from '../helpers/fetchData';
import Server from '../constants/environment';

class AnalyticsDataAccessor {
    static GetGenerations() {
        return new Promise((resolve, reject) => {
            const request = {
                method: 'GET',
                URL: `${Server.getConnection()}/api/analytics/generations`,
            };

            fetchData(request)
                .then((result) => {
                    const payload = result.json();
                    resolve(payload);
                })
                .catch((error) => {
                    reject(new Error(error));
                });
        });
    }


}

export default AnalyticsDataAccessor;