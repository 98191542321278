import React from 'react';
import Moment from 'react-moment';

const tableHeaderStyle = {
  background: '#5BC4F2',
  color: 'white',
  width: '100%',
  borderRadius: '8px',
  fontWeight: 'normal',
};
export default class PlateAllocation extends React.Component {
  constructor(props) {
    super(props);
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle(index) {
    this.props.onChange(index);
  }

  render() {
    return (
      <section>
        <table className="w3-table  w3-bordered w3-hoverable">
          <thead style={tableHeaderStyle} className="tableheader">
            <tr>
              <th />
              <th>Plate Template</th>
              <th>Date Allocated</th>
              <th>Dimensions</th>
              <th>Number Printed</th>
              <th>Last Printed Date</th>
            </tr>
          </thead>
          <tbody>
            {this.props.data.map(((plate, index) =>
                          (
                            <tr key={plate.plateTemplateId}>
                              <td>
                                <input
                                  type="checkbox"
                                  className="w3-check"
                                  checked={plate.isAllocated}
                                  value={plate.clientPlateTemplateId}
                                  onChange={() => this.handleToggle(index)}
                                />
                              </td>
                              <td><span>{plate.description}</span></td>
                              <td><span>{plate.isAllocated ? <Moment format="Do [of] MMMM[,] YYYY">{plate.allocatedDate}</Moment> : ''}</span></td>
                              <td><span>{plate.width}mm by {plate.height}mm</span></td>
                              <td><span>{plate.numberPrinted}</span></td>
                              <td><span>{plate.lastPrintedDate ? <Moment format="Do [of] MMMM[,] YYYY">{plate.lastPrintedDate}</Moment> : ''}</span></td>
                            </tr>
                            )
                        ))}

          </tbody>
        </table>
      </section>
    );
  }
}
