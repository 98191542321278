import React from "react";
import Moment from "react-moment";

class Message extends React.Component {


    constructor(props) {
        super(props)
    }


    formattedMessage(original) {
        if(!original)
            return <p>No Message</p>
        const newText = original
                        .split('\n')
                        .map(str => <p>{str}</p>);
      
        return newText;
      }

render() {


    const messageBoxStyle = {
        padding: "15px"
      }
  
      const messageBoxSubjectStyle = {
        fontSize: "15px",
        padding: "15px",
        background: "#efefef",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#a0a0a0"
      }
  
      const messageStyle = {
        padding: "15px"
      }
  
      const messageLines = {
        paddingTop: "8px"
      }

      const message = this.props.message;


    return (
        

      <div style={messageBoxStyle} className="w3-card-4 w3-hoverable w3-border">
        <div style={messageBoxSubjectStyle}>
          <div className="w3-row">
            <div className="w3-col w3-half" style={messageLines}><strong>Subject: </strong>{message.subject}</div>
            <div className="w3-col w3-half" style={messageLines}><strong>State: </strong>{message.status}</div>
          </div>
          <div className="w3-row">
            <div className="w3-col w3-half" style={messageLines}><strong>Sender: </strong>{`${message.fromName} <${message.fromEmail}>`}</div>
            <div className="w3-col w3-half" style={messageLines}><strong>Date: </strong><Moment local format="Do MMMM[,] YYYY, HH:mm">{message.dateSent}</Moment> <small>(<Moment local fromNow>{message.dateSent}</Moment>)</small></div>
          </div>
        </div>
        <div style={messageStyle}>
          <text>{this.formattedMessage(message.text)}</text>
        </div>
        <div>

        </div>
      </div>
    )
}

}

export default Message;