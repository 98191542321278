import React, {createContext} from 'react';
import Auth from '../authentication';

export const UserContext = createContext();

class UserContextProvider extends React.Component {
    state = {
        user: {},
        isAuthenticated: Auth.isAuthenticated(),
        languageCode: "por",
        canViewPlateHistory: Auth.canViewPlateHistory()
    }

    changeAuthenticatedState = (value) => {
        this.setState({
            isAuthenticated: value
        })
    }
    
    changeLanguage = (value) => {
        this.setState({
            languageCode: value
        })
    }

    render() { 
        return (
            <UserContext.Provider value={{...this.state, changeAuthenticatedState: this.changeAuthenticatedState, changeLanguage: this.changeLanguage}}>
                {this.props.children}
            </UserContext.Provider>
        );
    }
}
 
export default UserContextProvider;
