import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class TrueFalseDisplay extends Component {
  render() {
    const showIcon = (boolValue) => {
      if (boolValue === true) {
        return (<i style={{ color: 'green' }} className="fas fa-check" />);
      }
      return (<i style={{ color: 'red' }} className="fas fa-times" />);
    };

    return (
      <React.Fragment>
        {showIcon(this.props.value)} {this.props.text}
      </React.Fragment>
    );
  }
}

TrueFalseDisplay.propTypes = {
  value: PropTypes.bool.isRequired,
  text: PropTypes.string,
};

TrueFalseDisplay.defaultProps = {
  text: '',
};

