import React from 'react';
import { Link } from 'react-router-dom';
import PageHeader from '../common/pageHeader';
import TemplateDataAccessor from '../../dataAccess/template';
import AuthorisedArea from '../../helpers/AuthorisedArea';
import LoadingIcon from '../../images/loadingIcon.svg';
import {sortByField} from "../../helpers/sorting";

const menuItems = [];

const tableHeaderStyle = {
  background: '#09adf4',
  color: 'white',
};

const buttonPanelStyle = {
  paddingBottom: '5px',
};

export default class Templates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      templates: [],
    };

    this.handleSelection = this.handleSelection.bind(this);
  }

  componentWillMount() {
    TemplateDataAccessor.GetAll()
      .then((result) => {
        this.setState({
          templates: result.payload,
          isLoading: false,
        });
      });
  }

  handleSelection(templateId) {
    this.props.history.push(`/template/${templateId}`);
  }

  render() {
    return (
      <div className="w3-container">
        <PageHeader Heading="Templates" MenuItems={menuItems} />
        <AuthorisedArea requiredAdmin showError>
          <div className="w3-row" style={buttonPanelStyle}>
            <div className="w3-col w3-right-align">
              <Link className="w3-btn w3-blue w3-align-right w3-round" href="/template/" to="/template/"><i className="fa fa-file" /> Create New Template</Link>
            </div>
          </div>

          <table className="w3-table-all w3-card-4 w3-hoverable">
            <thead>
              <tr style={tableHeaderStyle}>
                <td>Template Name</td>
                <td>Dimensions</td>
                <td>Printed Count</td>
                <td>Users with this Template</td>
                <td>Preview</td>
              </tr>
            </thead>
            <tbody>
              {this.state.templates.sort((a,b) => sortByField(a,b,'description', 0)).map(template => (
                <tr className="tableRow" onClick={() => { this.handleSelection(template.id); }} key={template.id}>
                  <td>{template.description}</td>
                  <td>{`${template.width}mm x ${template.height}mm`}</td>
                  <td>{template.platesCreated}</td>
                  <td>{template.allocatedToClients}</td>
                  <td><img src={`${template.previewUrl}?t=${new Date()}`} height="15" /></td>
                </tr>
                ))
              }
            </tbody>
          </table>
          {this.state.isLoading &&
            <div className="w3-full w3-center">
              <LoadingIcon />
            </div>
          }
        </AuthorisedArea>
      </div>
    );
  }
}
