import fetchData from '../helpers/fetchData';
import Server from '../constants/environment';
import Auth from '../authentication'

const isReponseCodeOK = num => num.toString()[0] === '2';

class ClientDataAccessor {
  static Save(client) {
    return new Promise((resolve, reject) => {
      const request = {
        method: 'POST',
        URL: `${Server.getConnection()}/api/client`,
        data: client,
      };

      fetchData(request)
        .then((result) => {
          const payload = result.json();
          const status = result.status;
          if (isReponseCodeOK(status)) {
            resolve(payload);
          } else {
            reject(new Error('Failed'));
          }
        })
        .catch((error) => {
          console.log('In Error in Accessor!', error);
          reject(new Error(error));
        });
    });
  }
  
  static GetAuditHistory(id) {
    return new Promise((resolve, reject) => {
      const request = {
        method: 'GET',
        URL: `${Server.getConnection()}/api/client/${id}/log`,
      };

      fetchData(request)
          .then((result) => {
            const payload = result.json();
            resolve(payload);
          })
          .catch((error) => {
            reject(new Error(error));
          });
    });
  }

  static GetAll(page, size) {
    return new Promise((resolve, reject) => {
      const request = {
        method: 'GET',
        URL: `${Server.getConnection()}/api/clients?page=${page}&size=${size}`,
      };

      fetchData(request)
        .then((result) => {
          const payload = result.json();
          resolve(payload);
        })
        .catch((error) => {
          reject(new Error(error));
        });
    });
  }

  static GetMyClients() {
    return new Promise((resolve, reject) => {
      const request = {
        method: 'GET',
        URL: `${Server.getConnection()}/api/client/${Auth.clientId()}/clients`,
      };

      fetchData(request)
          .then((result) => {
            const payload = result.json();
            resolve(payload);
          })
          .catch((error) => {
            reject(new Error(error));
          });
    });
  }

  static GetById(id) {
    return new Promise((resolve, reject) => {
      const request = {
        method: 'GET',
        URL: !id ? `${Server.getConnection()}/api/client/new` : `${Server.getConnection()}/api/client/${id}`,
      };

      fetchData(request)
        .then((incomingData) => {
          const payload = incomingData.json();
          resolve(payload);
        })
        .catch((error) => {
          reject(new Error(error));
        });
    });
  }

  static GetDashboard() {
    return new Promise((resolve, reject) => {
      const clientRequest = {
        method: 'GET',
        URL: `${Server.getConnection()}/api/client/${Auth.clientId()}`,
      };

      fetchData(clientRequest)
        .then((data) => {
          const payload = data.json();
          resolve(payload);
        })
        .catch((error) => {
          reject(new Error(error));
        });
    });
  }

  static SendMessage(subject, name, email, body, recaptchaResponse) {
    return new Promise((resolve, reject) => {
      const sendRequest = {
        method: 'POST',
        URL: `${Server.getConnection()}/api/messaging/send`,
        data: {
          Name: name,
          Subject: subject,
          Email: email,
          Message: body,
          RecaptchaResponse: recaptchaResponse,
        },
      };
      fetchData(sendRequest)
        .then((data) => {
          const payload = data.json();
          resolve(payload);
        })
        .catch((error) => {
          reject(new Error(error));
        });
    });
  }

  
  static Delete(clientId) {
    return new Promise((resolve, reject) => {
      const request = {
        method: 'DELETE',
        URL: `${Server.getConnection()}/api/client/${clientId}`,
      };
      
      fetchData(request)
          .then((data) => {
            const payload = data.json();
            resolve(payload)
          })
          .catch((error) => {
            reject(new Error(error))
          })
    })
  }

  static GetGenerationsByClientId(clientId, pageNumber, pageSize) {
    return new Promise((resolve, reject) => {
      const historyRequest = {
        method: 'GET',
        URL: `${Server.getConnection()}/api/client/${clientId}/generations?page=${pageNumber}&size=${pageSize}`
      };

      fetchData(historyRequest)
          .then((data) => {
            const payload = data.json();
            resolve(payload);
          })
          .catch((error) => {
            reject(new Error(error));
          });
    });
  }

  static GetAllGenerations(pageNumber, pageSize) {
    return new Promise((resolve, reject) => {
      const historyRequest = {
        method: 'GET',
        URL: `${Server.getConnection()}/api/client/generations?page=${pageNumber}&size=${pageSize}`
      };

      fetchData(historyRequest)
          .then((data) => {
            const payload = data.json();
            resolve(payload);
          })
          .catch((error) => {
            reject(new Error(error));
          });
    });
  }

  static GetSimpleList() {
    return new Promise((resolve, reject) => {
      const historyRequest = {
        method: 'GET',
        URL: `${Server.getConnection()}/api/referencedata/clients`
      };

      fetchData(historyRequest)
          .then((data) => {
            const payload = data.json();
            resolve(payload);
          })
          .catch((error) => {
            reject(new Error(error));
          });
    });
  }
  
  static GetPlateGenerationsByClient(clientId, page, size) {
    return new Promise((resolve, reject) => {
      const historyRequest = {
        method: 'GET',
        URL: `${Server.getConnection()}/api/client/${clientId}/generations?page=${page}&size=${size}`,
        };

        console.log(size);
      
      fetchData(historyRequest)
          .then((data) => {
            const payload = data.json();
            resolve(payload);
          })
          .catch((error) => {
            reject(new Error(error));
          });
    });
  }
  

  static ValidateVatNumber(countryTypeId, vatNumber) {
    const data = {
      CountryTypeId: countryTypeId,
      VATNumber: vatNumber
    };
    const request = {
      method: 'POST',
      URL: `${Server.getConnection()}/api/admin/vatvalidation`,
      data,
    };

    return new Promise((resolve, reject) => {
      fetchData(request)
        .then((result) => {
          const payload = result.json();
          resolve(payload);
        })
        .catch((error) => {
          reject(new Error(error));
        });
    });
  }

  static ValidateClientUser(pin) {
    const data = {
      pin: pin,
    };
    
    const request = {
      method: 'POST',
      URL: `${Server.getConnection()}/api/client/${Auth.clientId()}/validateuser`,
      data,
    };

    return new Promise((resolve, reject) => {
      fetchData(request)
          .then((result) => {
            const payload = result.json();
            resolve(payload);
          })
          .catch((error) => {
            reject(new Error(error));
          });
    });
  }

  static GenerateRegistrationNumber(parentClientId, clientId) {
    const data = {
      ParentClientId: parentClientId,
      ClientId: clientId
    };
    
    const request = {
      method: 'POST',
      URL: `${Server.getConnection()}/api/admin/generateclientnumber`,
      data,
    };

    return new Promise((resolve, reject) => {
      fetchData(request)
        .then((result) => {
          const payload = result.json();
          resolve(payload);
        })
        .catch((error) => {
          reject(new Error(error));
        });
    });
  }
}

export default ClientDataAccessor;

