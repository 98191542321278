import React from "react";

const Translate = (function Translate() {
  
  const strings = [
    {name: "HOME_Intro", eng: <section><p> SmartPlates is an <strong>innovative solution</strong> catering for the needs to the European vehicle trade market.</p><p>We provide our clients with a <strong>simple, efficient and high quality</strong> solution for their vehicle registration plate production, which matches and <em>exceeds all European registration standards for quality, legality and safety</em>.</p><p>We allow you to create a plate within minutes, safe in the knowledge that our solution takes into account all aspects on vehicle registration requirements - allowing you to get on with what you do best in your company.</p></section>, por: <section><p><strong>SmartPlates</strong> é uma solução inovadora que atende às necessidades do mercado europeu de fabrico de chapas de matriculas para automoveis.</p></section>},
    {name: "HOME_Intro2", eng: <section><p><strong>SmartPlates</strong> can provide you with the ability to streamline your vehicle plate registration process, allowing you to <strong>take control</strong> of what you do and how you do it. We want to provide you with the tools to achieve an effective and efficient process.</p><p>How we will achieve this is by:</p><ul><li>Providing you with the the materials at a very affordable cost</li><li>Provide you with the tools required to produce extremely high quality and accurate plates, based on all required regulations</li><li>Provide you with software to manage your plate registration workflow</li><li>Guide you through the process of achieving an extremely efficient process</li></ul></section>, por: <section><p>Oferecemos aos nossos clientes uma solução simples, eficiente e de alta qualidade para a produção de matrículas de veículos, que atende e supera todos os padrões de registro europeus de qualidade, legalidade e segurança.</p></section>},


    { name: "HOME_WhoWeAre", eng: "Who we are" , por: "Quem Somos"},
    { name: "HOME_CurrentStatus", eng: "Current Status" , por: "Status"},
    { name: "HOME_HowWeCanHelp", eng: "How we can help you" , por: "Servicos"},
    { name: "TEXT_ContactUs", eng: "Contact Us" , por: "Contato"},
    { name: "TEXT_ContactDetails", eng: "Contact Details" , por: "Dados de Contacto"},
    { name: "TEXT_OpeningTimes", eng: "Monday - Friday: 9:00 AM to 5:00 PM" , por: "Segunda - Sexta: 9:00 AM to 5:00 PM"},
    { name: "TEXT_VisitFacebook", eng: "Visit us on Facebook" , por: "Facebook"},
    { name: "TEXT_Welcome", eng: "Welcome" , por: "Bem-vindo"},
    { name: "TEXT_YouLoggedIn", eng: "You're logged in. To view your dashboard, click" , por: "Você está logado. Para ver o seu painel, clique em"},
    { name: "TEXT_YouNotLoggedIn", eng: "You're currently not logged in" , por: "Neste momento não esta na sua conta"},
    { name: "TEXT_Name", eng: "Name" , por: "Nome"},
    { name: "TEXT_Subject", eng: "Subject" , por: "Assunto"},
    { name: "TEXT_Email", eng: "Your Email" , por: "Email"},
    { name: "TEXT_Message", eng: "Message" , por: "Mensagem"},
    { name: "TEXT_Send", eng: "Send" , por: "Enviar"},
    { name: "TEXT_Home", eng: "Home" , por: "Casa"},
    { name: "TEXT_SignIn", eng: "Login" , por: "Iniciar Sessão"},
    { name: "TEXT_SignOut", eng: "Sign Out" , por: "Encerrar Sessão"},
    { name: "TEXT_Dashboard", eng: "Dashboard" , por: "Painel"},
    { name: "TEXT_PlateCreator", eng: "Plate Creator" , por: "Criar Matricula"},
    { name: "TEXT_History", eng: "History" , por: "Historia"},
    { name: "TEXT_MyClients", eng: "My Clients" , por: "Os Meus Clientes"},

    { name: "TEXT_MyDetails", eng: "My Details" , por: "Meus Dados"},
    { name: "TEXT_ContactNumber", eng: "Contact Number" , por: "Numero de Contato"},
    { name: "TEXT_Distributor", eng: "Distributor" , por: "Distributor"},
    { name: "TEXT_RegistrationNumber", eng: "Registration Number" , por: "Numero de Manipulador"},
    { name: "TEXT_Country", eng: "Country" , por: "Pais"},
    { name: "TEXT_VatNumber", eng: "VAT Number" , por: "Numero NIF"},
    { name: "TEXT_VatStatus", eng: "VAT Status" , por: "Validacao NIF"},

    { name: "TEXT_UsageDetails", eng: "Usage Details" , por: "Dados de Sistema"},
    { name: "TEXT_NumberOfVisits", eng: "Number of Visits" , por: "Numero de Visitas"},
    { name: "TEXT_PlatesCreated", eng: "Plates Printed" , por: "Matriculas Criadas"},
    { name: "TEXT_LastDateCreated", eng: "Last Print Date" , por: "Ultima Matricula Criada"},
    { name: "TEXT_AllocatedTemplates", eng: "Allocated Templates" , por: "Modelos Alocados"},
    { name: "TEXT_RecentlyPrintedPlates", eng: "Your recently printed plates" , por: "Matriculas Produzidas"},

    { name: "TEXT_Username", eng: "Username" , por: "Username"},
    { name: "TEXT_Password", eng: "Password" , por: "Palavra-passe"},

    { name: "TEXT_TemplateSelection", eng: "Template Selection" , por: "Template Selection **"},
    { name: "TEXT_SelectATemplate", eng: "Select a Template" , por: "Select a Template **"},
    { name: "TEXT_PlateData", eng: "Plate Data" , por: "Plate Data **"},
    { name: "TEXT_EnterPlateDetails", eng: "Enter Plate Details" , por: "Enter Plate Details **"},
    { name: "TEXT_PreviewAndPrint", eng: "Preview & Print" , por: "Preview & Print **"},
    { name: "TEXT_PreviewAndPrintPlate", eng: "Preview and Print the plate" , por: "Preview and Print the plate **"},

    { name: "TEXT_Next", eng: "Next" , por: "Next**"},
    { name: "TEXT_Previous", eng: "Previous" , por: "Previous**"},
    { name: "TEXT_PrintPlate", eng: "Print Plate" , por: "Print Plate**"},
    { name: "TEXT_PrintAnother", eng: "Print Another" , por: "Print Another**"},
    { name: "TEXT_Close", eng: "Close" , por: "Close**"},

  ]

  Translate.getTranslation = function getTranslation(code, name) {
    const item = strings.filter(x => x.name === name);
    if(!item || item.length===0) {
      return "Invalid Translation Token"
    }
    return item[0][code];
  }
  return Translate
  
})();

export default Translate;