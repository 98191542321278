import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadingIconImage from '../../images/loadingIcon.svg';

// / A button which can easily have a spinning 'busy' icon.
export default class LoadingIcon extends Component {
  
  constructor(props) {
    super(props)
  }

  render() {

    if (this.props.visible === false) { return null; }

    return (
      <React.Fragment>
        <div>
          <LoadingIconImage width={25} height={25} alt={this.props.displayText} />
        </div>
      </React.Fragment>
    );
  }
}

LoadingIcon.protoTypes = {
  visible: PropTypes.bool,
  displayText: PropTypes.string,
};

LoadingIcon.defaultProps = {
  visible: true,
  displayText: '',
};
