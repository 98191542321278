import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../contexts/userContext';
import Translate from "../../internationalisation/translations";

const barStyle = {
  padding: '10px',
};



export default class PageHeader extends Component {
  static contextType = UserContext;
  
  render() {
    const {languageCode} = this.context;
    return (
      <div>
        <div className="w3-row">
          <div className="w3-col">
            <span className="w3-xxlarge animated fadeIn pageHeader">{this.props.Heading}</span>
          </div>
        </div>
        <div className="w3-row">
          <div className="w3-col">
            <ol className="breadcrumb w3-round" style={barStyle}>
              <li>
                <Link to="/" >{Translate.getTranslation(languageCode, "TEXT_Home")} &nbsp;</Link>
              </li>
              {this.props.MenuItems && this.props.MenuItems.map(menuItem => (
                <li key={menuItem}>
                  <Link to={menuItem.Url}>{menuItem.Display}</Link>
                </li>
                ))
              }&nbsp;
              <li className="active">{this.props.Heading}</li>
            </ol>
          </div>
        </div>
      </div>
    );
  }
}
