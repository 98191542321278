import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import toast from 'react-hot-toast';
import Auth from '../../authentication';
import ActionButton from '../common/inputs/components/actionButton';
import FormField, {ControlTypes, DataTypes} from '../common/inputs/formField';
import { UserContext } from '../../contexts/userContext';
import Translate from "../../internationalisation/translations";

const headingStyle = {
  background: 'linear-gradient(#09adf4, #5BC4F2)',
  color: 'white',
  borderRadius: '10px 10px 0px 0px'
};

const containerStyle = {
  borderRadius: '10px 10px 0px 0px'
};



class LoginBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: null,
      password: null,
      canLogin: false,
      isLoading: false,
      focusBoxName: "Username"
    };

    this.handleSignin = this.handleSignin.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
  }

  static contextType = UserContext;

  componentDidMount() {
      document.getElementById(this.state.focusBoxName).focus();
  }

  handleSignin(event) {
    event.preventDefault();

    this.setState({ isLoading: true });
    let message = '';
    
    Auth.login(this.state.username, this.state.password)
    .then((result) => {
      message = result.responseText;
      if (result.isSuccess) {
        const {changeAuthenticatedState} = this.context;
        changeAuthenticatedState(true);
        this.props.history.push('/dashboard');
      }
      toast.success(message);
      this.validateForm();
      this.setState({ isLoading: false });
    });
  }
  
  handleChange(field, value) {
    this.setState({[field]: value}, () => 
      {
        this.validateForm()
      });
  }

  validateForm() {
    this.setState({ canLogin: this.state.username && this.state.password });
  }

  onKeyPress(target) {
    if (target.charCode === 13) {
      if (this.state.canLogin) {
        this.handleSignin(target);
      }
    }
  }

  render() {
    const {languageCode} = this.context;
    return (
      <div>
        <div className="w3-card-4" style={containerStyle}>
          <header className="w3-container" style={headingStyle}>
            <h3><i className="fa fa-unlock" /> {Translate.getTranslation(languageCode, "TEXT_SignIn")}</h3>
          </header>
          
          <div className="w3-row w3-padding">

            <FormField
                isRequired
                label={Translate.getTranslation(languageCode, "TEXT_Username")}
                value={this.state.username}
                onChange={value => this.handleChange('username', value)}
                maxLength={30}
                controlType={ControlTypes.EditBox}
                dataType={DataTypes.String}
                id={this.state.focusBoxName}
                onKeyPress = {key => this.onKeyPress(key) }
            />

            <FormField
                isRequired
                label={Translate.getTranslation(languageCode, "TEXT_Password")}
                value={this.state.password}
                onChange={value => this.handleChange('password', value)}
                maxLength={30}
                controlType={ControlTypes.Password}
                dataType={DataTypes.Password}
                onKeyPress = {key => this.onKeyPress(key) }
            />
            
          </div>
          <div className="w3-container w3-padding">
            <ActionButton
              clickEvent={this.handleSignin}
              isEnabled={this.state.canLogin}
              text={Translate.getTranslation(languageCode, "TEXT_SignIn")}
              showLoading={this.state.isLoading}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(LoginBox);
