/* global fetch:false */
import Auth from '../authentication';


const fetchData = ({ method = 'GET', URL, data = {}, isBinary = false}) => {
  // Force the content type.
  const contentType = isBinary ? undefined : 'application/json'
  const header = {
    'Content-Type': contentType,
    Accept: contentType,
  };
  
  // If we have a bearer token (User seems to be signed in), add it to the header.
  const userIsAuthenticated = Auth.isAuthenticated();
  if (userIsAuthenticated) {
    header.Authorization = `Bearer ${Auth.token()}`;
  }

  // Create the config that will be used for the fetch.
  let config = {
    method,
    headers: header,
  };

  // If this is anything but a GET, set the body to hold the data we're posting.
  if (method !== 'GET' && method !== 'DELETE') {
    if(isBinary)
    {
      config = Object.assign({}, config, { body: data });
    }
    else
    {
        const stringified = JSON.stringify(data);
        config = Object.assign({}, config, { body: stringified });
    }
  }

  
  return fetch(URL, config)
    .then((raw) => {
      return raw;
    })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      console.timeEnd(`fetchExecutionTime ${URL}`)
      console.log(`An error has occured while calling the API. ${e}`);
    });
};

export default fetchData;
